app.controller('ModalEditUserCtrl', ['$rootScope', '$scope', '$modalInstance', 'user', 'users', 'User', 'SupplierContactTypes', function($rootScope, $scope, $modalInstance, user, users, User, SupplierContactTypes) {

  $scope.user = user;
  $scope.contactTypes = [];
  $scope.reservationsCustEmail = "";

  _.each(users, function(cust){
    _.each(cust.types, function(data){
        if (data.name.toLowerCase() == "reservations") {
          $scope.reservationsCustEmail = cust.customer.email;
        }
      }).length > 0;
  });

  SupplierContactTypes.then(function(data){
    $scope.contactTypes = _.map(data, function (newData) {
      var selectedStatus = _.filter($scope.user.types, function (currentType) {
        return currentType.name == newData.name;
      }).length > 0;

      var result = {
        status: {selected: selectedStatus},
        type : newData
      };
      return result;
    });
  });

  $scope.update = function() {
    var selected = _($scope.contactTypes).filter(function(ct) {
      return ct.status.selected;
    });

    $scope.user.types = _(selected).map(function(ct) {
      return ct.type;
    });

    User.customerWithContactType($rootScope.selectedSupplier.id).update($scope.user).$promise
      .then(function() {
        var msg = 'User details have been successfully updated.';
        $modalInstance.close({status: 'SUCCESS', message: msg});
      }, function() {
        var msg = 'Oh! An error happened while updating the User details.';
        $modalInstance.close({status: 'FAILURE', message: msg});
      });
  };

  $scope.cancel = function() {
    $modalInstance.dismiss();
  };

}]);

app.controller('FinancialsRemittanceCtrl', ['$rootScope', 'Supplier', 'remittance', 'remittanceYears', function($rootScope, Supplier, remittance, remittanceYears) {
  var self = this;

  self.supplierId = $rootScope.selectedSupplier.id;
  self.remittanceYears = remittanceYears;
  self.selectedYear = self.remittanceYears[0];
  self.remittance = remittance;
  self.loading = false;

  self.selectYear = function(item) {
    self.selectedYear = item;

    var ranges = item.split("/");
    var start = ranges[0] + '-07-01';
    var end = ranges[1] + '-06-30';
    loadRemittance(start, end);
  };

  self.grandTotalForMonth = function(m) {
    return _(m.details).foldl(function(sum, detail) {
      return sum + detail.total;
    }, 0)
  };

  function loadRemittance(start, end) {
    self.loading = true;
    Supplier.remittance(self.supplierId, start, end).get().$promise.then(function(data) {
      self.remittance = data;
      self.loading = false;
    }, function() {
      console.log('something is happening and I don\'t know what it is');
      self.loading = false;
    });
  }

}]);

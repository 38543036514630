app.controller('CompanyInsuranceCtrl', ['$rootScope', '$filter', 'Supplier', 'supplier', 'insuranceFiles', 'fileUpload','$location',
  function ($rootScope, $filter, Supplier, supplier, insuranceFiles, fileUpload,$location) {

  var self = this;

  self.supplierId = $rootScope.selectedSupplier.id;

  self.uploadAcceptedFileTypes = ['image/jpeg', 'image/gif', 'image/png', 'application/pdf'];
  self.uploadMaxSize = 10 * 1024 * 1024;
  self.minDate = moment().add(1, 'day').startOf('day');

  self.uploadState = null;
  self.updateResult = null;


  if($rootScope.selectedSupplier.productType == "Direct Ship")
  {
      $location.path('/home');
  }

  self.expiryDatePickerOptions = {
    changeYear: true,
    changeMonth: true,
    hideIfNoPrevNext: true,
    yearRange: "c-0:+10",
    beforeShowDay: function(d) {
      // only allow date to be selected if (1) it's valid or (2) it's the original date
      // this is necessary to block selection of invalid dates but at the same time
      // display the original date which may be invalid
      var selected = self.supplier && self.supplier.insurance.expiry.getTime() == d.getTime();
      var valid = self.validateExpiryDate(d);
      return [selected || valid];
    }
  };
  self.prettyFileSize = function(filesize) {
    if (!filesize) {
      return "0 KB"
    }
    if (filesize < 1024*1024) {
      return parseFloat(filesize/1024).toFixed(1) + ' KB';
    }
    return parseFloat(filesize/(1024*1024)).toFixed(1) + ' MB';
  };

  self.prepareForDisplay = function(supplier) {
    var copy = angular.copy(supplier);
    copy.insurance.expiry = self.unixMillisToDate(copy.insurance.expiry);
    copy.insurance.maxCover = self.numberToString(copy.insurance.maxCover);
    return copy;
  };

  self.prepareForUpdate = function(supplier) {
    var copy = angular.copy(supplier);
    copy.insurance.expiry = self.dateToUnixMillis(copy.insurance.expiry);
    copy.insurance.maxCover = self.stringToNumber(copy.insurance.maxCover);
    return copy;
  };

  self.checkInsuranceExpiration = function(supplier) {
    $rootScope.insuranceExpired = !self.validateExpiryDate(supplier.insurance.expiry);
  };

  self.updateDetails = function(form) {

    function doUpdate(supplier, form) {

      function resetFileElements() {
        angular.forEach(angular.element("input[type='file']"), function(e) { angular.element(e).val(null); });
      }

      function handleResult(result) {
        form.$setPristine();
        resetFileElements(); // for some reason the file element is not cleared
        self.updateResult = result;
      }

      function handleOk() {
        handleResult('SUCCESS');
        reloadInsuranceFiles();
        self.checkInsuranceExpiration(supplier);
      }

      function handleUpdateError() {
        handleResult('FAILURE-UPDATE');
      }

      function handleUploadError() {
        handleResult('FAILURE-UPLOAD');
      }

      function reloadInsuranceFiles() {
        Supplier.insuranceFiles(self.supplierId).query().$promise.then(function(files) { self.insuranceFiles = files; });
      }

      function update() {
        return Supplier.single(self.supplierId).update(supplier).$promise;
      }

      function upload() {
        var url = "/api/supplier/" + self.supplierId + "/fileSecured?fileType=insurance";
        return fileUpload.uploadFileToUrl(self.insuranceNewFile, url, handleOk, handleUploadError);
      }

      update().then(upload, handleUpdateError);

    }

    var s = self.prepareForUpdate(self.supplier);
    doUpdate(s, form);

  };

  self.numberToString = function(n) {
    return $filter('number')(n, 0);
  };

  self.stringToNumber = function(s) {
    return Number(s.replace(/,/g, ""));
  };

  self.unixMillisToDate = function(n) {
    return new Date(n);
  };

  self.dateToUnixMillis = function(d) {
    return d.getTime();
  };

  self.formatMaxCover = function() {
    self.supplier.insurance.maxCover = self.numberToString(self.stringToNumber(self.supplier.insurance.maxCover));
  };

  self.validateExpiryDate = function(d) {
    return d == undefined || self.minDate.isSame(d) || self.minDate.isBefore(d);
  };

  self.validateNewFile = function() {

    function validateFileType(file) {
      return _.contains(self.uploadAcceptedFileTypes, file.type);
    }

    function validateFileSize(file) {
      return file.size <= self.uploadMaxSize;
    }

    if (self.insuranceNewFile == undefined) return;
    var f = self.insuranceNewFile;
    self.uploadState = (validateFileType(f) && validateFileSize(f)) ? 'VALID' : 'INVALID';
    return self.uploadState === 'VALID';

  };

  self.supplier = self.prepareForDisplay(supplier);
  self.insuranceFiles = insuranceFiles;
  self.insuranceNewFile = null;
  self.checkInsuranceExpiration(self.supplier);

}]);

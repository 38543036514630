app.directive('reviewsLatestReview', function() {
  return {
    restrict: 'E',
    templateUrl: 'reviews/components/latest-review/reviews-latest-review.html',
    scope: {},
    controllerAs: 'ctrl',
    controller: function($scope, $element, $attrs, $rootScope, Review) {

      var self = this;

      // fetch data in component rather than in route to reduce number of homepage resolves
      Review.latestReview($rootScope.selectedSupplier.id).then(function(response) {
        initModel(response);
      });

      function initModel(response) {
        self.productName = response.productName;
        self.productCode = response.productCode;

        var review = response.reviews[0];

        self.score = review.score;
        self.reviewerName = review.reviewerName;
        self.productImage = review.productImage;
        self.reviewText = review.reviewText;
      }

    }
  };
});

app.controller('OrdersListContainerCtrl', ['$rootScope', '$window', '$routeParams', '$scope', '$location', 'Directship', 'counts', 'productsSummary', 'branches', '$filter', function ($rootScope, $window, $routeParams, $scope, $location, Directship, counts, productsSummary, branches, $filter) {
  var self = this;
  self.supplierId = $rootScope.selectedSupplier.id;
  self.tabs = [
    { id: 0, title: 'New', count: counts.newCount, type: 'new' },
    { id: 1, title: 'Sent', count: counts.completedCount, type: 'completed' }
  ];
  self.selectedTabType = "";
  self.newRecordCount = (counts.newCount > 0) ? "Record 1 to " + counts.newCount : 'Record 0 to 0';
  self.searchOrderNumber = null;
  self.searchName = null;
  self.selectTab = selectTab;
  self.isTabActive = isTabActive;
  self.hasSearchQueries = hasSearchQueries;
  self.tabSelected = selectTab(getInitialTab(), true);
  self.search = search;
  self.productsSummary = angular.copy(productsSummary);
  self.branches = angular.copy(branches);
  self.filterByProduct = filterByProduct;
  self.filterByBranch = filterByBranch;
  self.hasBranches = hasBranches;

  // filter with date
  self.dateOptions = {
    changeYear: true,
    changeMonth: true,
    yearRange: '1900:c+30',
    dateFormat: 'yy-mm-dd'
  };
  self.dateFormat = 'YYYY-MM-DD';
  self.startDate = null;
  self.endDate = null;
  self.searchByDate = searchByDate;
  self.exportDirectShipToExcel = exportDirectShipToExcel;
  self.exportDirectShipToCsv = exportDirectShipToCsv;
  self.markAsSent = markAsSent;
  self.isOrderItemSelected = false;
  init();

  function init() {
    $rootScope.tabSelected = self.tabSelected;
    self.productsSummary.unshift({
      code: null,
      name: 'Products'
    });
    if (angular.isDefined($routeParams.productCode)) {
      self.selectedProduct = _.find(self.productsSummary, function(p) {
        return p.code === $routeParams.productCode;
      });
    } else {
      self.selectedProduct = self.productsSummary[0];
    }

    self.branches.unshift({
      branchId: null,
      facilityId: null,
      productCode: null,
      state: null,
      branchName: "All Branches",
      email: null
    });
    if (angular.isDefined($routeParams.facilityId)) {
      self.selectedBranch = _.find(self.branches, function(b) {
        return b.facilityId === $routeParams.facilityId;
      });
    } else {
      self.selectedBranch = self.branches[0];
    }

    // added for date filter setting in url
    if (angular.isDefined($routeParams.startDate)) {
      self.startDate = parseDate($routeParams.startDate);
    }

    if (angular.isDefined($routeParams.endDate)) {
      self.endDate = parseDate($routeParams.endDate);
    }
  }

  $scope.$on('update-counts', function () {
    findAllCounts();
  });

  function getInitialTab() {
    var path = $location.$$path;
    if (path.indexOf('new') !== -1) {
      self.selectedTabType = 'new';
      return self.tabs[0];
    }
    if (path.indexOf('completed') !== -1) {
      self.selectedTabType = 'completed';
      return self.tabs[1];
    }
  }

  function isTabActive(tab) {
    return tab === self.tabSelected;
  }

  function selectTab(tab, init) {
    if (typeof init !== 'undefined' && init) {
      tab.active = true;
      self.tabSelected = tab;
      return tab;
    } else if (self.tabSelected === tab) {
      return tab;
    }

    self.tabSelected.active = false;
    tab.active = true;

    self.tabSelected = tab;

    $rootScope.tabSelected = self.tabSelected;
    self.selectedTabType = self.tabSelected.type;
    finalApply();
  }

  function getSortBy() {
    if (self.tabSelected.type === 'new') return "redeemDate";
    else if (self.tabSelected.type === 'confirmed') return "supplierConfirmedOnDate";
    else if (self.tabSelected.type === 'completed') return "confirmDateTime";
  }

  function getOrder() {
    if (self.tabSelected.type === 'new') return "DESC";
    else if (self.tabSelected.type === 'confirmed') return "DESC";
    else if (self.tabSelected.type === 'completed') return "DESC";
  }

  function findAllCounts() {
    Directship.findDirectShipCounts(self.supplierId, $routeParams.productCode, $routeParams.facilityId).query().$promise.then(
      function(data) {
        self.newRecordCount = (data.newCount > 0) ? "Record 1 to " + data.newCount : 'Record 0 to 0';
        self.tabs[0].count = data.newCount;
        self.tabs[1].count = data.confirmedCount;
        self.tabs[2].count = data.completedCount;
      }, function(error) {
        self.bookingErrors.push(error);
      }
    );
  }

  function hasSearchQueries() {
    return (
      (self.searchName !== null && self.searchName.trim() !== '') ||
      (self.searchOrderNumber !== null && self.searchOrderNumber.trim() !== '')
    );
  }

  function search() {
    $location.path('/orders/search/').search({ orderNumber: self.searchOrderNumber, name: self.searchName, page: 1, sortBy: 'name', order: 'ASC'});
  }

  function filterByProduct(product) {
    self.selectedProduct = product;
    finalApply();
  }

  function filterByBranch(branch) {
    self.selectedBranch = branch;
    finalApply();
  }

  function hasBranches() {
    return self.branches.length > 1;
  }

  function searchByDate() {
    finalApply();
  }

  // make sure date filter will always apply as long as they are set
  function finalApply() {
    var formatStartDate = self.startDate
      ? moment(self.startDate).format(self.dateFormat)
      : null;
    var formatEndDate = self.endDate
      ? moment(self.endDate).format(self.dateFormat)
      : null;

    $location.path('/orders/list/' + self.tabSelected.type)
      .search({
        startDate: formatStartDate,
        endDate: formatEndDate,
        page: 1,
        productCode: self.selectedProduct.code,
        facilityId: self.selectedBranch.facilityId,
        sortBy: getSortBy(),
        order: getOrder()
      });
  }

  // strict parse only for YYYY-MM-DD
  function parseDate(date) {
    if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(date)) {
      var m = moment(date, self.dateFormat);
      if (m.isValid()) {
        return m.toDate();
      }
    }
    return null;
  }

  function exportDirectShipToExcel() {
    self.isOrderItemSelected = false;

    if ($rootScope.orderDetails != undefined && $rootScope.orderDetails.length > 0) {
      var orderItemId = [];

      _.each($rootScope.orderDetails, function (el) {
      if (el.isChecked === true) {
      orderItemId.push(parseInt(el.orderItemId));
      }
      });

      if (orderItemId.length > 0)
        Directship.exportDirectShipExcel(self.supplierId, orderItemId);
      else
        self.isOrderItemSelected = true;
    }
  }

  function exportDirectShipToCsv() {
    self.isOrderItemSelected = false;

    if ($rootScope.orderDetails != undefined && $rootScope.orderDetails.length > 0) {

      var orderItemId = [];
      _.each($rootScope.orderDetails, function (el) {
        if (el.isChecked === true) {
          orderItemId.push(parseInt( el.orderItemId));
        }
      });

      if (orderItemId.length > 0)
        Directship.exportDirectShipCsv(self.supplierId, orderItemId);
      else
        self.isOrderItemSelected = true;
    }
  }

  function markAsSent() {
    self.isOrderItemSelected = false;

    if ($rootScope.orderDetails != undefined && $rootScope.orderDetails.length > 0) {
      var orderItemId = [];

      _.each($rootScope.orderDetails, function (el) {
        if (el.isChecked === true) {
          orderItemId.push(parseInt(el.orderItemId));
        }
      });

      if (orderItemId.length > 0) {
        Directship.markAsSent(self.supplierId, orderItemId).update().$promise.then(
          function (data) {
            $window.location.reload();
          }, function (error) {
            self.bookingErrors.push(error);
          }
        );
      }
      else
        self.isOrderItemSelected = true;
    }

  }



}]);

app.controller('ProductImageModalInstanceCtrl', ['$modalInstance', function($modalInstance) {

  var self = this;
  self.cancel = cancel;

  function cancel() {
    $modalInstance.dismiss();
  }
  
}]);

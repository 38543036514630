app.controller('ProductLeadTimeCtrl', ['$rootScope', '$scope', '$http', 'productData', function($rootScope, $scope, $http, productData) {

	$scope.model = productData.summary;
  $scope.supplierId = $rootScope.selectedSupplier.id;
  $scope.leadTime = $rootScope.product.leadTime;

  $scope.showLeadTimeError = function() {
    return isNaN($scope.leadTime);
  };

  $scope.updateProductLeadTime = function(leadTime) {
    var leadTimeData = {
      "leadTime": parseInt(leadTime)
    };
    $http.post('/supplier/' + $scope.supplierId + '/product/' + $rootScope.product.id + '/leadTime', leadTimeData);
  }

}]);

app.factory('autoSave', function($timeout) {
	var secondsToWaitBeforeSave = 2,
		timeout = null,
		saveInProgress = false;
	
	/*!
	 * Initialize the autosave
	 *
	 * @param  {Object}   $scope           the `$scope` of the caller, `$watchCollection` will occur on the `$scope.model`
	 * @param  {Array}    deepWatchers     an array of models i.e. `model.arrayName` to $watch `deep` where `$watchCollection` cannot
	 * @return {Void}
	 */
	var init = function($scope, deepWatchers) {
						
		// Save the updates
		var saveUpdates = function() {
			if ($scope.form.$valid) {
				saveInProgress = true;
				
				$scope.save();
				
				saveInProgress = false;
			}
		};
		
		// Debounce
		var debounceUpdate = function(newVal, oldVal) {
			if (!angular.equals(newVal, oldVal)) {
				if (timeout) {
					$timeout.cancel(timeout);
				}
				timeout = $timeout(saveUpdates, secondsToWaitBeforeSave * 1000);
			}
		};
		
		// Deep `$watch`
		if (typeof deepWatchers !== 'undefined') {
			angular.forEach(deepWatchers, function(item) {
				$scope.$watch(item, debounceUpdate, true);
			});
		}
						
		// Watch the model collection
		$scope.$watchCollection('model', debounceUpdate);

	};
	
	return {
		init: init
	}
});

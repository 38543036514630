app.directive('avAddDatebulk', ['availabilityUtil', function(availabilityUtil) {
	return {
		restrict: 'E',
		scope: {
			save: '&saveCallback',
			model: '=ngModel',
      statusData:'='
		},
		templateUrl: 'availability/components/editor/content-panel/datebulk/adddatebulk.html',
		controllerAs: 'ctrl',
		controller: function($scope, $element, $attrs, $routeParams, $location) {

      var handleResponseError = function(error) {
        var productName = error.productName;
        var productCode = error.productCode;
        var dateSession = error.date;
        var startTime = error.startTime;
        var endTime = error.endTime;

        _.each(error.errorMessage, function(errorMessage) {
          var message = productName + ' (' + productCode + ') : ' + dateSession + ' ' + startTime + '-' + endTime + ' - '+ errorMessage;
          $scope.errors.push(message);
        })
      };

      var handleMappingConflict = function (error) {
        _.each(error, function (errorMessage) {
          $scope.errors.push(errorMessage);
        })
      };

      $scope.daysSelected = false;

      $scope.errors = [];

			$scope.model.push(availabilityUtil.getNewTimeSlot(availabilityUtil.getNewTimeSlotId($scope.model)));

			$scope.isButtonDisabled = function() {
				return $scope.buttonDisabled || !$scope.daysSelected;
			};


			$scope.model = _.map($scope.model, function(product) {
				return _.extend(product, {hasError: false});
			});

			// function to remove products that contain the toDelete objects given
			// that everything is
			function removeToDeleteProducts(products) {
				return _.filter(products, function(product){
					return product.toDelete ? '' : product;
				});
			}


			$scope.$watch('model', function (products) {
				// check for toDelete object as they were staying in memory and causing
				// issues with the disable checks for save button
				products = removeToDeleteProducts(products);
				$scope.buttonDisabled = _.reduce(products, function (reducer, product) {
					return !reducer ? product.hasError : reducer;
				}, false);
			}, true)

			$scope.productsParam = $routeParams.productcode;
			$scope.filter = {
				fromDate: undefined,
				toDate: undefined,
				frequencyPeriod: undefined,
				frequencyMultiplier: undefined,
				selectedDays: []
			};



      $scope.resetErrorArray = function() {
        $scope.errors = [];
      };

      $scope.onFail = function (response) {
        console.error('failed with response:', response);
        //This error is thrown when a session is added for a product
        // that is already mapped to another provider (e.g: Rezdy,Livn etc)
        if (response.status === 409) {
          handleMappingConflict(response.data);
        }

        _.each(response.data.errors, function(error) {
          handleResponseError(error);
        });
      };

			$scope.addTimeslot = function() {
				$scope.model.push(availabilityUtil.getNewTimeSlot(availabilityUtil.getNewTimeSlotId($scope.model)));
			};

			$scope.saveTimeslots = function() {
        $scope.resetErrorArray();
        $scope.filter.selectedDays = availabilityUtil.syncUserSelectedDays($scope, "addBulk", $scope.filter.userSelectedDays); // 'addBulk'
				$scope.save({
          times: $scope.model,
          filter: $scope.filter,
          onFail: $scope.onFail,
          statusData: $scope.statusData
        });
			}
		}
	};
}]);

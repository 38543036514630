app.controller('ProductSupplierCtrl', ["$scope", "$location", "$http", '$routeSegment', '$log', function($scope, $location, $http, $routeSegment, $log) {
  $scope.primaryCategories = [];

  $scope.product = {
    typeId: 1,
    supplierId: Number($routeSegment.$routeParams.id),
    locations: [],
    additionalInfo : {},
    description: ""
  };

  $scope.extra = {
    sellingPriceCents: 0
  };

  $http.get('/placeholderCategoryIds').then(function(data) {
    $scope.product.categoryIds = data.data;
  }, function(){app.alertError("Unable to load essential data, form submission will fail");});

  $scope.setCategories = function(data){
    var filtered = _.filter(data.data, function(f) {
      return f.parentName != 'Team Building' && f.parentName != 'Platinum' && f.parentName != 'Gift Boxes';
    });

    $scope.primaryCategories = _.map(filtered, function(category) {
      return {
        id: category.id,
        parentName: category.parentName,
        name: category.name,
        fullName: category.parentName + ' > ' + category.name
      };
    });
  };

  $http.get('/categories?categoryType=category').then($scope.setCategories, function(){app.alertError("Unable to load categories");});

  $scope.setYoutubeUrl = function(theProduct, productToSubmit) {
    var url = theProduct.additionalInfo.videoCode;

    if (url) {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length == 11) {
        productToSubmit.additionalInfo.videoCode = match[2];
      } else {
        productToSubmit.additionalInfo.videoCode = undefined;
      }
    } else {
      productToSubmit.additionalInfo.videoCode = undefined;
    }
  };

  $scope.freeLength = function(base, input) {
    if(input){
      var number = base - input.length;
      if(number < 0) {
        return 0;
      }

      return number;
    } else {
      return base;
    }
  };

  $scope.save = function(form) {
    form.submitted = true;
    if(!form.$valid){
      return;
    }

    try {
      $scope.product.sellingPrice = $scope.extra.sellingPriceDollars + $scope.extra.sellingPriceCents / 100;
    } catch (f) {
      //Only if no validation from HTML
      $scope.product.sellingPrice=0.0;
    }
    $scope.product.buyingPrice = $scope.product.sellingPrice * 0.75;
    $scope.product.marketDescription = $scope.product.summary;
    $scope.product.printDescription = $scope.product.marketDescription;
    $scope.product.printName = $scope.product.name;

    $scope.setYoutubeUrl($scope.product, $scope.product);
    try {
      $scope.product.participants = Number($scope.product.participants);
      //Should only fail on non HTML5 browser and invalid input
    } catch(e) {
      $scope.product.participants = 0;
    }

    $scope.product.primaryCategoryId = $scope.finalCategory.id;

    var theForm = jQuery("#theForm");
    var submit = true;
    try {
      submit =  (!theForm[0].checkValidity || theForm[0].checkValidity());
    } catch(e) {
      $log.warn('error checkValidity'+e);
    }

    if (submit) {
      $scope.saving=true;

      $log.info('about to submit product', JSON.stringify($scope.product));

      $http.post('/supplier/'+$routeSegment.$routeParams.id+'/products', $scope.product)
      .success(function() {
        $location.path('/success/'+$routeSegment.$routeParams.id);
      }).error(function(data, status) {
        $scope.saving = false;
        $scope.error = "Error " + status + "saving the product: " + data;
        window.globalError = $scope.error;
        $log.error("Error saving product: " + $scope.error);
        $location.path('/failure/' + $routeSegment.$routeParams.id);
      });
    }
  };
}]);
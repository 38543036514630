app.directive('avEditBlockout', ['Availability', 'availabilityUtil', function(Availability, availabilityUtil) {
  return {
    restrict: 'E',
    scope: {
      save: '&saveCallback',
      multiple: '=multiple',
      model: '=ngModel',
      statusData: '='
    },
    templateUrl: 'availability/components/editor/content-panel/blockout/editblockout.html',
    controllerAs: 'ctrl',
    controller: function($scope, $element, $attrs, $routeParams, $location) {
      $scope.productParam = $routeParams.productcode;
      $scope.filter = {
        fromDate: undefined,
        toDate: undefined,
        frequencyPeriod: undefined,
        frequencyMultiplier: undefined,
        selectedDays: []
      };

      $scope.isSelectionsExpanded = [];

      $scope.expandSelections = function($index) {
        $scope.isSelectionsExpanded[$index] = $scope.isSelectionsExpanded[$index] ? false : true;
      };

      $scope.displayProduct = function(code) {
        return availabilityUtil.isProductSelected(code);
      };

      $scope.blockoutDates = Availability.getBlockoutDates();

      $scope.getResultsNumber = function() {
        var res = 0;
        if ($scope.model)
          for (var i=0;i<$scope.model.length;i++) {
            res += $scope.model[i].blockouts.length;
          }
        return res;
      };

      $scope.$on('$routeChangeStart', function(next, current) {
        $scope.model.length = 0;
      });

      $scope.saveBlockout = function () {
        $scope.save({
          data : $scope.model,
          onFail : $scope.onFail
        });
      }
    }
  };
}]);

app.controller('ModalRemoveSessionCtrl', ['$rootScope', '$scope', '$modalInstance', 'productCode', 'Availability', function ($rootScope, $scope, $modalInstance, productCode, Availability) {

  $scope.remove = function () {
    console.log("Product code is " + productCode);
    Availability.removeADMappingPromise(productCode).then(function (ADresult) {
      Availability.removeBDMappingPromise(productCode).then(function (BDresult) {
        var msg = 'Sessions has been removed';
        $modalInstance.close({ status: 'SUCCESS', message: msg });
      }, function (reason) {
        $modalInstance.close({ status: 'FAILURE', message: reason.data });
      });
    });
  };

  $scope.cancel = function () {
    $modalInstance.dismiss();
  };

}]);

app.controller('ManagePromotionCtrl',
    ['$scope','$rootScope', '$filter', '$routeParams', '$sce', 'products', '$modal', 'Campaign',
      function($scope, $rootScope, $filter, $routeParams, $sce, products, $modal, Campaign) {
  var self = this;

  var orderBy = $filter('orderBy');

  self.order = function(predicate) {
    if (predicate === self.predicate) {
      self.reverse = !self.reverse;
    }
    self.predicate = predicate;
    self.publishedProducts = orderBy(self.publishedProducts, predicate, self.reverse);
  };

  function initAllFamilies() {
    var productsCopy = angular.copy(products);
    var filteredProducts = _(productsCopy).filter(function (product) {
      return product.status.publishType.toLowerCase() === 'published';
    });
    return _(filteredProducts).map(function (product) {
      product.selectedForPromotion = false;
      return product;
    });
  }

// mobile accordion table
  self.expandProduct = function (family) {
    family.isExpanded = !family.isExpanded;
  };

  self.getHrefForTitle = function(family) {
    return '/myproducts/view/' + family.familyCode + '?publishType=Published&preview=true';
  };

  self.selectAllProducts = function (flag) {
    return _(self.publishedProducts).map(function (product) {
      product.selectedForPromotion = flag;
      return product;
    });
  };

  self.openManagePromotionsModal = function() {
    var modalInstance = $modal.open({
      templateUrl: 'products/promotion/manage-promotions-modal.html',
      controller: 'ManagePromotionsModalCtrl as ctrl',
      resolve: { selectedProducts: function() {
        return _(self.publishedProducts).filter(function (product) {
          return product.selectedForPromotion;
        });
      }, campaigns: function () {
          return Campaign.getCampaigns();
        }
      }
    });

    modalInstance.result.then( function (data) {
      var bodyToSend = _(data).map(function (product) {
        return createBodyForProductCampaignWorkflow(product)
      });

        Campaign.addWorkflow(bodyToSend)
          .then(function () {
            self.publishedProducts = initAllFamilies();
            $scope.publishedProducts = self.publishedProducts;
            $scope.toModeration = {success: true, message: "Your products are now in moderation, please allow a few days for approval."};
            self.flagViewMessage = true;
          }, function () {
            $scope.toModeration = {success: false, message: "Something when wrong please contact RedBalloon team to assist you."};
            self.flagViewMessage = true;
          });
    }, function () {
      self.publishedProducts = initAllFamilies();
      $scope.publishedProducts = self.publishedProducts;
      }
    );
  };


  function createBodyForProductCampaignWorkflow(product) {
    return {
      campaign: {
        id: product.campaign.id,
        name: product.campaign.name
      },
      productDetails: {
        productFamily: {
          name: product.name,
          code: product.familyCode
        },
        priceOption: {
          id: product.id,
          code: product.code,
          type: product.poType,
          wasPrice: product.originalSellPrice,
          oldWasPrice: product.wasPrice,
          sellPrice: product.sellPrice,
          oldSellPrice: product.originalSellPrice,
          buyPrice: product.sellPrice - (product.sellPrice * product.commission),
          oldBuyPrice: product.buyPrice,
          commission: product.commission
        }
      },
      supplier: {
        id: $rootScope.suppliers[0].id,
        name: $rootScope.suppliers[0].name
      },
      notes: [{
        note: "Workflow changed sell price for a campaign. From: " + product.originalSellPrice + ", To: " + product.sellPrice,
        user: $rootScope.suppliers[0].Email,
        source: "SupplierHub",
        dateAdded: moment().format("YYYY-MM-DD")
      }]
    };
  }



  self.publishedProducts = initAllFamilies();
  $scope.publishedProducts = self.publishedProducts;
  self.predicate = '';
  self.reverse = false;
  self.order('name');
  self.selectedAllFlag = false;
  self.continueDisabled = true;
  self.flagViewMessage = false;

  $scope.$watch('publishedProducts', function (nextValue) {
    if(self.flagViewMessage) {
      self.flagViewMessage = false;
    } else {
      $scope.toModeration = undefined;
    }
    self.continueDisabled = !Boolean(_.filter(nextValue, function (product) {
      return product.selectedForPromotion;
    }).length);
  }, true);


}]);

app.controller('BookingSearchCtrl', ['$rootScope', '$routeParams', '$location', 'Booking', 'bookings', 'totalCount', function($rootScope, $routeParams, $location, Booking, bookings, totalCount) {
  var self = this;
  self.predicateToParamMap = createParamMap();
  self.paramToPredicateMap = createPredicateMap();
  self.supplierId = $rootScope.selectedSupplier.id;
  self.bookings = angular.copy(bookings);
  self.predicate = self.paramToPredicateMap[$routeParams.sortBy];
  self.reverse = ($routeParams.order === 'ASC') ? false : true;
  self.allSelected = false;
  //The user can change the search parameters and not click on search again. After this change the user may navigate between
  //pages or to the booking details based on search results for the original search parameters. So we need to have two copies.
  self.searchVoucherNumber = angular.isDefined($routeParams.voucherNumber) ? $routeParams.voucherNumber : null;
  self.searchName = angular.isDefined($routeParams.name) ? $routeParams.name : null;
  self.routeSearchVoucherNumber = self.searchVoucherNumber;
  self.routeSearchName = self.searchName;
  self.search = search;
  self.cancel = cancel;
  self.currentPage = $routeParams.page;
  self.bookingDetails = bookingDetails;
  self.hasSearchQueries = hasSearchQueries;
  self.isAutoConfirmed = isAutoConfirmed;
  self.isPending = isPending;
  self.isNew = isNew;
  self.isConfirmed = isConfirmed;
  self.isCompleted = isCompleted;
  self.bookingErrors = [];
  self.limit = 25;
  self.maxVisiblePages = 10;
  self.hasMultiplePages = hasMultiplePages;
  self.totalCount = totalCount.count;
  self.goto = goto;
  self.exportBookings = exportBookings;
  self.expandBooking = expandBooking;
  
  function expandBooking(booking) {
    booking.isExpanded = booking.isExpanded ? false : true;
  }
  
  self.order = function(predicate) {
    if (predicate === self.predicate) {
      self.reverse = !self.reverse;
    } else {
      self.reverse = false;
    }
    self.predicate = predicate;
    var sortBy = self.predicateToParamMap[predicate];
    var order = (self.reverse) ? 'DESC' : 'ASC';
    $location.path('/bookings/search/').search({page: 1, voucherNumber: self.routeSearchVoucherNumber, name: self.routeSearchName, sortBy: sortBy, order: order});
  };

  function createParamMap() {
    var map = {};
    map['participantName'] = 'name';
    map['familyName'] = 'product';
    map['voucherNumber'] = 'voucherNumber';
    return map;
  }

  function createPredicateMap() {
    var map = {};
    map['name'] = 'participantName';
    map['product'] = 'familyName';
    map['voucherNumber'] = 'voucherNumber';
    return map;
  }

  function isNew(booking) {
    return booking.status === 'new';
  }

  function isConfirmed(booking) {
    return booking.status === 'confirmed';
  }

  function isCompleted(booking) {
    return booking.status === 'completed';
  }

  function isPending(booking) {
    return booking.pendingConfirmation === true;
  }

  function isAutoConfirmed(booking) {
    return booking.autoConfirmed === true;
  }

  function hasMultiplePages() {
    return self.totalCount > self.limit;
  }

  function cancel() {
    $location.path('/bookings/list/new').search({page: 1, sortBy: 'redeemDate', order: 'ASC'});
  }

  function search() {
    if (self.searchVoucherNumber !== null && self.searchVoucherNumber.trim() === '') self.searchVoucherNumber = null;
    if (self.searchName !== null && self.searchName.trim() === '') self.searchName = null;
    $location.path('/bookings/search/').search({voucherNumber: self.searchVoucherNumber, name: self.searchName, page: 1, sortBy: $routeParams.sortBy, order: $routeParams.order});
  }

  function exportBookings() {
    Booking.exportCsv(self.supplierId, self.routeSearchVoucherNumber, self.routeSearchName, $routeParams.sortBy, $routeParams.order);

  }

  function goto() {
    $location.path('/bookings/search/').search({voucherNumber: self.routeSearchVoucherNumber, name: self.routeSearchName, page: self.currentPage, sortBy: $routeParams.sortBy, order: $routeParams.order});
  }

  function bookingDetails(booking) {
    $location.path('/bookings/view/' + booking.id).search({voucherNumber: self.routeSearchVoucherNumber, name: self.routeSearchName, page: self.currentPage, sortBy: $routeParams.sortBy, order: $routeParams.order});
  }

  function hasSearchQueries() {
    return (
    (self.searchName !== null && self.searchName.trim() !== '') ||
    (self.searchVoucherNumber !== null && self.searchVoucherNumber.trim() !== '')
    );
  }

}]);

app.controller('HomeCtrl', ['$rootScope', 'Supplier', 'Booking', 'supplierContracts', 'users', 'supplier', 'paymentsTrendPrimary', 'paymentsTrendSecondary', 'remittanceYears', 'PageTitle', 'SupplierContract','$location', function($rootScope, Supplier, Booking, supplierContracts, users, supplier, paymentsTrendPrimary, paymentsTrendSecondary, remittanceYears, PageTitle, SupplierContract,$location) {
  var self = this;

  PageTitle.set('Home - Supplier Hub | RedBalloon');

  self.supplierId = $rootScope.selectedSupplier.id;
  self.labels = ["Jul", "Aug", "Sept", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May", "Jun"];
  self.remittanceYears = remittanceYears;
  self.hasSecondaryFinancialYears = (self.remittanceYears.length > 1);
  self.series = initSeries();
  self.payments = initPayments();
  self.showBankingAlert = showBankingAlert;
  self.showInsuranceAlert = showInsuranceAlert;
  self.showInsuranceExpiredAlert = showInsuranceExpiredAlert;
  self.showAgencyAgreementAlert = showAgencyAgreementAlert;
  self.showContactTypesAlert = showContactTypesAlert;
  self.hasMultipleContracts = hasMultipleContracts;
  self.selectYear = selectYear;
  self.hasNewBookingCount = hasNewBookingCount;
  self.selectedSupplierType = $rootScope.selectedSupplier.productType;

  self.nps = 60; // TODO: This is NPS value for Benchmark widget

  self.chart = {
    colours: ['#25bc2c', '#cccccc'],
    options: {
      scaleGridLineColor: '#f9f9f9'
    }
  };

  function initPayments() {
    var payments = [];

    if (self.remittanceYears.length >= 1) {
      payments[0] = _(paymentsTrendPrimary).map(function(p) {
        return p.toFixed(2);
      });
    }

    if (self.remittanceYears.length >= 2) {
      payments[1] = _(paymentsTrendSecondary).map(function (p) {
        return p.toFixed(2);
      });
    }
    return payments;
  }

  function initSeries() {
    var series = [];
    if (self.remittanceYears.length >= 1) {
      self.primaryFinancialYear = self.remittanceYears[0];
      series[0] = "FY " + self.remittanceYears[0];
    }
    if (self.remittanceYears.length >= 2) {
      self.secondaryFinancialYear = self.remittanceYears[1];
      series[1] = "FY " + self.remittanceYears[1];
    }
    return series;
  }

  function showBankingAlert() {
    var ba = supplier.bankAccount;
    var country = supplier.address.country ? supplier.address.country.code.toUpperCase() : 'AU';
    var completeBankAccount = ba
      && (ba.name && ba.bank  && ba.accountNumber)
      && (country === 'AU' && ba.BSB || country === 'NZ' );

    return !completeBankAccount;
  }

  function showInsuranceAlert() {
    var i = supplier.insurance;
    var completeInsurance = i && i.underwriter && i.policyNumber && i.type && i.maxCover && i.expiry && i.onFile;

    return !completeInsurance;
  }

  function showInsuranceExpiredAlert() {
    return $rootScope.insuranceExpired;
  }

  function showAgencyAgreementAlert() {
    return SupplierContract.checkAgencyAgreement();
  }

  function hasMultipleContracts() {
    return (SupplierContract.findAllResult > 1);
  }

  function showContactTypesAlert() {
    var allTypes = _(users).map(function(userAndTypes) {
      return userAndTypes.types;
    });

    var types = _(allTypes).flatten().filter(function(t) {
      return t.name === 'Customer Admin' || t.name === 'Finance';
    });

    return types.length < 2;
  }

  function countBookings() {
    Booking.findAllCounts(supplier.id).query(function(counts) {
      self.newBookingsCount = counts.newCount;
      self.confirmedBookingsCount = counts.confirmedCount;
      self.confirmedBookingsRealTimeCount = counts.confirmedRealTimeCount;
      self.completeBookingsCount = counts.completedCount;
    });
  }

  function selectYear(index, year) {
    var ranges = year.split("/");
    var start = ranges[0] + '-07-01';
    var end = ranges[1] + '-06-30';
    Supplier.paymentsTrend(self.supplierId, start, end).query().$promise.then(
      function(data) {
        if (index === 0) {
          self.primaryFinancialYear = year;
        } else {
          self.secondaryFinancialYear = year;
        }
        self.payments[index] = _(data).map(function(p) {
          return p.toFixed(2);
        });
        self.series[index] = "FY " + year;
      }, function(error) {
      }
    );

  }

  function hasNewBookingCount() {
    return angular.isDefined(self.newBookingsCount);
  }

  countBookings();

  if($rootScope.selectedSupplier.productType == "Direct Ship")
  {
       if(showBankingAlert()            == false
          && showInsuranceAlert()       == false
          && showInsuranceExpiredAlert()== false
          && showAgencyAgreementAlert() == false
          && showContactTypesAlert()    == false)
        {
          $location.path('/orders/list/new').search({page: 1, sortBy: 'redeemDate', order: 'DESC'});
        }
  }

}]);

// configure log entries
// only turn it on for UAT or PROD
if (document && document.URL){
  var token = null;
  if (document.URL.indexOf('supplier.uat.redballoon.com.au') > 0){
    token = '4061c402-ce99-4804-ad58-0de9578ac6af';
    console.log('configuring logentries for uat');
  } else if (document.URL.indexOf('supplier.redballoon.com.au') > 0){
    token = '49edceab-687e-4cad-bb52-338bb4c107e8';
    console.log('configuring logentries for prod');
  }

  if (token !== null){
    LE.init({
      token: token,
      ssl: true,
      catchall: true,
      print: true,
      page_info: 'per-page'
    });
  }
}
app.factory('Supplier', function($resource) {
  return {
    allForCustomer: function() {
      return $resource('/api/supplier/forCustomer', {}, {
          'query': {method: 'GET', isArray: true}
        }
      );
    },
    single: function(id) {
      return $resource('/api/supplier/' + id, {}, {
          'get': {method: 'GET'},
          'update': {method: 'PUT'}
        }
      );
    },
    insuranceFiles: function(supplierId) {
      return $resource('/api/supplier/:supplierId/file', {supplierId: supplierId, fileType: 'insurance'}, {
          'query': {method: 'GET', isArray: true}
        }
      );
    },
    contact: function(supplierId, customerId) {
      return $resource('/supplier/:supplierId/users/:customerId', {supplierId: supplierId, customerId: customerId}, {
          'delete': {method: 'DELETE'}
        }
      );
    },
    banking: function(supplierId) {
      return $resource('/api/supplier/:supplierId/bankDetails', {supplierId: supplierId}, {
          'create': {method: 'POST'}
        }
      );
    },

    /**
     * comment out this block (waiting for the design)
     */
    // leadTime: function(supplierId) {
    //   return $resource('/api/supplier/:supplierId/leadTime', {supplierId: supplierId}, {
    //       'save': {method: 'POST'}
    //     }
    //   );
    // },
    
    bankingChangeHistory: function(supplierId) {
      return $resource('/api/supplier/:supplierId/bankingHistory', {supplierId: supplierId}, {
          'get': {method: 'GET', isArray: true}
        }
      );
    },
    remittance: function(supplierId, start, end) {
      return $resource('/api/supplier/:supplierId/remittance', {supplierId: supplierId, start: start, end: end}, {
          'get': {method: 'GET'}
        }
      );
    },
    remittanceYears: function(supplierId) {
      return $resource('/api/supplier/:supplierId/remittanceYears', {supplierId: supplierId}, {
          'get': {method: 'GET', isArray: true}
        }
      );
    },
    productsSummary: function(supplierId) {
      return $resource('/api/supplier/:supplierId/productsSummary', {supplierId: supplierId}, {
          'get': {method: 'GET', isArray: true}
        }
      );
    },
    branches: function(supplierId) {
      return $resource('/api/supplier/:supplierId/branches', {supplierId: supplierId}, {
          'get': {method: 'GET', isArray: true}
        }
      );
    },
    paymentsTrend: function(supplierId, start, end) {
      return $resource('/api/supplier/:supplierId/paymentsTrend', {supplierId: supplierId, start: start, end: end}, {
          'get': {method: 'GET', isArray: true}
        }
      );
    },
  };
});

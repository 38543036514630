app.directive('reviewsHomepageNpsSummary', function() {
  return {
    restrict: 'E',
    templateUrl: 'reviews/components/homepage-nps-summary/reviews-homepage-nps-summary.html',
    scope: {},
    controllerAs: 'ctrl',
    controller: function($scope, $element, $attrs, $rootScope, Review) {

      var self = this;

      // fetch data in component rather than in route to reduce number of homepage resolves
      Review.npsSummary($rootScope.selectedSupplier.id).then(function(response) {
        initModel(response);
      });

      function initModel(response) {
        self.nps = response.nps;
        self.redBalloonBenchmark = 65;
      }

    }
  };
});

/**
 * productPanelBody
 * -----------------------------
 * @author Curtis McMullan <curtis.mcmullan@redballoon.com.au>
 */

(function () {

  angular
    .module('app')
    .directive('productPanelBody', function ($controller, $compile, $templateCache) {
      return {
        restrict: 'E',
        link: function ($scope, $element, $attrs) {

          // Compile the given template
          var templateUrl = $scope.$eval($attrs.template);
          var template = $templateCache.get(templateUrl);
          $element.html(template);
          $compile($element.contents())($scope);

          // Wire in the given controller
          var controllerName = $scope.$eval($attrs.controller);
          $element.data('$controller', $controller(controllerName, {
            $scope: $scope,
            $element: $element,
            $attrs: $attrs
          }));

        }
      }
    });

})();

app.controller('LandingpageCtrl', ['$rootScope', '$scope', '$anchorScroll', '$location', '$window', 'User',
  function ($rootScope, $scope, $anchorScroll, $location, $window, User) {
    // if user is logged in
    User.profile().get()
      .$promise
      .then(function (profile) {
        if (profile && profile.username) $location.path('/home');
      });

    $window.scrollTo(0, 0);

    $scope.goSignup = function () {
      $window.scrollTo(0, 0);

      $location.path('/signup')
    }

    $scope.scrollTo = function (id) {
      $location.hash(id);

      $anchorScroll();
    }
}]);

app.controller('HeaderCtrl', ['$rootScope', '$location', 'Auth', 'SupplierSelection','$window', function($rootScope, $location, Auth, SupplierSelection,$window) {

  var self = this;

  self.loggedInUser = function() {
    return $rootScope.profile.firstName + " " + $rootScope.profile.lastName;
  };

  self.goLogin = function() {
    $location.path('/login');
  }

  self.loggedInUserFirstName = function() {
    return $rootScope.profile.firstName;
  };

  self.loggedInUserInitials = function() {
    return $rootScope.profile.firstName.charAt(0) + $rootScope.profile.lastName.charAt(0);
  };

  self.isCompanySelected = function() {
    return $rootScope.profile && $rootScope.selectedSupplier;
  };

  self.selectedCompany = function() {
    return $rootScope.selectedSupplier.name;
  };

  self.isSelectedSupplier = function(supplier) {
    return $rootScope.profile && $rootScope.selectedSupplier === supplier;
  };  

  self.triggerSwitchCompany = function() {
    SupplierSelection.selectSupplier($rootScope.suppliers, true).then(function() {


      $location.path('/');

      // if($location.path()=='/home')
      // {
      //    $location.path('/');
      // }
      // else
      // {
      //   $location.path('/home');
      // }
      
    });
  };

  self.hasMultipleCompanies = function() {
    return _.size($rootScope.suppliers) > 1;
  };

  self.logout = function() {
    Auth.logout().success(function() {
      finishLogout()
    });
  };

  self.logoutAs = function() {
    Auth.logoutAs().then(function() {
      finishLogout()
    });
  };

  function finishLogout() {
    $rootScope.profile = undefined;
    $rootScope.selectedSupplier = undefined;
    $location.path('/login');
  }

}]);

app.factory('Booking', function ($resource, $window) {
  return {
    findAll: function (supplierId, status, productCode, facilityId, sortBy, order, page, startDate, endDate, limit) {
      return $resource('/api/supplier/:supplierId/bookings', { supplierId: supplierId, status: status, productFilter: productCode, branchFilter: facilityId, sortBy: sortBy, order: order, page: page, startDate: startDate, endDate: endDate, limit: limit }, {
        'query': { method: 'GET', isArray: true }
      }
      );
    },
    find: function (supplierId, bookingId) {
      return $resource('/api/supplier/:supplierId/booking/:bookingId', { supplierId: supplierId, bookingId: bookingId }, {
        'query': { method: 'GET' }
      }
      );
    },
    search: function (supplierId, voucherNumber, name, sortBy, order, page, limit) {
      return $resource('/api/supplier/:supplierId/bookings/search', { supplierId: supplierId, voucherNumber: voucherNumber, name: name, sortBy: sortBy, order: order, page: page, limit: limit }, {
        'query': { method: 'GET', isArray: true }
      }
      );
    },
    exportCsv: function (supplierId, voucherNumber, name, sortBy, order) {
      function getQueryString() {
        if (voucherNumber !== null && name !== null) {
          return 'voucherNumber=' + encodeURIComponent(voucherNumber) + '&name=' + encodeURIComponent(name) + '&sortBy=' + encodeURIComponent(sortBy) + '&order=' + encodeURIComponent(order);
        }
        else if (voucherNumber !== null) {
          return 'voucherNumber=' + encodeURIComponent(voucherNumber) + '&sortBy=' + encodeURIComponent(sortBy) + '&order=' + encodeURIComponent(order);
        }
        else if (name !== null) {
          return 'name=' + encodeURIComponent(name) + '&sortBy=' + encodeURIComponent(sortBy) + '&order=' + encodeURIComponent(order);
        }
      }
      var url = '/api/supplier/' + supplierId + '/bookings/search/export?' + getQueryString();
      $window.location.assign(url);
    },
    searchCount: function (supplierId, voucherNumber, name) {
      return $resource('/api/supplier/:supplierId/bookings/searchCount', { supplierId: supplierId, voucherNumber: voucherNumber, name: name }, {
        'query': { method: 'GET' }
      }
      );
    },
    findAllCounts: function (supplierId, productCode, facilityId) {
      return $resource('/api/supplier/:supplierId/bookingCounts', { supplierId: supplierId, productFilter: productCode, branchFilter: facilityId }, {
        'query': { method: 'GET' }
      }
      );
    },
    findQuestions: function (supplierId, bookingId) {
      return $resource('/api/supplier/:supplierId/qa/byBooking/:bookingId', { supplierId: supplierId, bookingId: bookingId }, {
        'query': { method: 'GET', isArray: true }
      }
      );
    },
    bookings: function (supplierId) {
      return $resource('/api/supplier/:supplierId/bookings', { supplierId: supplierId }, {
        'update': { method: 'PUT' }
      }
      );
    },
    bookingNote: function (supplierId, bookingId) {
      return $resource('/api/supplier/:supplierId/booking/:bookingId/internalNote', { supplierId: supplierId, bookingId: bookingId }, {
        'insert': { method: 'POST' }
      }
      );
    },
    findNotes: function (supplierId, bookingId) {
      return $resource('/api/supplier/:supplierId/booking/:bookingId/notes', { supplierId: supplierId, bookingId: bookingId }, {
        'query': { method: 'GET', isArray: true }
      }
      );
    },
    updatePending: function (supplierId, bookingId) {
      return $resource('/api/supplier/:supplierId/booking/:bookingId/pending', { supplierId: supplierId, bookingId: bookingId }, {
        'update': { method: 'PUT' }
      }
      );
    },
    getRejectReasons: function (supplierId) {
      return $resource('/api/giftCertificate/issueReason', { }, {
        'query': { method: 'GET', isArray: true }
      }
      );
    },
    clearBooking: function (orderItemId, supplierId) {
      return $resource('/api/orderitems/:orderItemId/clearBooking/:supplierId', { orderItemId: orderItemId, supplierId: supplierId }, {
        'insert': {
          method: 'POST', headers: {
            'Content-Type': 'text/json'
          }
          }

      })
    }
  };
});

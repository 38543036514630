  // angular
  //   .module('app')
  //   .controller('FinancialsMenuCtrl', FinancialsMenuCtrl);
  //
  // FinancialsMenuCtrl.$inject = ['$rootScope', '$location', 'PageTitle'];

app.controller('FinancialsMenuCtrl', ['$rootScope', '$location', 'PageTitle', function ($rootScope, $location, PageTitle) {
  var self = this;

  PageTitle.set('Financials - Supplier Hub | RedBalloon');

  self.tabs = [
    {id: 0, title: 'Remittance'},
    {id: 1, title: 'Payments'},
    {id: 2, title: 'Banking'}
  ];

  self.selectTab = selectTab;
  self.tabSelected = selectTab(getInitialTab(), true);

  init();

  function init() {
    $rootScope.tabSelected = self.tabSelected;
  }

  function selectTab(tab, init) {

    if (typeof init !== 'undefined' && init) {
      tab.active = true;
      self.tabSelected = tab;
      return tab;
    } else if (self.tabSelected === tab) {
      return tab;
    }

    self.tabSelected.active = false;
    tab.active = true;
    self.tabSelected = tab;
    $rootScope.tabSelected = self.tabSelected;
    $location.path('/financials/' + tab.title.toLowerCase());
  }

  function getInitialTab() {
    var path = $location.$$path;
    if (path.indexOf(self.tabs[0].title.toLowerCase()) !== -1) return self.tabs[0];
    if (path.indexOf(self.tabs[1].title.toLowerCase()) !== -1) return self.tabs[1];
    if (path.indexOf(self.tabs[2].title.toLowerCase()) !== -1) return self.tabs[2];
  }
}]);

app.directive('avCalendarDate', ['$document', '$timeout', '$rootScope', '$routeParams', 'availabilityUtil', function ($document, $timeout, $rootScope, $routeParams, availabilityUtil) {
  return {
    transclude: true,
    scope: {
      model: '=ngModel',
      productStatus: '=',
      month: '=avMonth'
    },
    restrict: 'E',
    templateUrl: 'availability/components/manager/calendar-date/calendar-date.html',
    controller: function ($scope, $element, $attrs) {
      $scope.calendar = $document.find('#av-calendar');
      $scope.overlay = $document.find('#av-calendar-overlay');
      $scope.selectedDate = null;
      $scope.hasEvents = [];
      $scope.hasBookings = {};
      $scope.hasBlockout = {};

      $scope.$watch(
        "model", function (newValue, oldValue) {
          $scope.calendar.fullCalendar('removeEvents');
          $scope.calendar.fullCalendar('addEventSource', $scope.generateEvents());
          $scope.calendar.fullCalendar('rerenderEvents');
          $scope.calendar.fullCalendar('prev');
          $scope.calendar.fullCalendar('next');
        },
        true
      );

      $scope.generateEvents = function () {
        var events = [];
        $scope.hasEvents = [];
        $scope.hasBookings = {};
        $scope.hasBlockout = {};
        _.each($scope.model, function (date) {
          _.each(date.products, function (product) {
            if (product.selected) {

              if (_.find(product.times, function (el) { return el.bookingCount > 0; }))
                $scope.hasBookings[date.date] = true;

              if (_.find(product.times, function (el) { return el.status == "Blockout"; })) {
                product.isBlockout = true;
                product.status = "Blockout";
                $scope.hasBlockout[date.date] = true;
              }

              _.each(product.times, function (time) {
                $scope.hasEvents[date.date] = true;
                if (time.status != "Blockout") {
                  title = time.allDay ? 'All day' : time.start.format('HH:mm') + ' - ' + time.end.format('HH:mm');
                events.push({
                  id: time.id,
                  title: title,
                  start: date.date,
                  details: _.extend(time, { date: date.date }) // custom parameter containing all timeslot details
                });
                }
              });
            }
          });
        });

        return events;
      };

      // instantiate calendar
      var options = {
        defaultDate: moment().year($scope.month.year).month($scope.month.month),
        header: false,
        fixedWeekCount: true, // this is required (along with aspectRatio) to keep cells the same size
        // although this means some months will have an extra row of days from next month
        aspectRatio: 1.1,
        eventLimit: true,
        events: $scope.generateEvents(),

        views: {
          month: {
            eventLimit: 2
          }
        },

        // massage callbacks for all of the possible click events into a displayModal(date) call
        dayClick: function (date) {
          return $scope.displayModal(date.format());
        },
        eventClick: function (event) {
          return $scope.displayModal(event.details.date);
        },
        eventLimitClick: function (cellInfo) {
          return $scope.displayModal(cellInfo.date.format());
        },

        dayRender: function (date, cell) {
          if ($scope.hasEvents[date.format('')]) {
            cell.addClass('rb-has-events');
            if ($scope.hasBookings[date.format('')])
              cell.append('<span class="calendar__entries-icon"><i class="fa fa-calendar-check-o" aria-hidden="true"></i></span>');
            if ($scope.hasBlockout[date.format('')])
              cell.append('<span class="calendar__entries-icon"><i class="fa fa-calendar-times-o" aria-hidden="true"></i></span>');
          }
        }
      };
      $scope.calendar.fullCalendar(options);


      // render modal containing timeslots for given date
      $scope.displayModal = function (date) {
        var calendarDate = $scope.calendar.fullCalendar('getDate');
        if (true) {
          // find and set selectedDate model, or get an empty model
          var selectedDate = _.find($scope.model, function (_date) {
            return _date.date === date;
          });
          if (selectedDate) {
            $scope.selectedDate = selectedDate;
          } else {
            $scope.selectedDate = availabilityUtil.getEmptyModel(date);
          }
          // show overlay
          $timeout(function () {
            $scope.overlay.modal('show');
          });
        }
      };

      var ableNav = function () {
        $rootScope.manage_availability_show_loading = false;
      }
      // navigate calendar to prev/next month
      $scope.calendarNav = function (action) {
        var fromStr, toStr;

        $rootScope.manage_availability_show_loading = true;
        $scope.month.date = moment();
        if (action === 'prev') {
          if ($scope.month.month === 0) {
            $scope.month.month = 11;
            $scope.month.year--;
          } else {
            $scope.month.month--;
          }
        } else {
          if ($scope.month.month === 11) {
            $scope.month.month = 0;
            $scope.month.year++;
          } else {
            $scope.month.month++;
          }
        }

        $scope.month.date.year($scope.month.year)
          .month($scope.month.month)
          .format('YYYY-MM-DD');

        $scope.month.weekDate = moment($scope.month.date)
          .startOf("month")
          .startOf("week")
          .format('YYYY-MM-DD');

        fromStr = moment($scope.month.date)
          .startOf("month")
          .startOf("week")
          .format('YYYY-MM-DD');

        toStr = moment($scope.month.date)
          .endOf("month")
          .format('YYYY-MM-DD');

        availabilityUtil.loadNewDates($routeParams.productcode, fromStr, toStr, ableNav);
        $scope.calendar.fullCalendar(action);

      };

      // get short month string for prev/next month
      $scope.getPrevMonth = function () {
        return $scope.calendar.fullCalendar('getDate')
          .subtract(1, 'months')
          .format('MMM');
      };
      $scope.getNextMonth = function () {
        return $scope.calendar.fullCalendar('getDate')
          .add(1, 'months')
          .format('MMM');
      };
    },
    controllerAs: 'ctrl'
  }
}]);

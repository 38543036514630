app.controller('HomeDirectShipCtrl', ['$rootScope', 'Supplier', 'Booking', 'supplierContracts', 'users', 'supplier', 'paymentsTrendPrimary', 'paymentsTrendSecondary', 'remittanceYears', 'PageTitle', 'SupplierContract','$location', function($rootScope, Supplier, Booking, supplierContracts, users, supplier, paymentsTrendPrimary, paymentsTrendSecondary, remittanceYears, PageTitle, SupplierContract, $location) {
    var self = this;
  
    PageTitle.set('Home - Supplier Hub | RedBalloon');
  
    self.supplierId = $rootScope.selectedSupplier.id;
    self.labels = ["Jul", "Aug", "Sept", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May", "Jun"];
    self.showBankingAlert = showBankingAlert;
    self.showInsuranceAlert = showInsuranceAlert;
    self.showInsuranceExpiredAlert = showInsuranceExpiredAlert;
    self.showAgencyAgreementAlert = showAgencyAgreementAlert;
    self.showContactTypesAlert = showContactTypesAlert;
    self.hasMultipleContracts = hasMultipleContracts;


    if($rootScope.selectedSupplier.productType != "Direct Ship")
    {
        $location.path('/home');
    }

    function showBankingAlert() {
      var ba = supplier.bankAccount;
      var country = supplier.address.country ? supplier.address.country.code.toUpperCase() : 'AU';
      var completeBankAccount = ba
        && (ba.name && ba.bank && ba.BSB && ba.accountNumber)
        && (country === 'AU' || country === 'NZ' && ba.swift);
  
      return !completeBankAccount;
    }
  
    function showInsuranceAlert() {
      var i = supplier.insurance;
      var completeInsurance = i && i.underwriter && i.policyNumber && i.type && i.maxCover && i.expiry && i.onFile;
  
      return !completeInsurance;
    }
  
    function showInsuranceExpiredAlert() {
      return $rootScope.insuranceExpired;
    }
  
    function showAgencyAgreementAlert() {
      return SupplierContract.checkAgencyAgreement();
    }
  
    function hasMultipleContracts() {
      return (SupplierContract.findAllResult > 1);
    }
  
    function showContactTypesAlert() {
      var allTypes = _(users).map(function(userAndTypes) {
        return userAndTypes.types;
      });
  
      var types = _(allTypes).flatten().filter(function(t) {
        return t.name === 'Customer Admin' || t.name === 'Finance';
      });
  
      return types.length < 2;
    }

    if(showBankingAlert() == false
      && showInsuranceAlert() == false
      && showInsuranceExpiredAlert()  == false
      && showAgencyAgreementAlert() == false
      && showContactTypesAlert() == false     
      && $rootScope.selectedSupplier.productType == "Direct Ship"
     )
    {
      $location.path('/orders/list/new').search({page: 1, sortBy: 'redeemDate', order: 'DESC'});
    }
  
  }]);
  
/**
* productImagesDropzoneController
*/

(function () {

	angular
	.module('app')
	.controller('productImagesDropzoneController', productImagesDropzoneController);

	productImagesDropzoneController.$inject = ['productData', '$timeout'];
	
	/**
	* productImagesDropzoneController
	*/
	function productImagesDropzoneController(productData, $timeout) {

		var self = this;

		self.dzAddedFile = function(file) {
			if (file.xhr && file.xhr.readyState == 4) {
				var res = JSON.parse(file.xhr.response);
				$timeout(function() {
					productData.imgVid.images.push(res);
					self.dropzone.removeFile(file);
				});
			} else {
				console.log('Image upload returned with failure');
				self.dropzone.removeFile(file);
			}
		};

		self.dzError = function(file, errorMessage, xhr) {
			console.log(xhr);
		};

		self.dropzoneConfig = {
			url: "/api/images/standalone",
			acceptedFiles: '.jpg,.jpeg,.png',
			previewsContainer: '#dz-output',
			previewTemplate: '<li class="media-gallery__item">' +
				'<div class="media-gallery__item-crop dz-details">' +
				'<div class="dz-filename"><span data-dz-name></span></div>' +
				'<div class="dz-size" data-dz-size></div>' +
					'<img class="media-gallery__item-image" data-dz-thumbnail />' +
				'</div>' +
				'<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>' +
				'<div class="dz-success-mark"><span>✔</span></div>' +
				'<div class="dz-error-mark"><span>✘</span></div>' +
				'<div class="dz-error-message"><span data-dz-errormessage></span></div>' +
			'</li>',
			thumbnailWidth: 380,
			thumbnailHeight: 380,
			parallelUploads: 3,
			maxFileSize: 30,
			dictDefaultMessage: ''
		};

	};

})();

app.controller('ChooseSupplierModalCtrl', ['$scope', '$modalInstance', 'items', 'selectedItem', '$location', 'SupplierContract', function($scope, $modalInstance, items, selectedItem, $location, SupplierContract) {

  $scope.items = items;
  $scope.selectedItem = selectedItem;

  $scope.select = function (item) {
    
    
    SupplierContract.findAll(item.id)
    .then(function () {
      if (SupplierContract.checkAgencyAgreement()) {
        $location.url($location.path()); // This clears the location path and removes any parameters
        $location.path('/home');
      }
    });

    $modalInstance.close(item);
  };

}]);

app.directive('avManagerControls', function() {
	return {
		transclude: true,
		restrict: 'E',
		scope: {
			calendarView: '=displayCalendar',
			datesData: '=datesModel',
			month: '=avMonth'
		},
		templateUrl: 'availability/components/manager/controls/manager-controls.html',
		controllerAs: 'ctrl',
		controller: function($scope, $element, $attrs, $document, $location, $routeParams, $rootScope, availabilityUtil) {
			var ctrl = this;
			ctrl.onCalendar = $scope.calendarView;
			ctrl.onList = !$scope.calendarView;
			$scope.productParam = $routeParams.productcode;

			$scope.getListDate = function() {
				from = moment($scope.month.weekDate);
				to = moment($scope.month.weekDate).add(6, 'd');

				return from.format('Do MMM') + ' to ' + to.format('Do MMM, YYYY');
			}

			$scope.getCalendarDate = function() {
				return $scope.loadCalendar() ? $scope.calendar.fullCalendar('getView').title : '';
			};

			var ableNav = function() {
				$rootScope.manage_availability_show_loading = false;
			}

			$scope.listNav = function(action) {
				if (action === 'prev') {
					$scope.month.weekDate = moment($scope.month.weekDate).subtract(1, 'w').format('YYYY-MM-DD');
				} else {
					$scope.month.weekDate = moment($scope.month.weekDate).add(1, 'w').format('YYYY-MM-DD');
				}
				if ($scope.month.month != moment($scope.month.weekDate).month() &&
					$scope.month.month != moment($scope.month.weekDate).add(6, 'd').month()) {
						$rootScope.manage_availability_show_loading = true;
						$scope.month.month = moment($scope.month.weekDate).month();
						$scope.month.year = moment($scope.month.weekDate).year();
						$scope.month.date = moment().month($scope.month.month).format('YYYY-MM-DD');
						var fromStr = moment($scope.month.weekDate).startOf('month').subtract(10, 'd').format('YYYY-MM-DD');
						var toStr = moment($scope.month.weekDate).endOf('month').add(10, 'd').format('YYYY-MM-DD');
						availabilityUtil.loadNewDates($scope.productParam, fromStr, toStr, ableNav);
				}
			}

			$scope.calendarNav = function(action) {
				$rootScope.manage_availability_show_loading = true;
				$scope.month.date = moment();
				if (action === 'prev') {
					if ($scope.month.month === 0) {
						$scope.month.month = 11;
						$scope.month.year--;
					} else {
						$scope.month.month--;
					}
				} else {
					if ($scope.month.month === 11) {
						$scope.month.month = 0;
						$scope.month.year++;
					} else {
						$scope.month.month++;
					}
				}
				$scope.month.date.year($scope.month.year).month($scope.month.month).format('YYYY-MM-DD');
				$scope.month.weekDate = moment($scope.month.date).startOf("month").startOf("week").format('YYYY-MM-DD');
				var fromStr = moment($scope.month.date).startOf("month").startOf("week").format('YYYY-MM-DD');
				var toStr = moment($scope.month.date).endOf("month").format('YYYY-MM-DD');
				availabilityUtil.loadNewDates($scope.productParam, fromStr, toStr, ableNav);
				if ($scope.loadCalendar()) {
					$scope.calendar.fullCalendar(action);
				}
			};

			$scope.loadCalendar = function() {
				// no reference, or empty reference, attempt to select calendar element
				if (!$scope.calendar || !$scope.calendar.length) {
					$scope.calendar = $document.find('#av-calendar');
				}
				// return true if we have a successfully selected element
				return $scope.calendar && $scope.calendar.length;
			};

			$scope.switchView = function(view) {
				$location.path('/myproducts/availability/manager/'+$scope.productParam+'/'+view);
			}
		},
	};
});

app.controller('ModalAddUserCtrl', ['$rootScope', '$scope', '$modalInstance', 'users', 'User', '$http', 'Auth', '$timeout', 'SupplierContactTypes', function ($rootScope, $scope, $modalInstance, users, User, $http, Auth, $timeout, SupplierContactTypes) {

  $scope.user = { firstName: '', lastName: '', jobTitle: '', types: [], email: '' };

  $scope.contactTypes = [];
  $scope.reservationsCustEmail = '';

  _.each(users, function(cust){
    _.each(cust.types, function(data){
        if (data.name.toLowerCase() == "reservations") {
          $scope.reservationsCustEmail = cust.customer.email;
        }
      }).length > 0;
  });

  SupplierContactTypes.then(function(data){
    $scope.contactTypes = getContactTypes(data);
  });

  function getContactTypes(data) {
    var allContactTypes = _.map(data, function (value) {
      return { type: {
          id: value.id,
          name: value.name,
          description: value.description
        }, status: {
          selected: false
        }
      };
    });

    _($scope.user.types).map(function (t) {
      var c = _(allContactTypes).find(function (ct) {
        return t.name === ct.type.name;
      });

      if (c) {
        c.status.selected = true;
      }
    });

    return allContactTypes;
  }

  $scope.add = function () {
    $scope.loading = true;

    var userIsAlreadyAdded = _(users).filter(function (usr) {
      return usr.customer.email == $scope.user.email;
    });

    if (userIsAlreadyAdded.length > 0) {
      var msg = 'Oh! This user is already in the list.';
      $modalInstance.close({ status: 'FAILURE', message: msg });
      $scope.loading = false;
      return;
    }

    var requestPayload = {
      username: $scope.user.email,
      firstName: $scope.user.firstName,
      lastName: $scope.user.lastName,
      email: $scope.user.email,
      password: $scope.user.password,
      sendNewsletter: false,
      address: { state: $scope.user.selectedState },
      isFromSupplierHub: true
    };

    $http.post('/api/profile', requestPayload).then(function (succ) {
      Auth.resetPassword({ email: $scope.user.email }).success(function (response, status) {
        processUser();
      });
    }, function (response, status) {
      if (response.status == 409) {
        processUser();
      } else {
        $scope.loading = false;
      }
    }).$promise;
  };

  $scope.cancel = function () {
    $modalInstance.dismiss();
  };

  function processUser() {
    var selected = _($scope.contactTypes).filter(function (ct) {
      return ct.status.selected;
    });

    $scope.user.types = _(selected).map(function (ct) {
      return ct.type;
    });

    $timeout(function () {
      $http.get('/customers?q=(phrase field=email \'' + $scope.user.email + '\')').then(function (succ) {
        if (succ.data.length > 0) {
          var userId = succ.data[0].id;

          $http.post('/supplier/' + $rootScope.selectedSupplier.id + "/users/" + userId, {
            types: $scope.user.types,
            sendWelcomeEmail: false
          }).then(function (succ) {

            User.customerWithContactType($rootScope.selectedSupplier.id).query().$promise.then(function (users) {
              var currentUsers = users;

              var userList = _(currentUsers).filter(function (usr) {
                if (usr.customer.id == userId) {
                  return usr;
                }
              });

              var apiUser = userList[0];

              apiUser.customer.contact.jobTitle = $scope.user.jobTitle;
              apiUser.customer.contact.phone = $scope.user.phone;
              apiUser.customer.contact.firstName = $scope.user.firstName;
              apiUser.customer.contact.lastName = $scope.user.lastName;

              User.customerWithContactType($rootScope.selectedSupplier.id).update(apiUser).$promise
                .then(function () {
                  var msg = 'User ' + $scope.user.firstName + ' ' + $scope.user.lastName + ' has been successfully added.';
                  $scope.loading = false;
                  $modalInstance.close({ status: 'SUCCESS', message: msg });
                }, function () {
                  var msg = 'Oh! An error happened while adding the User ' + $scope.user.firstName + ' ' + $scope.user.lastName;
                  $scope.loading = false;
                  $modalInstance.close({ status: 'FAILURE', message: msg });
                });

            });
          });
        } else {
          processUser();
        }
      });
    }, 500);
  }
}]);

app.directive('avManager', function() {
	return {
		restrict: 'E',
		scope: {
			model: '=ngModel',
			statusData: '=avStatus',
			month: '=avMonth'
		},
		templateUrl: 'availability/components/manager/manager.html',
		controllerAs: 'ctrl',
        controller: function ($scope, $element, $attrs, $routeParams, $location, $modal, $window) {
			if ($routeParams.view != 'calendar' && $routeParams.view != 'list')
				$location.path('/myproducts');
			$scope.displayCalendar = ($routeParams.view === 'calendar') ? true : false;
			$scope.multiple = $scope.statusData.length > 1 ? true : false;
      $scope.routeParams = $routeParams;
      $scope.removeMappingEnable = $scope.routeParams.productcode.split("=").length <= 2;

      $scope.productParam = $scope.routeParams.productcode;

      if ($scope.removeMappingEnable) {
        $scope.removeMappingText = "";
      } else {
        $scope.removeMappingText = "This button only enabled in single product code editing"
      }

      this.openRemoveDatesModal = function() {
        var productCode = "";
        if ($scope.removeMappingEnable) {
          productCode = $scope.routeParams.productcode.split("=")[1];
        }
        this.modalInstance = $modal.open({
          templateUrl: 'availability/remove-mapping-modal.html',
          controller: 'ModalRemoveSessionCtrl',
          windowClass: 'supplier-modal',
          backdrop: 'static', // This prevents clicking backdrop closing modal
          keyboard: true, // This allows pressing ESC key to close modal
          resolve: {
            productCode: function() { return angular.copy(productCode); }
          }
        });

        this.modalInstance.result.then(function(result) {
          self.updateResult = result;
          if (self.updateResult.status === 'SUCCESS') {
            $window.location.reload();
          }
        }, function(result) {
        });
      };

		},
	};
});

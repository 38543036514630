
app.controller('SignupCtrl', ["$scope", "$location", "$http", 'fileUpload', '$log', '$rootScope', '$routeSegment', 'SupplierContactTypes', '$sce',
  function ($scope, $location, $http, fileUpload, $log, $rootScope, $routeSegment, SupplierContactTypes, $sce) {
    $scope.currentCountry = {};
    $scope.myCode = "AU";

    // fetch countries and states listings
    $http.get('/countries').then(function (data) {
      // limit set to Australia/NZ
      $scope.countries = _.filter(data.data, function (country) {
        // default the country to Australia
        return country.code === 'AU' || country.code === 'NZ';
      });

      $scope.currentCountry = _.find($scope.countries, function (country) {
        return country.code === 'AU';
      });
    }, app.alertError);

    $scope.setcountry = function (country) {
      $scope.myCode = country.code;
      $scope.currentCountry = _.find($scope.countries, function (cu) {
        return country.code === cu.code;
      });
    }
  }
]);

app.controller('PricesCtrl', ['$scope', 'Product', 'productData', 'autoSave', function($scope, Product, productData, autoSave) {

	$scope.model = productData.prices;

	$scope.getPublishTypeGroupCss = function(publishType) {
		if (publishType) {
			var type = publishType.toLowerCase();
			if (type === 'honouring') {
				return 'fa-circle status-icon--yellow';
			} else if (type === 'published') {
				return 'fa-circle status-icon--green';
			} else if (type == 'preview') {
				return 'fa-eye';
			} else if (type === 'unpublished') {
				return 'fa-circle status-icon--red';
			} else {
				return 'fa-circle status-icon--green'; // Should this really be the default value?
			}
		}
	};

	$scope.addParticipant = function(option) {
		option.noParticipants += 1;
	};
	$scope.subtractParticipant = function(option) {
		if (option.noParticipants > 1)
			option.noParticipants -= 1;
	};

	$scope.model.newQuestions = [];
	$scope.rmQuestion = function(option, index) {
		option.questions.splice(index, 1);
	};
	$scope.addQuestion = function(option, index) {
		option.questions.push($scope.model.newQuestions[index]);
		$scope.model.newQuestions[index] = undefined;
	};

	$scope.deleteProduct = function(option) {
		var index = $scope.model.priceOptions.indexOf(option);
		$scope.model.priceOptions.splice(index, 1);
		$scope.model.newQuestions[index] = undefined;
	};

	$scope.newProduct = function() {
		var priceOption = {
			questions: [],
			status: {
				publishType: 'unpublished'
			},
			noParticipants: 1,
			sellPrice: 0,
			poType: ''
		};
		var questions = {};
		$scope.model.priceOptions.push(priceOption);
	};

	$scope.showPriceOptionsError = function() {
		return productData.shared.submitAttempted && $scope.model.priceOptions.length === 0;
	};

	$scope.priceOptionTypeFieldIsInvalid = function (option) {
		return productData.shared.submitAttempted && !Boolean(option.poType.length);
	};

	$scope.priceOptionTypes = [];
	Product.priceOptionTypes().query(function(res) {
		if (res.length) {
			$scope.priceOptionTypes = res;
		}
	});

	$scope.save = function() {
		productData.updateWorkflow();
	};

	// Set up autosave
	$scope.initForm = function () {
		autoSave.init($scope, ['model.newQuestions', 'model.priceOptions', 'model.questions']);
	};

	// Init the form for autosave
	$scope.initForm();

}]);

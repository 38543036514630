app.directive('avMpTimeslot', ['availabilityUtil', function () {
  return {
    restrict: 'E',
    scope: {
      save: '&saveCallback',
      multiple: '=',
      statusData: '='
    },
    templateUrl: 'availability/components/editor/content-panel/timeslot/mp-timeslot.html',
    controllerAs: 'ctrl',
    controller: function ($scope, $element, $attrs, $routeParams, $location, availabilityUtil) {
      function productTimesContainErrors(times) {
        return _.reduce(times, function (reducer, time) {
          if (!reducer) return time.hasError;

          return reducer;
        }, false);
      }

      $scope.productsParam = $routeParams.productcode;
      $scope.day = {};
      if ($routeParams.date) {
        $scope.date = $routeParams.date;
        availabilityUtil.copyDay($scope.day, $scope.date);
      } else {
        $location.path('/home');
      }

      $scope.day.products = _.map($scope.day.products, function (product) {
        return _.extend(product, {
          times: _.map(product.times, function (time) {
            return _.extend({
              hasError: false
            }, time);
          })
        })
      });

      $scope.$watch('day.products', function (products) {
        $scope.buttonDisabled = _.reduce(products, function (reducer, product) {
          return !reducer ? productTimesContainErrors(product.times) : reducer;
        }, false);
      }, true)

      $scope.addTimeslotToProduct = function (productCode) {
        var product = _.findWhere($scope.day.products, { productCode: productCode });
        product.times.push(availabilityUtil.getNewTimeSlot(availabilityUtil.getNewTimeSlotId(product.times)));
      };

      $scope.addBlockOutDateToProduct = function (productCode) {
        var product = _.findWhere($scope.day.products, { productCode: productCode });
        if (!product.isBlockout) {
          //Blockout date pushed
          product.blockoutdate = $scope.date;
          product.isBlockout = true;
        } else {
          //Blockout date removed
          product.isBlockout = false;
          product.blockoutdate = '';
        }
      };

      $scope.productSelected = function (productCode) {
        return _.findWhere($scope.statusData, { code: productCode }).selected;
      };

      var handleMappingConflict = function (error) {
        $scope.errors = _.map(error, function (message) {
          return message;
        });
      };

      $scope.onFail = function (response) {
        console.error('failed with response :', response);
        var errors = response.data.errors;
        var products = [].concat($scope.day.products);
        var errorIndex = 0;
        //This error is thrown when a session is added for a product
        // that is already mapped to another provider (e.g: Rezdy,Livn etc)
        if (response.status === 409) {
          handleMappingConflict(response.data);
        } else {
          $scope.day.products = products.map(function (product) {
            product.times = product.times.map(function (time) {
              time.error = errors[errorIndex++];
              time.hasError = Boolean(time.error);

              return time;
            });

            return product;
          });
        }
      };

      $scope.saveTimeslots = function () {
        $scope.save({ day: $scope.day, dateString: $scope.date, view: 'calendar', onFail: $scope.onFail, statusData: $scope.statusData });
      };

    }
  };
}]);

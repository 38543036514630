app.controller('ManagePromotionsModalCtrl', function ($scope, $modalInstance, $timeout, selectedProducts, campaigns, Campaign) {
  var self = this;

  function updateProductsFromCampaignSelected(campaignId) {
    $scope.selectedProducts = angular.copy(selectedProducts);

    _($scope.selectedProducts).map(function (product) {
      Campaign.getCampaignStatusPerProduct(campaignId, product.id)
        .then(function (data) {
          var campaignResponse = data.data;
          product.originalSellPrice = product.sellPrice;
          if(angular.isObject(campaignResponse)) {
            product.alreadyInCampaign = "Product already on campaign selected";
            product.sellPrice = campaignResponse.sellPrice;
            product.discount = Number(parseFloat((product.wasPrice - product.sellPrice) * 100 / product.wasPrice).toFixed(2));
          } else {
            product.alreadyInCampaign = "";
            product.discount = Number(parseFloat((product.wasPrice - product.sellPrice) * 100 / product.wasPrice).toFixed(2));
          }
        });
    });
  }

  self.removeFromSelectedProducts = function (product) {
    var productFound = _(selectedProducts).find(function (selectedProduct) {
      return angular.equals(product.id, selectedProduct.id);
    });
    if(productFound){
      selectedProducts = _.without(selectedProducts, productFound);
      $scope.selectedProducts = angular.copy(selectedProducts);
    }
  };

  // mobile accordion table
  self.expandProduct = function (family) {
    family.isExpanded = !family.isExpanded;
  };

  self.calculateNewDiscount = function (product) {
    product.discount = Number(parseFloat((product.wasPrice - product.sellPrice) * 100 / product.wasPrice).toFixed(2));
  };

  self.calculateNewPrice = function (product) {
    product.sellPrice = Number(parseFloat(product.wasPrice - (product.wasPrice * product.discount / 100 )).toFixed(2));
  };

  self.dismiss = function () {
    $modalInstance.dismiss();
  };

  self.submit = function () {
    var validDiscounts = _($scope.selectedProducts).find(function (product) {
      return product.discount <= 0;
    });
    if (validDiscounts) {
      self.errorMessage = "all products selected have to have a discount, please correct the values"
    } else {
      _($scope.selectedProducts).map(function (product) {
        product.campaign = $scope.campaign;
        return product;
      });
      $modalInstance.close($scope.selectedProducts);
    }
  };

  self.campaignOptions = _(campaigns.data).filter(function (campaign) {
    return campaign.status.toLowerCase() === 'pending';
  });

  $scope.selectedProducts = angular.copy(selectedProducts);

  _($scope.selectedProducts).map(function (product) {
    product.discount = Number(parseFloat((product.wasPrice - product.sellPrice) * 100 / product.wasPrice).toFixed(2));
    product.originalSellPrice = product.sellPrice;
  });

  $scope.$watch('selectedProducts', function () {
    self.errorMessage = undefined;
  }, true);

  $scope.$watch('campaign', function (newData) {
    if($scope.campaign) {
      updateProductsFromCampaignSelected(newData.id);
    }
  });

});

app.controller('FinanceBankingCtrl', ['$rootScope', 'Supplier', 'supplier', 'bankingHistory', function($rootScope, Supplier, supplier, bankingHistory) {

  var self = this;
  self.supplierId = $rootScope.selectedSupplier.id;
  self.supplier = supplier;
  self.bankAccount = supplier.bankAccount;
  self.swiftRequired = supplier.address.country.code === 'NZ';
  self.swiftShow = supplier.address.country.code === 'AU';
  self.bsbShow = supplier.address.country.code === 'AU';
  self.changes = bankingHistory;
  self.updateResult = null;

  self.updateDetails = function(form) {
    if (angular.isUndefined(self.bankAccount.swift)) self.bankAccount.swift = '';
    if (supplier.address.country.code === 'NZ') self.bankAccount.BSB = '';
    Supplier.banking(self.supplierId).create(self.bankAccount).$promise.then(function(data) {
      self.bankAccount = data.bankAccount;
      self.changes.push(data.bankAccountHistory);

      self.handleUpdateResult(form, 'SUCCESS');
    }, function() {
      self.handleUpdateResult(form, 'FAILURE');
    });
  };

  self.handleUpdateResult = function(form, result) {
    form.$setPristine();
    self.updateResult = result;
  };
}]);

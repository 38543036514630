app.directive('reviewsProductList', function () {
  return {
    restrict: 'E',
    scope: {
      model: '=ngModel',
      fyOptions: '='
    },
    templateUrl: 'reviews/components/reviews-product-list/reviews-product-list.html',
    controllerAs: 'ctrl',
    controller: function ($scope, $element, $attrs, $rootScope, $filter, Review) {

      var self = this;

      self.supplierId = $rootScope.selectedSupplier.id;
      self.fyOptions = $scope.fyOptions;
      self.statusOptions = ['All', 'Published', 'Honouring', 'Unpublished'];
      self.maxResultsPerPage = 10;
      self.maxVisiblePages = 30;

      initState($scope.model, self.fyOptions[0]);

      // fresh mutable model state
      function initState(reviews, year) {
        self.currentStatus = self.statusOptions[0];
        self.currentYear = year;
        self.currentPredicate = 'productName';
        self.isReversed = false;
        self.currentPage = 1;
        self.reviewsListByProduct = reviews;
        filterReviews();
      }

      function filterReviews() {
        // filter by product publish status
        self.filteredReviewsListByProduct = _.filter(self.reviewsListByProduct, function(product) {
          return (self.currentStatus.toLowerCase() === 'all'
          || product.publishStatus.toLowerCase() === self.currentStatus.toLowerCase());
        });
      }

      self.sortReviews = function(reviews) {
        return $filter('orderBy')(reviews, self.currentPredicate, self.isReversed);
      };

      self.getPaginatedResults = function() {
        var displayFromIndex = (self.currentPage - 1) * self.maxResultsPerPage;
        var displayToIndex = displayFromIndex + self.maxResultsPerPage - 1;
        return _.filter(self.sortReviews(self.filteredReviewsListByProduct), function(product, index) {
          return index >= displayFromIndex && index <= displayToIndex;
        });
      };

      self.hasNoResults = function() {
        return _.size(self.filteredReviewsListByProduct) == 0;
      };

      self.getFilteredResultCount = function() {
        return _.size(self.filteredReviewsListByProduct);
      };

      self.getPageCount = function() {
        return Math.ceil(self.getFilteredResultCount() / self.maxResultsPerPage);
      };

      self.hasMultiplePages = function() {
        return self.getPageCount() > 1;
      };

      self.selectStatus = function(status) {
        self.currentStatus = status;
        filterReviews();
      };

      self.selectYear = function(year) {
        self.currentYear = year;

        Review.reviewsByProduct(self.supplierId, self.currentYear).then(function(reviews) {
          initState(reviews, year);
        });
      };

      self.orderByProperty = function(predicate) {
        // only reverse order on the 2nd sort for a column
        if (predicate === self.currentPredicate) {
          self.isReversed = !self.isReversed;
        } else {
          // numReviews/nps should default to high-low, which is a reversed sort
          if (predicate === 'numReviews' || predicate === 'nps') {
            self.isReversed = true;
          } else {
            self.isReversed = false;
          }
        }

        self.currentPredicate = predicate;
      };

    }
  };
});

app.factory('Review', function ($resource, $q) {
  /*
   List review summaries for all products for a given supplier (filter by financial year eg '2015-2016'):
   GET /supplier/:supplierId/reviews?fy=:financialYear
   Note: unsure what values should be for "publishStatus"
   */
  var getMockReviewSummaryResponse = function() {
    return [
      {
        "productName": "product " + Math.random().toString(36).substring(7),
        "productCode": "ABC123-M",
        "location": "Melbourne, Australia",
        "publishStatus": "published",
        "numReviews": Math.round(Math.random() * 100),
        "nps": Math.round(Math.random() * 200) - 100
      },
      {
        "productName": "product " + Math.random().toString(36).substring(7),
        "productCode": "DEF456-M",
        "location": "Sydney, Australia",
        "publishStatus": "unpublished",
        "numReviews": Math.round(Math.random() * 100),
        "nps": Math.round(Math.random() * 200) - 100
      },
      {
        "productName": "product " + Math.random().toString(36).substring(7),
        "productCode": "GHI789-M",
        "location": "Auckland, New Zealand",
        "publishStatus": "published",
        "numReviews": Math.round(Math.random() * 100),
        "nps": Math.round(Math.random() * 200) - 100
      }
    ];
  };

  /*
   List review details for a single product (filter by financial year):
   GET /supplier/:supplierId/reviews/:productCode?fy=:financialYear
   Note: unsure what values will be for "reviewStatus"
   Note: may not need priceOptionCode
   Note: in designs, voucherNumber appears to be a link - unsure how this is generated
   */
  var getMockReviewDetailsResponse = function() {
    return {
      "productName": "V8 Race Car Driving - 4 Laps",
        "productCode": "VRE450-M",
        "productLocation": "Sydney, Australia",
        "productPublishUrl": "https://www.redballoon.com.au/product/driving-experiences/v8-experiences/v8-race-car-driving-4-laps",
        "reviews": [
        {
          "reviewTitle": "A relaxing experience",
          "reviewStatus": "APPROVED",
          "productType": "Adult",
          "reviewDate": "2016-02-08T10:45:02.000+00:00",
          "reviewerName": "Jane Day",
          "voucherNumber": "voucherNumber",
          "reviewText": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          "productImage": "https://static.redballoon.com.au/media/products/au/321x214/VRE450_01.jpg",
          "priceOptionCode": "VRE450",
          "score": Math.round(Math.random() * 10)
        },
        {
          "reviewTitle": "A bad experience",
          "reviewStatus": "APPROVED",
          "productType": "Child",
          "reviewDate": "2016-03-15T10:45:02.000+00:00",
          "reviewerName": "Jane Day",
          "voucherNumber": "voucherNumber",
          "reviewText": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          "productImage": "https://static.redballoon.com.au/media/products/au/321x214/VRE450_01.jpg",
          "priceOptionCode": "VRE453",
          "score": Math.round(Math.random() * 10)
        },
        {
          "reviewTitle": "An amazing experience",
          "reviewStatus": "REJECTED",
          "productType": "FAMILY",
          "reviewDate": "2016-02-09T22:40:00.000+00:00",
          "reviewerName": "Jane Day",
          "voucherNumber": "voucherNumber",
          "reviewText": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          "productImage": "https://static.redballoon.com.au/media/products/au/321x214/VRE450_01.jpg",
          "priceOptionCode": "VRE452",
          "score": Math.round(Math.random() * 10)
        }
    ]
    }
  };

  /*
   Get an NPS summary for last 30 days for a given supplier.
   GET /supplier/:supplierId/reviews/nps/summary
   */
  var mockNpsSummaryResponse = {
    "nps": Math.round(Math.random() * 200) - 100,
    "promotersPercent": 70,
    "passivesPercent": 20,
    "detractorsPercent": 10
  };

  /*
   Get an NPS summary for the last 30 days for a given product.
   GET /supplier/:supplierId/reviews/nps/summary?productCode=:productCode
   */
  var mockNpsSummaryForProductResponse = {
    "nps": Math.round(Math.random() * 200) - 100,
    "month": 'Aug'
  };

  /*
   Get historical NPS data for a given supplier (filter by financial year):
   GET /supplier/:supplierId/reviews/nps?fy:financialYear
   */
  var getMockNpsTrendForYear = function(year) {
    return {
      "financialYear": year,
      "averageNps": Math.round(Math.random() * 200) - 100,
      "npsByMonth": _.map(_.range(12), function() {
        return Math.round(Math.random() * 200) - 100
      })
    }
  };

  return {

    reviewsByProduct: function(supplierId, year) {
      var q = $q.defer();

      var url = "/api/suppliers/:supplierId/productswithreviewstats";
      var resource = $resource(url, {supplierId: supplierId, fy: year.split("/")[0]});

      $q.all([resource.get().$promise])
                 .then( function(result) {
                  //console.log('reviewsByProduct', result[0].records);
                   q.resolve(result[0].records);
                 });

      /*var results = [];
      _.each(_.range(22), function() {
       results = results.concat(getMockReviewSummaryResponse());
      });
      q.resolve(results);*/

      return q.promise;
    },

    reviewsForSingleProduct: function(supplierId, productCode, year) {
      var q = $q.defer();

      var url = "/api/suppliers/:supplierId/products/:productCode/reviews";
      var resource = $resource(url, {supplierId: supplierId, productCode: productCode, fy: year.split("/")[0]});

      $q.all([resource.get().$promise])
                 .then( function(result) {
                  //console.log('reviewsForSingleProduct', result);
                   q.resolve(result[0].records);
                 });

      /*var results = getMockReviewDetailsResponse();
      _.each(_.range(12), function() {
        results.reviews = results.reviews.concat(getMockReviewDetailsResponse().reviews);
      });
      q.resolve(results);*/

      return q.promise;

      // note: should check for null year being passed
      /*return $resource('/api/supplier/:code/reviewdetail', {code: productCode}, {
        'query': {method: 'GET'}
      });*/
    },

    latestReview: function(supplierId) {
      var result = getMockReviewDetailsResponse();
      result.reviews = [result.reviews[0]];

      var q = $q.defer();
      q.resolve(result);
      return q.promise;
    },

    npsSummary: function(supplierId) {
      var q = $q.defer();
      // q.resolve(mockNpsSummaryResponse);

      var url = "/api/suppliers/:supplierId/reviews/nps/summary";
      var resource = $resource(url, {supplierId: supplierId});

      $q.all([resource.get().$promise])
                 .then( function(result) {
                  //console.log('npsSummary', result);
                   q.resolve(result[0]);
                 });

      return q.promise;
    },

    npsSummaryForProduct: function(supplierId, productCode) {
      var q = $q.defer();
      //q.resolve(mockNpsSummaryForProductResponse);

      var url = "/api/suppliers/:supplierId/products/:productCode/reviews/nps/summary";
      var resource = $resource(url, {supplierId: supplierId, productCode: productCode});

      $q.all([resource.get().$promise])
                 .then( function(result) {
                  //console.log('npsSummaryForProduct', result);
                   q.resolve(result[0]);
                 });


      return q.promise;
    },

    npsTrend: function(supplierId, years) {

      // get both fy trend data
      var q = $q.defer();
      var url = "/api/suppliers/:supplierId/reviews/npstrend";
      var fy1Resource = $resource(url, {supplierId: supplierId, fy: years[0].replace("/","-")});
      var fy2Resource = years[1] ? $resource(url, {supplierId: supplierId, fy: years[1].replace("/","-")}) : fy1Resource;

      $q.all([fy1Resource.get().$promise, fy2Resource.get().$promise])
                 .then( function(result) {
                  //console.log('npsTrend', result);
                  result[0]["financialYear"] = years[0];
                  result[1]["financialYear"] = years[1] ? years[1] : years[0];
                   q.resolve([result[0],result[1]]);
                 });

      /*q.resolve(_.map(years, function(year) {
        return getMockNpsTrendForYear(year);
      }));*/

      return q.promise;

    }

  };
});

app.controller('BookingsListContainerCtrl', ['$rootScope', '$routeParams', '$scope', '$location', 'Booking', 'counts', 'productsSummary', 'branches', function($rootScope, $routeParams, $scope, $location, Booking, counts, productsSummary, branches) {
  var self = this;
  self.supplierId = $rootScope.selectedSupplier.id;
  self.tabs = [
    {id: 0, title: 'New', count: counts.newCount, type: 'new'},
    {id: 1, title: 'Confirmed', count: counts.confirmedCount, type: 'confirmed'},
    {id: 2, title: 'Completed', count: counts.completedCount, type: 'completed'}
  ];
  self.searchVoucherNumber = null;
  self.searchName = null;
  self.selectTab = selectTab;
  self.isTabActive = isTabActive;
  self.hasSearchQueries = hasSearchQueries;
  self.tabSelected = selectTab(getInitialTab(), true);
  self.search = search;
  self.productsSummary = angular.copy(productsSummary);
  self.branches = angular.copy(branches);
  self.filterByProduct = filterByProduct;
  self.filterByBranch = filterByBranch;
  self.hasBranches = hasBranches;

  // filter with date
  self.dateOptions = {
    changeYear: true,
    changeMonth: true,
    yearRange: '1900:c+30',
    dateFormat: 'yy-mm-dd'
  };
  self.dateFormat = 'YYYY-MM-DD';
  self.startDate = null;
  self.endDate = null;
  self.searchByDate = searchByDate;

  init();

  function init() {
    $rootScope.tabSelected = self.tabSelected;
    self.productsSummary.unshift({
      code: null,
      name: 'All Products'
    });
    if (angular.isDefined($routeParams.productCode)) {
      self.selectedProduct = _.find(self.productsSummary, function(p) {
        return p.code === $routeParams.productCode;
      });
    } else {
      self.selectedProduct = self.productsSummary[0];
    }

    self.branches.unshift({
      branchId: null,
      facilityId: null,
      productCode: null,
      state: null,
      branchName: "All Branches",
      email: null
    });
    if (angular.isDefined($routeParams.facilityId)) {
      self.selectedBranch = _.find(self.branches, function(b) {
        return b.facilityId === $routeParams.facilityId;
      });
    } else {
      self.selectedBranch = self.branches[0];
    }

    // added for date filter setting in url
    if (angular.isDefined($routeParams.startDate)) {
      self.startDate = parseDate($routeParams.startDate);
    }

    if (angular.isDefined($routeParams.endDate)) {
      self.endDate = parseDate($routeParams.endDate);
    }
  }

  $scope.$on('update-counts', function () {
    findAllCounts();
  });

  function getInitialTab() {
    var path = $location.$$path;
    if (path.indexOf('new') !== -1) return self.tabs[0];
    if (path.indexOf('confirmed') !== -1) return self.tabs[1];
    if (path.indexOf('completed') !== -1) return self.tabs[2];
  }

  function isTabActive(tab) {
    return tab === self.tabSelected;
  }

  function selectTab(tab, init) {
    if (typeof init !== 'undefined' && init) {
      tab.active = true;
      self.tabSelected = tab;
      return tab;
    } else if (self.tabSelected === tab) {
      return tab;
    }

    self.tabSelected.active = false;
    tab.active = true;

    self.tabSelected = tab;

    $rootScope.tabSelected = self.tabSelected;

    finalApply();
  }

  function getSortBy() {
    if (self.tabSelected.type === 'new') return "redeemDate";
    else if (self.tabSelected.type === 'confirmed') return "supplierConfirmedOnDate";
    else if (self.tabSelected.type === 'completed') return "confirmDateTime";
  }

  function getOrder() {
    if (self.tabSelected.type === 'new') return "DESC";
    else if (self.tabSelected.type === 'confirmed') return "DESC";
    else if (self.tabSelected.type === 'completed') return "DESC";

  }

  function findAllCounts() {
    Booking.findAllCounts(self.supplierId, $routeParams.productCode, $routeParams.facilityId).query().$promise.then(
      function(data) {
        self.tabs[0].count = data.newCount;
        self.tabs[1].count = data.confirmedCount;
        self.tabs[2].count = data.completedCount;
      }, function(error) {
        self.bookingErrors.push(error);
      }
    );
  }

  function hasSearchQueries() {
    return (
      (self.searchName !== null && self.searchName.trim() !== '') ||
      (self.searchVoucherNumber !== null && self.searchVoucherNumber.trim() !== '')
    );
  }

  function search() {
    $location.path('/bookings/search/').search({voucherNumber: self.searchVoucherNumber, name: self.searchName, page: 1, sortBy: 'name', order: 'ASC'});
  }

  function filterByProduct(product) {
    self.selectedProduct = product;
    finalApply();
  }

  function filterByBranch(branch) {
    self.selectedBranch = branch;
    finalApply();
  }

  function hasBranches() {
    return self.branches.length > 1;
  }

  function searchByDate() {
    finalApply();
  }

  // make sure date filter will always apply as long as they are set
  function finalApply() {
    $scope.dateError = false;
    var formatStartDate = self.startDate
      ? moment(self.startDate).format(self.dateFormat)
      : null;
    var formatEndDate = self.endDate
      ? moment(self.endDate).format(self.dateFormat)
      : null;
    if(formatStartDate && formatEndDate && formatEndDate <= formatStartDate) {
      $scope.dateError = true;
      return;
    }
    $location.path('/bookings/list/' + self.tabSelected.type)
      .search({
        startDate: formatStartDate,
        endDate: formatEndDate,
        page: 1,
        productCode: self.selectedProduct.code,
        facilityId: self.selectedBranch.facilityId,
        sortBy: getSortBy(),
        order: getOrder()
      });
  }

  // strict parse only for YYYY-MM-DD
  function parseDate(date) {
    if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(date)) {
      var m = moment(date, self.dateFormat);
      if (m.isValid()) {
        return m.toDate();
      }
    }
    return null;
  }

}]);

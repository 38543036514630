app.controller('ProductSummaryCtrl', ['$rootScope', '$scope', '$http', 'productData', 'Product', 'autoSave', '$timeout', function($rootScope, $scope, $http, productData, Product, autoSave, $timeout) {

	$scope.model = productData.summary;

	$scope.productFamilyCode = productData.shared.productFamilyCode;
	$scope.supplierId = $rootScope.selectedSupplier.id;
	$scope.supplierCountryCode = $rootScope.selectedSupplier.address.country ? $rootScope.selectedSupplier.address.country.code : '';
	$scope.categoryOptionsCache = {};

	// get display name for category
	$scope.getCategoryName = function(category) {
		return category ? category.parentName + ' > ' + category.name : 'No category selected';
	};

	// fetch country options and set country
	$http.get('/countries').then(function(data) {
		// limit set to Australia/NZ
		$scope.countryOptions = _.filter(data.data, function(c) {
			return c.code == 'AU' || c.code == 'NZ';
		});
		// set model.country, defaulting to the supplier's countryCode if there's no countryCode set for the product
		var selectedCountryCode = $scope.model.countryCode ? $scope.model.countryCode : $scope.supplierCountryCode;
		$scope.model.country = _.find($scope.countryOptions, function(c) {
			return c.code === selectedCountryCode;
		});
    productData.copyOldData();
	}, app.alertError);

	// fetch & update category options based on model.country
	// Note: AU/NZ category sets are disjoint, so swapping between them will set $scope.model.category to undefined
	$scope.$watch(function() {return $scope.model.country}, function() {
		if ($scope.model.country) {
			var countryCode = $scope.model.country.code;

			// keep a cache of categoryOptions so we don't need to keep re-fetching
			if ($scope.categoryOptionsCache[countryCode]) {
				$scope.categoryOptions = $scope.categoryOptionsCache[countryCode];
				$scope.setMatchingCategory();
			} else {
				Product.categories(countryCode).query().$promise.then(function(categories) {
					// order alphabetically by parent name TODO: confirm UX for this
					$scope.categoryOptionsCache[countryCode] = _.sortBy(categories, function(category) {
						return category.parentName;
					});
					$scope.categoryOptions = $scope.categoryOptionsCache[countryCode];
					$scope.setMatchingCategory();
					$scope.enableAutoSave();
				}, app.alertError);
			}
		}
	});

	// set category to category option with matching id. This means the initial category will be set to a valid option.
	$scope.setMatchingCategory = function() {
		if ($scope.model.category) {
			$scope.model.category = _.find($scope.categoryOptions, function(categoryOption) {
				return $scope.model.category.id === categoryOption.id;
			});
		}
	};

  $scope.showCountryError = function() {
    return productData.shared.submitAttempted && ($scope.model.country == null || $scope.model.country == '');
  };

	$scope.showCategoryError = function() {
		return productData.shared.submitAttempted && ($scope.model.category == null || $scope.model.category == '');
	};

	// Update workflow item
	$scope.save = function() {
		productData.updateWorkflow();
	};

	// Set up autosave
	$scope.initForm = function() {
		autoSave.init($scope);
	};

	// Note: we delay enabling auto-save until the async calls (which modify $scope) finish
	// if they fail, auto-save will not be enabled, but this seems reasonable
	$scope.enableAutoSave = function() {
		$scope.initForm();
	};

}]);

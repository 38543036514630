app.controller('ProductViewCtrl', ['$rootScope','$window', '$route','$scope', '$filter', '$modal', '$sce', '$routeParams', 'product', 'Product', 'nps', 'productData', 'moment', '$location', '$http','Campaign',
    function($rootScope,$window, $route,$scope, $filter, $modal, $sce, $routeParams, product, Product, nps, productData, moment, $location, $http, Campaign) {
  var self = this;

  self.publishType = $routeParams.publishType;
  self.previewMode = $routeParams.preview && $routeParams.preview === 'true' ? true : false;
  self.pageTitle = self.previewMode ? 'View Product' : 'Edit Product';
  self.product = product;
  self.mode = $routeParams.mode;
  self.selectedSupplierType = $rootScope.selectedSupplier.productType;
  $scope.campaign = undefined;
  self.isInCampaignWarningShowed = false;
  Product.getCurrentOrFutureCampaigns($routeParams.productCodeOrWorkflowId).query(function (data) {
    if (data.length > 0) {
      self.isInCampaignWarningShowed = true;
    }
  });
  
      // comment out this block (waiting for the design)
  // $rootScope.product = product;

  self.displayCopyButton = function() {
    if(self.previewMode ==false && $routeParams.mode == 'edit' && self.publishType== 'Draft'){
      return true;
    }
    return false;
  }
 

  $scope.getCampaignColor = function() {
    if($scope.campaign) {
      return ($scope.campaign.beginTime > new Date().getTime() ? '#4caf50':'red')
    }
  }
  //self.nps = nps.value;

  // initialise model from live product or draft (workflow) product based on publishType
  if (self.publishType && (self.publishType.toLowerCase() === 'draft' || self.publishType.toLowerCase() === 'preview')) {
      productData.initFromWorkflowProduct(self.product,self.mode);
  } else {
    productData.initFromLiveProduct(self.product,self.mode);
  }
  productData.copyOldData();
  // when in view mode or edit mode of submitted data we disable auto updates
  // if (self.previewMode || productData.shared.submitted) {
  if (self.previewMode) {
    productData.shared.disableAutoUpdates = true;
  }

  Campaign.getCampaignByProduct(productData.shared.productFamilyCode).then(function(res){
    $scope.campaign = (res.data || [])[0];
    var item = self.sections.find(function(a){
      return a.name === 'prices'
    })
    if(item){
      item.disable = !!$scope.campaign
    }
    $scope.$apply();
  })

  var lastFocusedPanelIndex = 0;

	function handleScrollDebounced () {
		_.throttle(handleScroll(), 200);
	}

	function handleScroll () {
		var focusedPanelIndex = getFocusedPanelIndex();

		if (focusedPanelIndex !== lastFocusedPanelIndex) {
			lastFocusedPanelIndex = focusedPanelIndex;

			self.sections = self.sections.map(function (section, index) {
				section.viewing = index === focusedPanelIndex;

				return section;
			});

			$scope.$apply();
		}
	}

	function getFocusedPanelIndex () {
		var bodyScrollTop = $('body').scrollTop();
		var panelIndex = 0;
		var $panels = $('.panel--product');

		if ($panels.length) $panels.toArray().reverse().forEach(function (panel, index) {
			if (bodyScrollTop <= $(panel).offset().top + $(panel).height()) {
				panelIndex = ($panels.length - 1) - index;
			}
		});

		return panelIndex;
	}

	function initScrollSpy() {
	  $(window).on('scroll.productScrollSpy', handleScrollDebounced);
	}

	$scope.$on('$destroy', function () {
		$(window).off('scroll.productScrollSpy');
	});

	initScrollSpy();

  self.getLastUpdated = function() {
    var lastUpdated = Math.floor(productData.shared.lastUpdated / 1000);
    return lastUpdated ? moment.unix(lastUpdated).format("DD-MM-YYYY, hh:mm a") : '-';
  };

  self.getSubmitted = function() {
    return productData.shared.submitted
  };


  self.sections = [{
    'name': 'productInfo',
    'fullName': 'Product Info',
    'path': 'products/product/product-summary.html',
    'ctrl': 'ProductSummaryCtrl',
    'id': 0,
    'viewing': true,
    'editing': !self.previewMode,
    'productType' : $rootScope.selectedSupplier.productType
  }, {
    'name': 'prices',
    'fullName': 'Prices',
    'path': 'products/product/product-prices.html',
    'ctrl': 'PricesCtrl',
    'id': 1,
    'viewing': false,
    'editing': !self.previewMode,
    'disable': false,
    'productType' : $rootScope.selectedSupplier.productType
  }, {
    'name': 'needToKnow',
    'fullName': 'Need To Know',
    'path': 'products/product/product-needtoknow.html',
    'ctrl': 'ToKnowCtrl',
    'id': 2,
    'viewing': false,
    'editing': !self.previewMode,
    'productType' : $rootScope.selectedSupplier.productType
  }, {
    'name': 'moreInfo',
    'fullName': 'More Info',
    'path': 'products/product/product-moreinfo.html',
    'ctrl': 'MoreInfoCtrl',
    'id': 3,
    'viewing': false,
    'editing': !self.previewMode,
    'productType' : $rootScope.selectedSupplier.productType
  }, {
    'name': 'maps',
    'fullName': 'Maps',
    'path': 'products/product/product-maps.html',
    'ctrl': 'MapsCtrl',
    'id': 4,
    'viewing': false,
    'editing': !self.previewMode,
    'productType' : $rootScope.selectedSupplier.productType
  }, {
    'name': 'imgVid',
    'fullName': 'Images & Video',
    'path': 'products/product/product-images.html',
    'ctrl': 'ImagesCtrl',
    'id': 5,
    'viewing': false,
    'editing': !self.previewMode,
    'productType' : $rootScope.selectedSupplier.productType
  }
    /**
     * comment out this block (waiting for the design)
     */
  //   , {
  //   'name': 'leadTime',
  //   'fullName': 'Lead Time',
  //   'path': 'products/product/product-lead-time.html',
  //   'ctrl': 'ProductLeadTimeCtrl',
  //   'id': 6,
  //   'viewing': true,
  //   'editing': !self.previewMode
  // }

  ];

  self.sectionOrder = 'id';

  self.isInvalidAndSubmitAttempted = function () {
    return !productData.shared.hasMandatoryFieldsForSubmit() && productData.shared.submitAttempted;
  };

  self.toggleViewing = function($index) {
    angular.forEach(self.sections, function(e, i) {
      e.viewing = false;
    });
    var section = self.sections[$index];
    section.viewing = !section.viewing;
  };

  self.redirectToEditMode = function() {
    if (self.previewMode) {
      var redirect = function(publishType, productCodeOrWorkflowId) {
        var href = '/myproducts/view/' + productCodeOrWorkflowId + '?publishType=' + publishType + '&preview=false';
        $location.url(href);
      };

      // check for existing draft with current familyCode, if so then redirect to it, otherwise redirect with
      // current publish type (which will load 'edit product' for current product)
      Product.supplierWorkflowProducts({supplierId: $rootScope.selectedSupplier.id}).fetchAll(function(res) {
        var existingDraft = _.find(res, function(workflowProduct) {
          var details = JSON.parse(workflowProduct.entityDetails);
          return $routeParams.productCodeOrWorkflowId === details.productFamilyCode;
        });

        if (existingDraft != null) {
          redirect('Draft', existingDraft.id);
        } else {
          redirect(self.publishType, $routeParams.productCodeOrWorkflowId);
        }
      }, function() {
        redirect(self.publishType, $routeParams.productCodeOrWorkflowId);
      });
    }
  };

  self.submit = function() {
    productData.submitWorkflow();
  };

  self.copyProductFromEdit = function() {
    $location.search('mode', 'copy');
    $routeParams.mode = 'copy';
    $window.location.reload();
  };

  self.goToManageAvailability = function() {
    $location.path('/myproducts/availability/manager/code=' + product.code + '/calendar');
  };

  self.markAsExclusive = function() {
    $http({
      method: 'PUT',
      url: '/api/productFamily/' + product.code,
      data: {
        field: 'exclusive status',
        value: !product.isExclusive
      }
    }).success(function(data, status, headers, config) {
      product.isExclusive = !product.isExclusive;
    }).error(function(data, status, headers, config) {
      console.log('Set as Exclusive change state error: ' + data);
    });
  };


  self.productPageTypeLabel = function () {
    return self.pageTitle === 'Edit Product' ? 'Product edit' : 'new product';
  };

}]);

/**
 * productPanelController
 * -----------------------------
 * @author Curtis McMullan <curtis.mcmullan@redballoon.com.au>
 */

(function () {

  angular
    .module('app')
    .controller('productPanelController', productPanelController);

  /**
   * productPanelController
   * -----------------------------
   * @param $scope
   */
  function productPanelController($scope) {

    /**
     * editing
     * ----------------------
     */
    if ($scope.editing === undefined) {
      $scope.editing = false;
    }

    if ($scope.productType === undefined) {
      $scope.productType = "DEFAULT";
    }

    /**
     * toggleEdit
     * ----------------------
     */
    $scope.toggleEdit = function () {
      $scope.editing = !$scope.editing;
      if ($scope.editCallback) {
        $scope.editCallback();
      }
    };

  };

})();

app.directive('avSelectedProducts', function() {
	return {
		restrict: 'E',
		scope: {
			ngModel:'='
		},
		templateUrl: 'availability/components/shared/selected-products/selected-products.html',
		controllerAs: 'ctrl',
		controller: function($scope, $element, $attrs, $location, $routeParams) {
      if ($location.path().indexOf('/myproducts/availability/editor/timeslot/single/') > -1) {
				$scope.statusData = _.findWhere($scope.ngModel, {code: $routeParams.selected});
			} else {
				$scope.statusData = $scope.ngModel[0];
			}

			$scope.generateProductHref = function(familyCode) {
		    return '/myproducts/view/' + familyCode + '?preview=true';
		  };

			$scope.expandSelections = function() {
				$scope.isSelectionsExpanded = $scope.isSelectionsExpanded ? false : true;
			};

			$scope.getPublishedTypeGroupCss = function(publishType) {
				var type = publishType.toLowerCase();
				if (type === 'honouring') {
					return 'fa-circle status-icon--red';
				} else if (type === 'published') {
					return 'fa-circle status-icon--green';
				} else {
					return '';
				}
			};
		},
	};
});

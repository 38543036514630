"use strict";

Dropzone.autoDiscover = false;

var app = angular.module('app', [
  'angularMoment',
  'ngRoute',
  'ngCookies',
  'route-segment',
  'view-segment',
  'ngResource',
  'remoteValidation',
  'ui',
  'ui.utils',
  'ui.bootstrap',
  'rb-logentries',
  'sticky',
  'smoothScroll',
  'ui.map',
  'chart.js',
  'ngDropzone',
  'ui-library.forms',
  'ui-library.maps',
  'ui-library.product'
]).constant('angularMomentConfig', {
  preprocess: 'utc', // required to convert UTC time in directives
});

app.service('fileUpload', ['$http', function ($http) {
  this.uploadFileToUrl = function (file, uploadUrl, onSuccess, onError) {
    var fd = new FormData();
    fd.append('file', file);
    $http.post(uploadUrl, fd, {
      transformRequest: angular.identity,
      headers: {'Content-Type': undefined}
    })
        .success(onSuccess)
        .error(onError);
  };
}]);

app.directive('fileModel', ['$parse', function ($parse) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      var model = $parse(attrs.fileModel);
      var modelSetter = model.assign;

      function setIt() {
        scope.$apply(function () {
          modelSetter(scope, element[0].files[0]);
        });
      }

      element.bind('change', setIt);

      scope.$on('$destroy', function () {
        element.unbind('change', setIt);
      });
    }
  };
}]);

// Directive to set a form dirty flag when a input[type=file] is changed (known problem)

app.directive('dirtyOnChange', function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      var form = scope.$eval(attrs.dirtyOnChange);

      function onChangeHandler() {
        scope.$apply(function () {
          form.$setDirty();
        })
      }

      element.bind('change', onChangeHandler);
    },
  };
});

app.directive('benchmark', function () {
  return {
    restrict: 'AE',
    scope: {
      benchmark: '=value'
    },
    templateUrl: 'common/benchmark-widget.html',
    link: function (scope) {
      // Added a watch on benchmark due to the promise resolving
      // in regards to reviewsHomepageNpsSummary directive
      // Review.npsSummary function
      scope.$watch('benchmark', function(newValue) {
        if (newValue) {
          scope.benchmark = newValue;
          scope.position = ((newValue + 100) / 200) * 100;
        }
      }, true);
    }
  };
});

app.alertError = function (response) {
  var msg = "Error";
  if (_.isString(response)) {
    msg = "Error: " + response;
  } else {
    msg = 'Error performing action:  ' + response.config.method + ' : ' + response.data;
  }
  alert(msg.substring(0, 500));
  LE.error(msg);
};

app.config(['ChartJsProvider', function (ChartJsProvider) {
  // Configure all line charts
  ChartJsProvider.setOptions('Line', {
    bezierCurve: false,
    datasetFill: false
  });
}])

app.config(['$httpProvider', '$routeSegmentProvider', '$routeProvider', '$locationProvider',
  function ($httpProvider, $routeSegmentProvider, $routeProvider, $locationProvider) {

    $routeSegmentProvider.options.autoLoadTemplates = true;
    $locationProvider.html5Mode(true);
    // Routes
    $routeSegmentProvider
        .when('/', 'landingpage')
        .when('/home', 'home')

        .when('/account', 'account')

        .when('/company', 'company')
        .when('/company/account-details', 'company.accountDetails')
        .when('/company/agency-agreement', 'company.agencyAgreement')
        //.when('/company/agency-agreement/:id', 'company.agencyAgreementDetails')
        //.when('/company/insurance', 'company.insurance')
        .when('/company/user-accounts', 'company.userAccounts')

        .when('/myproducts', 'products')
        .when('/myproducts/list', 'products.list')
        .when('/myproducts/view/:productCodeOrWorkflowId', 'products.view')
        .when('/myproducts/add', 'products.add')
        .when('/myproducts/availability', 'products.selectAvailability')
        .when('/myproducts/availability/select', 'products.selectAvailability')
        .when('/myproducts/manage-promotions', 'products.managePromotions')
        .when('/myproducts/availability/manager/:productcode/:view/:yyyymm?', 'products.availability.manager')
        .when('/myproducts/availability/editor/timeslot/single/:date/:selected/:productcode/:id', 'products.availability.editor.stimeslot')
        .when('/myproducts/availability/editor/timeslot/multiple/:date/:productcode', 'products.availability.editor.mtimeslot')
        .when('/myproducts/availability/editor/datebulk/edit/:productcode/:filter', 'products.availability.editor.editdatebulk')
        .when('/myproducts/availability/editor/datebulk/add/:productcode', 'products.availability.editor.adddatebulk')

        .when('/myproducts/availability/editor/datebulk/addblockoutdate/:productcode', 'products.availability.editor.addblockoutdate')
        .when('/myproducts/availability/editor/datebulk/editblockoutdate/:productcode', 'products.availability.editor.editblockoutdate')

        //changes
        .when('/myproducts/availability/editor/addblockoutContent/add/:productcode', 'products.availability.editor.blockoutContent')


        .when('/bookings', 'bookings')
        .when('/bookings/list/', 'bookings.list')
        .when('/bookings/search/', 'bookings.search')
        .when('/bookings/list/new', 'bookings.list.new')
        .when('/bookings/list/confirmed', 'bookings.list.confirmed')
        .when('/bookings/list/completed', 'bookings.list.completed')
        .when('/bookings/view/:id', 'bookings.view')

        .when('/orders', 'orders')
        .when('/orders/list/', 'orders.list')
        .when('/orders/list/new', 'orders.list.new')
        .when('/orders/search/', 'orders.search')
        .when('/orders/list/confirmed', 'orders.list.confirmed')
        .when('/orders/list/completed', 'orders.list.completed')
        .when('/orders/view/:id', 'orders.view')

        .when('/financials', 'financials')
        .when('/financials/remittance', 'financials.remittance')
        .when('/financials/invoice', 'financials.invoice')
        .when('/financials/payments', 'financials.payments')
        .when('/financials/banking', 'financials.banking')

        .when('/reviews', 'reviews.parent')
        .when('/reviews/summary', 'reviews.parent.summary')
        .when('/reviews/products/:productCode', 'reviews.parent.product')

        .when('/login', 'login')
        .when('/login/forgot-password/:email', 'login.forgotPassword')
        .when('/login/change-password', 'login.changePassword')
        .when('/logout', 'logout')

        .when('/signup', 'signup')
        .when('/signup/company', 'signup.company')
        .when('/signup/insurance', 'signup.insurance')
        .when('/signup/contacts', 'signup.contacts')
        .when('/signup/qa', 'signup.qa')
        .when('/signup/complete', 'signup.complete')
        .when('/signup/failure', 'signup.failure');

    // Home Segments
    $routeSegmentProvider
        .segment('home', {
          templateUrl: 'home/home-menu.html',
          controller: 'HomeMenuCtrl',
          controllerAs: 'ctrl',
          resolve: {data: loadProfile}
        })
        .within()
        .segment('home', {
          'default': true,
          templateUrl: 'home/home.html',
          controller: 'HomeCtrl',
          controllerAs: 'ctrl',
          resolve: {
            supplier: findSupplier,
            supplierContracts: findSupplierContracts,
            users: findUsers,
            paymentsTrendPrimary: findPaymentsPrimary,
            paymentsTrendSecondary: findPaymentsSecondary,
            remittanceYears: findFinancialYears
          }
        })
        .up();

    // Landingpage Segments
    $routeSegmentProvider
        .segment('landingpage', {
          templateUrl: 'landingpage/landingpage.html',
          controller: 'LandingpageCtrl',
          controllerAs: 'ctrl'
        });

    // Account Segments
    $routeSegmentProvider
        .segment('account', {
          templateUrl: 'account/account-details.html',
          controller: 'AccountDetailsCtrl',
          controllerAs: 'ctrl',
          resolve: {data: loadProfile}
        });

    // Company Segments
    $routeSegmentProvider
        .segment('company', {
          templateUrl: 'company/company-menu.html',
          controller: 'CompanyMenuCtrl',
          controllerAs: 'theCompanyMenu',
          resolve: {data: loadProfile}
        })
        .within()
        .segment('accountDetails', {
          'default': true,
          templateUrl: 'company/account-details.html',
          controller: 'CompanyAccountDetailsCtrl',
          controllerAs: 'ctrl',
          resolve: {supplier: findSupplier}
        })
        .segment('agencyAgreement', {
          templateUrl: 'company/agency-agreement.html',
          controller: 'CompanyAgencyAgreementCtrl',
          controllerAs: 'ctrl',
          resolve: {supplierContracts: findSupplierContracts}
        })
        .segment('agencyAgreementDetails', {
          templateUrl: 'company/agency-agreement-details.html',
          controller: 'CompanyAgencyAgreementDetailsCtrl',
          controllerAs: 'ctrl',
          dependencies: ['id'],
          resolve: {supplierContract: findSupplierContract}
        })
        .segment('insurance', {
          templateUrl: 'company/insurance.html',
          controller: 'CompanyInsuranceCtrl',
          controllerAs: 'ctrl',
          resolve: {supplier: findSupplier, insuranceFiles: findSupplierInsuranceFiles}
        })
        .segment('userAccounts', {
          templateUrl: 'company/user-accounts.html',
          controller: 'CompanyUserAccountsCtrl',
          controllerAs: 'ctrl',
          resolve: {users: findUsers}
        })
        .up();

    // Products Segments
    $routeSegmentProvider
        .segment('products', {
          templateUrl: 'products/products-menu.html',
          controller: 'ProductsMenuCtrl',
          controllerAs: 'theProductsMenu',
          resolve: {data: loadProfile}
        })
        .within()
        .segment('list', {
          'default': true,
          templateUrl: 'products/product-list.html',
          controller: 'ProductListCtrl',
          controllerAs: 'ctrl',
          dependencies: ['keyword', 'publishType'],
          resolve: {products: findProducts, workflowProducts: findWorkflowProducts}
        })
        .segment('view', {
          templateUrl: 'products/product/product-view.html',
          controller: 'ProductViewCtrl',
          controllerAs: 'ctrl',
          dependencies: ['productCodeOrWorkflowId', 'publishType', 'preview'],
          resolve: {product: findSingleProduct, nps: findProductNps}
        })
        .segment('managePromotions', {
          templateUrl: 'products/promotion/manage-promotion.html',
          controller: 'ManagePromotionCtrl',
          controllerAs: 'ctrl',
          resolve: {products: findProducts}
        })
        .segment('add', {
          templateUrl: 'products/product/product-view.html',
          controller: 'ProductAddCtrl',
          controllerAs: 'ctrl'
        })
      // Availability segments
      .segment('selectAvailability', {
        templateUrl: 'availability/availability-list.html',
        controller: 'availabilityListCtrl',
        controllerAs: 'ctrl',
        resolve: {products: findNonDupeProducts},
      })
      .segment('availability', {
        templateUrl: 'availability/availability.html',
        controller: 'AvailabilityCtrl',
        controllerAs: 'ctrl',
        resolve: {availability: loadAllAvailability},
        controller: function($scope, availability) {
          $scope.status = availability.status;
          $scope.data = availability.model;
          $scope.month = availability.month;
        },
      })
      .within()
      .segment('manager', {
        template: '<av-manager ng-model="data" av-status="status" av-month="month"/>',
        dependencies: ['productcode', 'view', 'yyyymm']
      })
      .segment('editor', {
        template: '<av-editor ng-model="data" av-status="status" av-month="month"/>'
      })
      .within()
      .segment('stimeslot', {
        template: '<av-sp-timeslot save-callback="saveDayToModel(day, dateString, view, null, null, onFail)" />',
        dependencies: ['date', 'selected', 'productcode', 'id']
      })
      .segment('mtimeslot', {
        template: '<av-mp-timeslot save-callback="saveDayToModel(day, dateString, view, null, null, onFail, statusData)" multiple="multiple" status-data="statusData"/>',
        dependencies: ['date', 'productcode']
      })
      .segment('editdatebulk', {
        template: '<av-edit-datebulk save-callback="saveDayToModel(day, dateString, view, null, null, onFail, statusData)" ng-model="data" multiple="multiple" status-data="statusData"/>',
        dependencies: ['productcode', 'filter'],
        resolve: {data: initEditBulkDates},
        controller: function($scope, data){ $scope.data = data; }
      })
      .segment('adddatebulk', {
        template: '<av-add-datebulk save-callback="addBulkDates(times, filter, onFail, statusData)" ng-model="addBulkDateModel" status-data="statusData" />',
        dependencies: ['productcode']
      })
      .segment('addblockoutdate', {
        template: '<av-add-blockout save-callback="addBlockDates(data, onFail)"  multiple="multiple"  status-data="statusData" />',
        dependencies: ['productcode']
      })
      .segment('editblockoutdate', {
        template: '<av-edit-blockout save-callback="editBlockDates(data, onFail)"  ng-model="blockoutData" multiple="multiple"  status-data="statusData" />',
        dependencies: ['productcode'],
        resolve: {blockoutData: initEditBlockoutDates},
        controller: function($scope, blockoutData){ $scope.blockoutData = blockoutData; }
      })
      .segment('blockoutContent', {
        template: '<av-blockout-content save-callback="addBlockOutDates(blockoutRange, filter, onFail, statusData)" ng-model="addBlockingDateModel"  status-data="statusData"/>',
        dependencies: ['productcode'],
      })
      .up();

    // Bookings Segments
    $routeSegmentProvider
        .segment('bookings', {
          templateUrl: 'bookings/bookings-menu.html',
          controller: 'BookingsMenuCtrl',
          resolve: {data: loadProfile}
        })
        .within()
        .segment('view', {
          templateUrl: 'bookings/booking-view.html',
          controller: 'BookingViewCtrl',
          controllerAs: 'ctrl',
          dependencies: ['id', 'status'],
          resolve: {booking: findBooking, questions: findBookingQuestions, notes: findBookingNotes}
        })
        .segment('search', {
          templateUrl: 'bookings/bookings-search.html',
          controller: 'BookingSearchCtrl',
          controllerAs: 'ctrl',
          dependencies: ['voucherNumber', 'name', 'page', 'sortBy', 'order'],
          resolve: {bookings: searchBookings, totalCount: searchBookingsCount}
        })
        .segment('list', {
          templateUrl: 'bookings/bookings-list-container.html',
          controller: 'BookingsListContainerCtrl',
          controllerAs: 'ctrl',
          dependencies: ['productCode', 'facilityId'],
          resolve: {
            counts: findBookingCounts,
            productsSummary: findSupplierProductsSummary,
            branches: findSupplierBranches
          }
        })
        .within()
        .segment('new', {
          templateUrl: 'bookings/bookings-list-content.html',
          controller: 'BookingsListContentCtrl',
          controllerAs: 'ctrl',
          dependencies: ['page', 'productCode', 'facilityId', 'sortBy', 'order', 'startDate', 'endDate'],
          resolve: {
            bookings: findAllNew, status: function () {
              return "new";
            }
          }
        })
        .segment('confirmed', {
          templateUrl: 'bookings/bookings-list-content.html',
          controller: 'BookingsListContentCtrl',
          controllerAs: 'ctrl',
          dependencies: ['page', 'productCode', 'facilityId', 'sortBy', 'order', 'startDate', 'endDate'],
          resolve: {
            bookings: findAllConfirmed, status: function () {
              return "confirmed";
            }
          }
        })
        .segment('completed', {
          templateUrl: 'bookings/bookings-list-content.html',
          controller: 'BookingsListContentCtrl',
          controllerAs: 'ctrl',
          dependencies: ['page', 'productCode', 'facilityId', 'sortBy', 'order', 'startDate', 'endDate'],
          resolve: {
            bookings: findAllCompleted, status: function () {
              return "completed";
            }
          }
        })
        .up();

    // Orders Segments
    $routeSegmentProvider
        .segment('orders', {
          templateUrl: 'orders/orders-menu.html',
          controller: 'OrdersMenuCtrl',
          controllerAs: 'theOrdersMenu',
          resolve: { data: loadDirectShipProfile }
      })
      .within()
      .segment('view', {
        templateUrl: 'orders/order-view.html',
        controller: 'OrderViewCtrl',
        controllerAs: 'ctrl',
        dependencies: ['id', 'status'],
        resolve: { booking: findBooking, questions: findBookingQuestions, notes: findBookingNotes }
      })
      .segment('search', {
        templateUrl: 'orders/orders-search.html',
        controller: 'OrderSearchCtrl',
        controllerAs: 'ctrl',
        dependencies: ['orderNumber', 'name', 'page', 'sortBy', 'order'],
        resolve: { bookings: searchDirectShips, totalCount: searchDirectShipsCount }
      })
      .segment('list', {        
        templateUrl: 'orders/orders-list-container.html',
        controller: 'OrdersListContainerCtrl',
        controllerAs: 'ctrl',
        dependencies: ['productCode', 'facilityId'],
        resolve: {
          counts: findDirectShipCounts,
          productsSummary: findDirectShipSupplierProductsSummary,
          branches: findSupplierBranches
        }
      })
      .within()
      .segment('new', {
        templateUrl: 'orders/orders-list-content.html',
        controller: 'OrdersListContentCtrl',
        controllerAs: 'ctrl',
        dependencies: ['page', 'productCode', 'facilityId', 'sortBy', 'order', 'startDate', 'endDate'],
        resolve: {
          bookings: findAllDirectShipNew, status: function () {
            return "new";
          }
        }
      })
      .segment('confirmed', {
        templateUrl: 'orders/orders-list-content.html',
        controller: 'OrdersListContentCtrl',
        controllerAs: 'ctrl',
        dependencies: ['page', 'productCode', 'facilityId', 'sortBy', 'order', 'startDate', 'endDate'],
        resolve: {
          bookings: findAllDirectShipConfirmed, status: function () {
            return "confirmed";
          }
        }
      })
      .segment('completed', {
        templateUrl: 'orders/orders-list-content.html',
        controller: 'OrdersListContentCtrl',
        controllerAs: 'ctrl',
        dependencies: ['page', 'productCode', 'facilityId', 'sortBy', 'order', 'startDate', 'endDate'],
        resolve: {
          bookings: findAllDirectShipCompleted, status: function () {
            return "completed";
          }
        }
      })
      .up();

    // Financials Segments
    $routeSegmentProvider
        .segment('financials', {
          templateUrl: 'financials/financials-menu.html',
          controller: 'FinancialsMenuCtrl',
          controllerAs: 'ctrl',
          resolve: {data: loadProfile}
        })
        .within()
        .segment('remittance', {
          templateUrl: 'financials/remittance.html',
          controller: 'FinancialsRemittanceCtrl',
          controllerAs: 'ctrl',
          resolve: {remittance: findRemittance, remittanceYears: findFinancialYears}
        })
        .segment('invoice', {
          templateUrl: 'financials/invoice.html',
          controller: 'FinancialsInvoiceCtrl',
          controllerAs: 'ctrl',
          dependencies: ['orderItemId']
        })
        .segment('payments', {
          templateUrl: 'financials/payments.html',
          controller: 'FinancialsPaymentsCtrl',
          controllerAs: 'ctrl',
          resolve: {invoices: findInvoices, searchDefaultDate: currentDate}
        })
        .segment('banking', {
          templateUrl: 'financials/banking.html',
          controller: 'FinanceBankingCtrl',
          controllerAs: 'ctrl',
          resolve: {supplier: findSupplier, bankingHistory: findBankingHistory}
        })
        .up();

    // Reviews Segments
    $routeSegmentProvider
        .segment('reviews', {
          templateUrl: 'reviews/reviews.html',
          resolve: {data: loadProfile}
        }).within()
        // note: 'parent' is a dummy segment to handle the following dependency chain:
        // (profile <- financial years) <- (reviews, nps summary, nps trend)
        .segment('parent', {
          resolve: {financialYears: findAndStoreFinancialYears}
        }).within()
        .segment('summary', {
          templateUrl: 'reviews/reviews-summary.html',
          controller: 'ReviewsSummaryCtrl',
          controllerAs: 'ctrl',
          resolve: {reviewsByProduct: findReviewsByProduct, npsSummary: findNpsSummary, npsTrend: findNpsTrend},
          'default': true
        })
        .segment('product', {
          templateUrl: 'reviews/reviews-for-product.html',
          controller: 'ReviewsForProductCtrl',
          controllerAs: 'ctrl',
          dependencies: ['productCode'],
          resolve: {reviewsForSingleProduct: findReviewsForSingleProduct}
        });

    // Login Segments
    $routeSegmentProvider
        .segment('login', {
          templateUrl: 'login/login.html',
          controller: 'LoginSupplierCtrl',
          controllerAs: 'login'
        }).within()
        .segment('login', {
          templateUrl: 'login/login-login.html',
          'default': true
        })
        .segment('changePassword', {
          templateUrl: 'login/change-password.html',
          controller: 'ChangePasswordCtrl',
          controllerAs: 'ctrl',
          dependencies: ['token', 'returnState']
        })
        .segment('forgotPassword', {
          templateUrl: 'login/login-forgot.html',
          controller: 'ResetPasswordCtrl',
          controllerAs: 'ctrl',
          dependencies: ['email']
        })
        .up();

    // Signup Segments
    $routeSegmentProvider
        .segment('signup', {
          templateUrl: 'signup/signup.html',
          controller: 'SignupCtrl',
          controllerAs: 'ctrl'
        })
        .within()
        .segment('company', {
          'default': true,
          templateUrl: 'signup/signup-form-company.html',
        })
        .segment('insurance', {
          templateUrl: 'signup/signup-form-insurance.html',
        })
        .segment('contacts', {
          templateUrl: 'signup/signup-form-contacts.html',
        })
        .segment('qa', {
          templateUrl: 'signup/signup-form-qa.html',
        })
        .segment('complete', {
          templateUrl: 'signup/signup-complete.html'
        })
        .segment('failure', {
          templateUrl: 'signup/signup-failure.html'
        });

    $routeProvider.otherwise({redirectTo: '/'});

    $httpProvider.interceptors.push(function ($q, $injector, $location) {
      return {
        responseError: function (rejection) {
          if (rejection.status === 401) {
            if ($location.path() === '/') {
              return true;
            } else if ($location.path() !== '/login') {
              var redirect = $location.$$url;
              $location.path('/login').search({redirect: redirect});
            }
          }
          return $q.reject(rejection);
        }
      };
    });

    setResolveDefaults($routeSegmentProvider.segments);

  }
]);

// Set default values for `resolveFailed` and `untilResolved` for the routes which don't have one.
var setResolveDefaults = function (segments) {
  if (_.isEmpty(segments)) return;
  _.each(segments, function (s) {
    if (!_.isEmpty(s.params.resolve)) {
      if (_.isEmpty(s.params.resolveFailed)) {
        // has `resolve` but not `resolveFailed`, set default
        s.params.resolveFailed = {templateUrl: 'common/error.html'}
      }
      if (_.isEmpty(s.params.untilResolved)) {
        // has `resolve` but not `untilResolved`, set default
        s.params.untilResolved = {templateUrl: 'common/wait.html'}
      }
    }
    setResolveDefaults(s.children);
  });
};

var loadProfile = function (SupplierSelection) {
  return SupplierSelection.loadInitialData();
};

var loadDirectShipProfile = function (SupplierSelection) {
  return SupplierSelection.loadDirectShipInitialData();
};

var getUserProfile = function (SupplierSelection) {
  return SupplierSelection.getUserProfile();
};

var findSupplier = function ($rootScope, Supplier) {
  return Supplier.single($rootScope.selectedSupplier.id).get().$promise;
};

var findSupplierInsuranceFiles = function ($rootScope, Supplier) {
  return Supplier.insuranceFiles($rootScope.selectedSupplier.id).query().$promise;
};

var findSupplierContracts = function ($rootScope, SupplierContract) {
  return SupplierContract.findAll($rootScope.selectedSupplier.id);
};

var findSupplierProductsSummary = function ($rootScope, $q, Supplier, Product) {
  var validPublishTypes = ['Published', 'Honouring'];

  // filter out product summary which is unpublished (only published and honouring)
  return $q.all([
    Supplier.productsSummary($rootScope.selectedSupplier.id).query().$promise,
    Product.allForSupplier($rootScope.selectedSupplier.id).query().$promise
  ]).then(function(values) {
    var productsSummary = values[0];
    var products = values[1];

    return _.filter(productsSummary, function(ps) {
      return _.find(products, function(p) {
        return (p.familyCode === ps.code) && (validPublishTypes.indexOf(p.status.publishType) !== -1);
      });
    });
  });
};

var findDirectShipSupplierProductsSummary = function ($rootScope, $q, Supplier, Product) {
  var validPublishTypes = ['Published', 'Honouring'];

  // filter out product summary which is unpublished (only published and honouring) ["0"].status.isDirectShip
  return $q.all([
    Supplier.productsSummary($rootScope.selectedSupplier.id).query().$promise,
    Product.allForSupplier($rootScope.selectedSupplier.id).query().$promise
  ]).then(function (values) {
    var productsSummary = values[0];
    var products = values[1];
    
    var productsFiltered = _.filter(products, function (p) {
      var matchedProductCode = _.filter(productsSummary, function (ps) {
        return p.familyCode === ps.code
      });
      if (matchedProductCode) {
        if ((validPublishTypes.indexOf(p.status.publishType) !== -1) && (p.status.isDirectShip === true)) {
          p.code = p.familyCode;
          return p;
        }
      }     
    });    

    return productsFiltered;   
  });
};

var findAllDirectShipNew = function ($rootScope, $routeParams, Directship) {
  if ($rootScope.selectedSupplier) {
    return Directship.findAllDirectShip($rootScope.selectedSupplier.id, 'new', $routeParams.productCode, $routeParams.facilityId, $routeParams.sortBy, $routeParams.order, $routeParams.page, $routeParams.startDate, $routeParams.endDate).query().$promise;
  }
};

var findAllDirectShipConfirmed = function ($rootScope, $routeParams, Directship) {
  if ($rootScope.selectedSupplier) {
    return Directship.findAllDirectShip($rootScope.selectedSupplier.id, 'confirmed', $routeParams.productCode, $routeParams.facilityId, $routeParams.sortBy, $routeParams.order, $routeParams.page, $routeParams.startDate, $routeParams.endDate).query().$promise;
  }
};

var findAllDirectShipCompleted = function ($rootScope, $routeParams, Directship) {
  if ($rootScope.selectedSupplier) {
    return Directship.findAllDirectShip($rootScope.selectedSupplier.id, 'completed', $routeParams.productCode, $routeParams.facilityId, $routeParams.sortBy, $routeParams.order, $routeParams.page, $routeParams.startDate, $routeParams.endDate).query().$promise;
  }
};

var findDirectShipCounts = function ($rootScope, $routeParams, Directship) {
  if ($rootScope.selectedSupplier) {
    return Directship.findDirectShipCounts($rootScope.selectedSupplier.id, $routeParams.productCode, $routeParams.facilityId).query().$promise;
  }
};

var searchDirectShips = function ($rootScope, $routeParams, Directship) {
  if ($rootScope.selectedSupplier) {
    return Directship.search($rootScope.selectedSupplier.id, $routeParams.orderNumber, $routeParams.name, $routeParams.sortBy, $routeParams.order, $routeParams.page).query().$promise;
  }
};

var searchDirectShipsCount = function ($rootScope, $routeParams, Directship) {
  if ($rootScope.selectedSupplier) {
    return Directship.searchCount($rootScope.selectedSupplier.id, $routeParams.orderNumber, $routeParams.name).query().$promise;
  }
};

var findSupplierBranches = function ($rootScope, Supplier) {
  return Supplier.branches($rootScope.selectedSupplier.id).query().$promise;
};

var findSupplierContract = function ($rootScope, $routeParams, SupplierContract) {
  var supplierId = $rootScope.selectedSupplier.id;
  var contractId = $routeParams.id;
  return SupplierContract.find(supplierId, contractId).get().$promise;
};

var findUsers = function ($rootScope, User) {
  return User.customerWithContactType($rootScope.selectedSupplier.id).query().$promise;
};

var findPaymentsPrimary = function ($rootScope, Supplier) {
  var yearRange = getFinancialYearRange();
  return Supplier.paymentsTrend($rootScope.selectedSupplier.id, yearRange[0] + "-07-01", yearRange[1] + "-06-30").query().$promise;
};

var findPaymentsSecondary = function ($rootScope, Supplier) {
  var yearRange = getFinancialYearRange();
  return Supplier.paymentsTrend($rootScope.selectedSupplier.id, (yearRange[0] - 1) + "-07-01", (yearRange[1] - 1) + "-06-30").query().$promise;
};

function getFinancialYearRange() {
  var now = new Date();
  // If July or later
  var startYear = now.getMonth() > 6 ? now.getFullYear() : now.getFullYear() - 1
  var endYear = startYear + 1;

  return [startYear, endYear];
}

var findRemittance = function ($rootScope, Supplier) {
  if ($rootScope.selectedSupplier) {
    return Supplier.remittance($rootScope.selectedSupplier.id).get().$promise;
  }
};

var findFinancialYears = function($rootScope, Supplier) {
  if ($rootScope.selectedSupplier) {
    return Supplier.remittanceYears($rootScope.selectedSupplier.id).get().$promise;
  }
};

var findAndStoreFinancialYears = function($rootScope, Supplier) {
  // attach response to $rootScope for use by Reviews resolves
  if ($rootScope.selectedSupplier) {
    return Supplier.remittanceYears($rootScope.selectedSupplier.id).get().$promise.then(function(res) {
      $rootScope.financialYears = res;
    });
  }
};

var findBankingHistory = function ($rootScope, Supplier) {
  if ($rootScope.selectedSupplier) {
    return Supplier.bankingChangeHistory($rootScope.selectedSupplier.id).get().$promise;
  }
};

var findInvoices = function ($rootScope, Invoice) {
  var from = moment(currentDate()).startOf('month').toDate();
  var to = moment(currentDate()).endOf('month').toDate();
  return Invoice.search($rootScope.selectedSupplier.id, {from: from, to: to}).get().$promise;
};

var currentDate = function () {
  return new Date();
};

var findProducts = function ($rootScope, Product) {
  return Product.allForSupplier($rootScope.selectedSupplier.id).query().$promise;
};

var findNonDupeProducts = function ($rootScope, Product) {
  return Product.allForSupplier($rootScope.selectedSupplier.id, true).query().$promise;
};

var findSingleProduct = function ($rootScope, $routeParams, Product) {
  // fetch live product or draft (workflow) product based on publishType
  if ($routeParams.publishType && ($routeParams.publishType.toLowerCase() === 'draft' || $routeParams.publishType.toLowerCase() === 'preview')) {
    return Product.supplierWorkflowProducts({
      supplierId: $rootScope.selectedSupplier.id,
      workflowId: $routeParams.productCodeOrWorkflowId
    })
        .fetch().$promise;
  } else {
    return Product.findByFamilyCode($routeParams.productCodeOrWorkflowId).query().$promise;
  }
};

var findProductQuestions = function ($routeParams, Product) {
  return Product.questionsByProductFamily($routeParams.family).query().$promise;
};

var findWorkflowProducts = function ($rootScope, Product) {
  if ($rootScope.selectedSupplier) {
    return Product.supplierWorkflowProducts({supplierId: $rootScope.selectedSupplier.id}).fetchAll().$promise;
  }
};

var findAllNew = function ($rootScope, $routeParams, Booking) {
  if ($rootScope.selectedSupplier) {
    return Booking.findAll($rootScope.selectedSupplier.id, 'new', $routeParams.productCode, $routeParams.facilityId, $routeParams.sortBy, $routeParams.order, $routeParams.page, $routeParams.startDate, $routeParams.endDate).query().$promise;
  }
};

var searchBookings = function ($rootScope, $routeParams, Booking) {
  if ($rootScope.selectedSupplier) {
    return Booking.search($rootScope.selectedSupplier.id, $routeParams.voucherNumber, $routeParams.name, $routeParams.sortBy, $routeParams.order, $routeParams.page).query().$promise;
  }
};

var searchBookingsCount = function ($rootScope, $routeParams, Booking) {
  if ($rootScope.selectedSupplier) {
    return Booking.searchCount($rootScope.selectedSupplier.id, $routeParams.voucherNumber, $routeParams.name).query().$promise;
  }
};

var findAllConfirmed = function ($rootScope, $routeParams, Booking) {
  if ($rootScope.selectedSupplier) {
    return Booking.findAll($rootScope.selectedSupplier.id, 'confirmed', $routeParams.productCode, $routeParams.facilityId, $routeParams.sortBy, $routeParams.order, $routeParams.page, $routeParams.startDate, $routeParams.endDate).query().$promise;
  }
};

var findAllCompleted = function ($rootScope, $routeParams, Booking) {
  if ($rootScope.selectedSupplier) {
    return Booking.findAll($rootScope.selectedSupplier.id, 'completed', $routeParams.productCode, $routeParams.facilityId, $routeParams.sortBy, $routeParams.order, $routeParams.page, $routeParams.startDate, $routeParams.endDate).query().$promise;
  }
};

var findBookingCounts = function ($rootScope, $routeParams, Booking) {
  if ($rootScope.selectedSupplier) {
    return Booking.findAllCounts($rootScope.selectedSupplier.id, $routeParams.productCode, $routeParams.facilityId).query().$promise;
  }
};

var findBookingQuestions = function ($rootScope, $routeParams, Booking) {
  if ($rootScope.selectedSupplier) {
    return Booking.findQuestions($rootScope.selectedSupplier.id, $routeParams.id).query().$promise;
  }
};

var findBookingNotes = function ($rootScope, $routeParams, Booking) {
  if ($rootScope.selectedSupplier) {
    return Booking.findNotes($rootScope.selectedSupplier.id, $routeParams.id).query().$promise;
  }
};

var findBooking = function ($rootScope, $routeParams, Booking) {
  if ($rootScope.selectedSupplier) {
    return Booking.find($rootScope.selectedSupplier.id, $routeParams.id).query().$promise;
  }
};

var findProductNps = function ($routeParams, Product) {
  // FIXME - TIBO review this
  return 0;
  //return Product.findNps($routeParams.code).query().$promise;
};

var findProductsOnSpecial = function ($routeParams, Product) {
  return Product.findProductsOnSpecial($routeParams.family).query().$promise;
};

// Customer Reviews
var getLatestYear = function($rootScope) {
  return !_.isEmpty($rootScope.financialYears) ? $rootScope.financialYears[0] : null;
};

var getLatestYearsPair = function($rootScope) {
  return !_.isEmpty($rootScope.financialYears) ? _.first($rootScope.financialYears, 2) : [];
};

var findReviewsByProduct = function ($rootScope, $routeParams, Review) {
  //return Review.reviewsByProduct($rootScope.selectedSupplier.id, $routeParams.status, $routeParams.sortBy, $routeParams.order, $routeParams.page).query().$promise;
  if ($rootScope.selectedSupplier) {
    return Review.reviewsByProduct($rootScope.selectedSupplier.id, getLatestYear($rootScope));
  }
};

var findReviewsForSingleProduct = function ($rootScope, $routeParams, Review) {
  //return Review.reviewsForSingleProduct($routeParams.id).query().$promise;
  if ($rootScope.selectedSupplier) {
    return Review.reviewsForSingleProduct($rootScope.selectedSupplier.id, $routeParams.productCode, getLatestYear($rootScope));
  }
};

var findNpsTrend = function ($rootScope, Review) {
  if ($rootScope.selectedSupplier) {
    return Review.npsTrend($rootScope.selectedSupplier.id, getLatestYearsPair($rootScope));
  }
};

var findNpsSummary = function ($rootScope, Review) {
  if ($rootScope.selectedSupplier) {
    return Review.npsSummary($rootScope.selectedSupplier.id);
  }
};

// Availability
var loadAllAvailability = function($routeParams, Availability) {
  return Availability.loadAll($routeParams.productcode, $routeParams.yyyymm || undefined);
}
var initEditBulkDates = function ($routeParams, Availability) {
  return Availability.getBulkDates();
}
var initEditBlockoutDates = function ($routeParams, Availability) {
  return Availability.getBlockoutDates();
}
var loadProductStatus = function ($routeParams, Availability) {
  return Availability.loadProductStatus($routeParams.productcode);
}

jQuery(document).ready(function () {
  jQuery(".required-group").append("<span class='header-required'><span class='asterix'>*</span> Required</span>");

  jQuery(document).on('focus blur', 'input', function () {
    jQuery(this).closest(".form-group:not(.ignore)").toggleClass('active-section');
  });
  jQuery(document).on('focus blur', 'select', function () {
    jQuery(this).closest(".form-group:not(.ignore)").toggleClass('active-section');
  });
  jQuery(document).on('focus blur', 'textarea', function () {
    jQuery(this).closest(".form-group:not(.ignore)").toggleClass('active-section');
  });

  jQuery(document).on('focus blur', '.grouped-control select', function () {
    jQuery(this).closest('.grouped-control').toggleClass('active-group');
  });
  jQuery(document).on('focus blur', '.grouped-control input', function () {
    jQuery(this).closest('.grouped-control').toggleClass('active-group');
  });
  jQuery(document).on('focus blur', '.grouped-control textarea', function () {
    jQuery(this).closest('.grouped-control').toggleClass('active-group');
  });
});

app.run(['$location', '$rootScope', '$route', 'SegmentTrackingService', function ($location, $rootScope, $route, SegmentTrackingService) {

  $rootScope.$on('$routeChangeSuccess', function (event, current, previous) {
    var PATH = $location.path();
    var URL = $location.absUrl();
    var PAGE_TITLE = '';
    var REFERRER = '';

    if (current && current.$$route) {
      var currentPath = current.$$route.originalPath;
      var cleanCurrentPath = currentPath.split('/').join(' ').trim();
      PAGE_TITLE = SegmentTrackingService.titleCase(cleanCurrentPath) + ' - Supplier Hub | RedBalloon';
    }

    if (previous && previous.$$route && previous.$$route.originalPath) {
      REFERRER = $location.protocol() + '://' + $location.host() + previous.$$route.originalPath;
    }

    SegmentTrackingService.allPages({
      referrer: REFERRER,
      title: PAGE_TITLE,
      path: PATH,
      url: URL
     });
  });
 }]);

// object length filter
// Usage: <div ng-hide="(products | objLength) == 1"> show products</div>
app.filter('objLength', function () {
  return function (object) {
    var count = 0;
    for (var i in object) {
      count++;
    }
    return count;
  }
});

app.controller('AppCtrl', ['$scope', 'PageTitle', function($scope, PageTitle) {
  $scope.PageTitle = PageTitle;
}]);

app.factory('PageTitle', function () {
  var title = 'Home - Supplier Hub | RedBalloon';

  return {
    get: function () {
      return title;
    },

    set: function (nextTitle) {
      title = nextTitle
    }
  };
});

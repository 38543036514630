app.controller('MapsCtrl', ['$scope', 'productData', 'autoSave', '$timeout', function($scope, productData, autoSave, $timeout) {

  var locationMarker = _.find(productData.maps.locations, function (marker) { return marker.markerType === 'location' })
  var meetingPointMarker = _.find(productData.maps.locations, function (marker) { return marker.markerType === 'meetingPoint' })

  $scope.markers = {
    location: _.assign({}, locationMarker, {
      name: 'Location',
      icon: '/img/marker_blue_map.png'
    }),
    meetingPoint: _.assign({}, meetingPointMarker, {
      name: 'Meeting Point',
      icon: '/img/marker_green_map.png',
    })
  };

  $scope.save = function () {
    productData.maps.locations = $scope.markers;
    productData.updateWorkflow();
  };

  // Set up autosave
  $scope.initForm = function () {
    autoSave.init($scope, ['markers']);
  };

  // Init the form for autosave
  $timeout(function() {
    $scope.initForm();
  }, 1000, false);

}]);

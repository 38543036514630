angular.module('app').controller('ChangePasswordCtrl', function (Auth, $routeParams, $location) {
  var self = this;

  var token = $routeParams.token;

  Auth.tokenValid(token).
    success(function () {
      self.isTokenValid = true;
    }).
    error(function () {
      self.isTokenValid = false;
    });

  self.confirmPasswordMatches = function (value) {
    return value == self.password;
  };

  self.doChangePassword = function () {
    Auth.changePassword({token: $routeParams.token, newPassword: self.password}).success(function () {
      self.passwordChangeSuccessfull = true;
      $location.path('/login');
    }).error(function () {
      self.passwordChangeSuccessfull = false;
    });
  };
});

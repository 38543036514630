app.controller('MainNavCtrl', ['$rootScope', '$scope', 'SupplierContract', function($rootScope, $scope, SupplierContract) {
  // Added factory on scope to check for
  // checkAgencyAgreement function in factory
  $scope.SupplierContract = SupplierContract;

  $scope.isInNav = false;

  $scope.isIn = {
    products: false,
    bookings: false,
    orders: false,
    company: false,
    financials: false
  };

  $scope.toggleIn = function(item) {
    var value = $scope.isIn[item];
    for (var prop in $scope.isIn) {
      $scope.isIn[prop] = false;
    }
    $scope.isIn[item] = !value;
  };

  $scope.collapseNav = function() {
    $scope.isInNav = false;
    for (var prop in $scope.isIn) {
      $scope.isIn[prop] = false;
    }
  };

  $scope.blockMenu = function() {
    //return $rootScope.selectedSupplier && ($rootScope.insuranceExpired || !$rootScope.selectedSupplier.insurance.onFile);
    return false;
  }

}]);

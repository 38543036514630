app.controller('LoginSupplierCtrl', ['$location', '$routeParams', 'Auth', '$rootScope', function($location, $routeParams, Auth, $rootScope) {

  var self = this;

  self.noSupplierCompanyAccount = $rootScope.noSupplierCompanyAccount;
  self.incorrectLogin = false;
  self.loading = false;

  self.login = function() {
    if (self.loginForm.$invalid) {
      self.incorrectLogin = true;
    } else {
      setLoading(
        Auth.login({
            email: self.email,
            password: self.password
          }
        ).success(function() {
            // Reset flag that shows No Company Account error when true
            $rootScope.noSupplierCompanyAccount = false;
            // Setting a rootScope flag to detect when user is Acutally logging in
            $rootScope.trackUserLogin = true;
            if (angular.isDefined($routeParams.redirect)) {
              $location.url($routeParams.redirect);
            } else {
              $location.path('/home');
            }
          }).error(function() {
            self.incorrectLogin = true;
            $location.path('/login');
          })
      );
    }
  };

  function setLoading(promise) {
    self.loading = true;
    // on success don't unset the loading.
    // We will be navigating away which does the delayed page change.
    promise.then(undefined, function() {
      self.loading = false;
    });
  }

}]);

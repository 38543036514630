app.controller('ResetPasswordCtrl', ['$location', 'Auth', '$routeParams', function($location, Auth, $routeParams) {
  var self = this;

  var email = $routeParams.email;
  if (email && email !== 'undefined') {
    self.email = email;
  }

  self.incorrectLogin = false;
  self.loading = false;
  self.noSuchUser = false;

  self.doResetPassword = function() {

    Auth.resetPassword({email: this.email}).success(function(response, status) {
      if (status === 200) {
        self.resetPasswordSuccess = true;
        self.noSuchUser = false;
      } else {
        self.resetPasswordSuccess = false;
        self.noSuchUser = true;
      }
    }).error(function(e) {
      self.resetPasswordSuccess = false;
      self.noSuchUser = true;
    });
  };


}]);

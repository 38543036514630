app.controller('ProductAddCtrl', ['$rootScope', '$scope', '$timeout', 'productData', 'moment', '$sce', function($rootScope, $scope, $timeout, productData, moment, $sce) {
	var self = this;

  self.pageTitle = 'Add a new Product';
	productData.initFreshModel();

	var lastFocusedPanelIndex = 0;

	function handleScrollDebounced () {
		_.throttle(handleScroll(), 200);
	}

	function handleScroll () {
		var focusedPanelIndex = getFocusedPanelIndex();

		if (focusedPanelIndex !== lastFocusedPanelIndex) {
			lastFocusedPanelIndex = focusedPanelIndex;

			self.sections = self.sections.map(function (section, index) {
				section.viewing = index === focusedPanelIndex;

				return section;
			});

			$scope.$apply();
		}
	}

	function getFocusedPanelIndex () {
		var bodyScrollTop = $('body').scrollTop();
		var panelIndex = 0;
		var $panels = $('.panel--product');

		if ($panels.length) $panels.toArray().reverse().forEach(function (panel, index) {
			if (bodyScrollTop <= $(panel).offset().top + $(panel).height()) {
				panelIndex = ($panels.length - 1) - index;
			}
		});

		return panelIndex;
	}

	function initScrollSpy() {
	  $(window).on('scroll.productScrollSpy', handleScrollDebounced);
	}

	$scope.$on('$destroy', function () {
		$(window).off('scroll.productScrollSpy');
	});

	initScrollSpy();


  self.getLastUpdated = function() {
    var lastUpdated = Math.floor(productData.shared.lastUpdated / 1000);
    return lastUpdated ? moment.unix(lastUpdated).format("DD-MM-YYYY, hh:mm a") : '-';
  };

  self.getSubmitted = function() {
    return productData.shared.submitted
  };

	self.sections = [{
    'name': 'productInfo',
    'fullName': 'Product Info',
    'path': 'products/product/product-summary.html',
    'ctrl': 'ProductSummaryCtrl',
    'id': 0,
    'viewing': true,
    'editing': true
  }, {
    'name': 'prices',
    'fullName': 'Prices',
    'path': 'products/product/product-prices.html',
    'ctrl': 'PricesCtrl',
    'id': 1,
    'viewing': false,
    'editing': true
  }, {
    'name': 'needToKnow',
    'fullName': 'Need To Know',
    'path': 'products/product/product-needtoknow.html',
    'ctrl': 'ToKnowCtrl',
    'id': 2,
    'viewing': false,
    'editing': true
  }, {
    'name': 'moreInfo',
    'fullName': 'More Info',
    'path': 'products/product/product-moreinfo.html',
    'ctrl': 'MoreInfoCtrl',
    'id': 3,
    'viewing': false,
    'editing': true
  }, {
    'name': 'maps',
    'fullName': 'Maps',
    'panelTooltip': 'This should show the exact location of the experience at street level.',
    'path': 'products/product/product-maps.html',
    'ctrl': 'MapsCtrl',
    'id': 4,
    'viewing': false,
    'editing': true
  }, {
    'name': 'imgVid',
    'fullName': 'Images & Video',
    'path': 'products/product/product-images.html',
    'ctrl': 'ImagesCtrl',
    'id': 5,
    'viewing': false,
    'editing': true
  }];

	self.sectionOrder = 'id';

	self.isInvalidAndSubmitAttempted = function () {
    return !productData.shared.hasMandatoryFieldsForSubmit() && productData.shared.submitAttempted;
  };

  self.networkErrorFromSubmitAttempted = function () {
    return productData.shared.networkErrorUpdatingWorkflow;
  };

  self.networkErrorStringFromSubmitAttempted = function () {
    return $sce.trustAsHtml("<p>Please Try Again!!! <br>Network Error " + productData.shared.networkErrorStringUpdatingWorkflow.status + " - " + productData.shared.networkErrorStringUpdatingWorkflow.statusText + "</p>");
  };

	self.toggleViewing = function($index) {
		angular.forEach(self.sections, function(e, i) {
			e.viewing = false;
		});
		var section = self.sections[$index];
		section.viewing = !section.viewing;
	};

	self.toggleEditable = function($index) {
		var section = self.sections[$index];
		self.toggleViewing($index);
		section.editing = !section.editing;
	};

	self.submit = function() {
		productData.submitWorkflow();
	};

  self.productPageTypeLabel = function () {
    return self.pageTitle === 'Edit Product' ? 'Product edit' : 'new product';
  };

}]);

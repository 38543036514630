app.controller('EnterPasswordModalCtrl', function($modalInstance, accountDetails, currentPasswordInvalid) {

  var self = this;
  self.accountDetails = accountDetails;
  self.currentPasswordInvalid = currentPasswordInvalid && self.accountDetails.password;

  self.dismiss = function() {
    self.accountDetails.password = null;
    $modalInstance.dismiss();
  };

  self.submit = function() {
    $modalInstance.close();
  };

});

app.controller('CompanyAccountDetailsCtrl', ['$rootScope', 'Supplier', 'supplier', function($rootScope, Supplier, supplier) {

  var self = this;

  self.supplierId = $rootScope.selectedSupplier.id;
  self.supplier = supplier;
  self.updateResult = null;
  self.states = [
    'NSW',
    'VIC',
    'QLD',
    'WA',
    'SA',
    'TAS',
    'NT',
    'ACT'
  ];

  self.updateDetails = function(form) {
    Supplier.single(self.supplierId).update(self.supplier).$promise.then(function() {
      self.handleUpdateResult(form, 'SUCCESS');
    }, function() {
      self.handleUpdateResult(form, 'FAILURE');
    });
  };

  self.handleUpdateResult = function(form, result) {
    form.$setPristine();
    self.updateResult = result;
  };

}]);

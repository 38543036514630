app.controller('NewSupplierCtrl', ["$scope", "$location", "$http", 'fileUpload', '$log', '$rootScope', 'SupplierContactTypes', function ($scope, $location, $http, fileUpload, $log, $rootScope, SupplierContactTypes) {

  var EXPIRY_DATE_INTERVAL_YEARS = 10;
  var BIRTHDAY_DATE_INTERVAL_YEARS = 100;

  // init data
  $scope.supplier = {
    isActive: true,
    paymentTerms: "-7",
    insurance: {
      onFile: false,
      maxCoverText: ""
    },
    info: {
      qaInfo: {}
    },
    address: {
      country: {}
    }
  };

  $scope.$root.uploadingFiles = 0;

  // setup contacts
  $scope.contactList = [];

  var baseContact = {
    hidden: false,
    duplicate: true,
    newsletter: false,
    contact: {
      titleRequired: false,
      titlePopup: "Only fill out if applicable",
    }
  };

  $scope.primaryContact = {
    types: []
  };

  SupplierContactTypes.then(function(data){
    _.each(data, function (value) {
      var typeData = angular.extend({}, baseContact, {
        id: value.id,
        types: [value.name],
        typeDesc: value.description,
        emailTip: "",
        display: value.name,
        nameTip: "Main contact for customer enquiries (if you have a large reservations team please enter 'Reservations')",
        contact: { titleRequired : false, titlePopup : 'Only fill out if applicable' }
      });

      switch(value.id) {
        case 1:
          typeData.emailTip = "For customer booking requests and enquiries";
          break;
        case 2:
          typeData.emailTip = "For product related enquiries";
          typeData.contact = {
            titleRequired: true,
              titlePopup: ""
          };
          $scope.primaryContact = typeData;
          break;
      }
      $scope.contactList.push(typeData);
    });
  });

  $http.get('/countries').then(function (data) {
    $scope.countries = _.filter(data.data, function(c) {
       return c.code == 'AU' || c.code == 'NZ';
    });
    // default the country to australia
    $scope.supplier.address.country = _.find($scope.countries, function (country) {
      return country.code === "AU";
    });
  }, app.alertError);

  $http.get('/states').then(function (data) {
    $scope.states = data.data;
  }, app.alertError);
  
  // logic

  $scope.togglePrimaryContactExtraType = function(type){
    if(_.contains($scope.primaryContact.types, type)){
      $scope.primaryContact.types = _.without($scope.primaryContact.types, type);
    } else {
      $scope.primaryContact.types.push(type);
    }
  };

  $scope.getInventorySystem = function(info){
    if (info.inventorySystemOption == "Other"){
      return info.inventorySystem;
    } else {
      return info.inventorySystemOption;
    }
  };

  $scope.contactsToSave = function(){
    return _.filter($scope.contactList, function(e){ return !e.hidden; });
  };

  $scope.birthdayDatePickerOptions = {
    changeYear: true,
    changeMonth: true,
    hideIfNoPrevNext: true,
    minDate: moment().startOf('day').subtract(BIRTHDAY_DATE_INTERVAL_YEARS, 'years').toDate(),
    maxDate: moment().startOf('day').toDate(),
    yearRange: "-" + BIRTHDAY_DATE_INTERVAL_YEARS + ":+0" // "-100:+0"
  };

  $scope.expiryDatePickerOptions = {
    changeYear: true,
    changeMonth: true,
    hideIfNoPrevNext: true,
    minDate: moment().startOf('day').toDate(),
    maxDate: moment().startOf('day').add(EXPIRY_DATE_INTERVAL_YEARS, 'years').toDate(),
    yearRange: "-0:+" + EXPIRY_DATE_INTERVAL_YEARS // "-0:+10"
  };

  $scope.save = function (form) {
    form.submitted = true;

    if (!form.$valid) {
      return;
    }
    $scope.saving = true;
    $scope.error = "";

    var submissionCopy = angular.copy($scope.supplier);

    submissionCopy.insurance.maxCover = $scope.normaliseNumber($scope.supplier.insurance.maxCoverText);
    submissionCopy.info.inventorySystem = $scope.getInventorySystem($scope.supplier.info);
    submissionCopy.isGSTRegistered = Boolean($scope.supplier.isGSTRegistered);

    var contactListToSave = _.map($scope.contactsToSave(), function(c){
      return {
        types: c.types,
        contact: {
          email: c.contact.email,
          username: c.contact.email,
          phone: c.contact.phone,
          jobTitle: c.contact.jobTitle,
          firstName: c.contact.firstName,
          lastName: c.contact.lastName,
          birthday: c.contact.birthday,
          address: {}
        }
      };
    });

    var submittionData = {
      supplier: submissionCopy,
      contactList: contactListToSave
    };

    $log.info('about to submit supplier', JSON.stringify(submittionData));
    
    $http({
      method: 'POST',
      url: '/submit',
      data: submittionData
    }).success(function (data) {

      function uploadFileError(){
        $rootScope.uploadingFiles -= 1;
        app.alertError();
      }
      
      //These both takes place asynchronously and take a while, allowing them to continue entering data
      if ($scope.cert){
        $rootScope.uploadingFiles += 1;
        fileUpload.uploadFileToUrl($scope.cert, "/supplier/" + data.id + "/file", function () {
          $log.info('cert file uploaded');
          $rootScope.uploadingFiles -= 1;
        }, uploadFileError);
      }
      if($scope.indemnity) {
        $rootScope.uploadingFiles += 1;
        fileUpload.uploadFileToUrl($scope.indemnity, "/supplier/" + data.id + "/file?fileType=indemnity", function () {
          $rootScope.uploadingFiles -= 1;
          $log.info('indemnity file uploaded');
        }, uploadFileError);
      }

      $location.path("/product/" + data.id);
      $("html, body").animate({ scrollTop: jQuery("top-product").scrollTop() }, "fast");
    }).error(function (data) {
      $scope.saving = false;
      $scope.error = "Error " + status + " saving the supplier info: " + data;
      window.globalError = $scope.error;

      $log.error("Error saving the supplier info: " + data);
      $location.path('/failure/0');
    });
  };

  // util method
  $scope.browserHasFilesApi = function(){
    return $("<input type='file'>")    // create test element
     .get(0)               // get native element
     .files !== undefined; // check whether files property is not undefined
  };

  $scope.evPrev = function($event){
    $event.preventDefault();
    $event.stopPropagation();
  };

  $scope.normaliseNumber = function(maxCoverText){
    return Number(maxCoverText.replace(/,/g,""));
  };
}]);

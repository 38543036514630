app.factory('productData', ['Product', 'Review', '$rootScope', function(Product, Review, $rootScope) {
  var shared = {},
    summary = {},
    prices = {},
    toKnow = {},
    moreInfo = {},
    maps = {},
    imgVid = {},
    updatesInProgress = 0,
    submitInProgress = false,
    retrievingWorkflowId = false,
    readyToSubmit = false,
    prevProductData = {},
    initialNoteCreated = false,
    ableToCreateNote = false;

  // common initialisation
  var initCommon = function () {
    shared.supplierName = $rootScope.selectedSupplier.name;
    shared.supplierId = $rootScope.selectedSupplier.id;
    shared.submitAttempted = false;
    shared.disableAutoUpdates = false;
    ableToCreateNote = false;
  };

  // initialise a fresh model - ie for the 'add product' functionality
  var initFreshModel = function() {
    initCommon();

    shared.productFamilyCode = '';
    shared.workflowId = null;
    shared.lastUpdated = null;
    shared.submitted = false;

    summary.name = '';
    summary.countryCode = '';
    summary.category = '';
    summary.supplierName = '';
    summary.productNps = {};
    summary.isExclusive = false;
    summary.exclusiveReason = '';

    prices.priceOptions = [];
    prices.questions = [];

    moreInfo.accessibility = '';
    moreInfo.cancellationPolicy = [];
    moreInfo.dressCode = '';
    moreInfo.otherInfo = '';
    moreInfo.participantChanges = '';
    moreInfo.spectators = '';
    moreInfo.numbersOnTheDay = '';
    moreInfo.rescheduling = [];
    moreInfo.weather = '';

    toKnow.howToGetThere = '';
    toKnow.marketingDescription = '';
    toKnow.printDescription = '';
    toKnow.venue = '';
    toKnow.guidelines = [];
    toKnow.highlights = [];
    toKnow.restrictions = '';
    toKnow.sessionLength = '';
    toKnow.whatsIncluded = [];
    toKnow.description = [];

    maps.locations = [];

    imgVid.images = [];
    imgVid.videoCode = '';
    copyOldData();
  };

  var mapToProductInfoColumns = function(product) {
    // TODO: toKnow and moreInfo objects will eventually be populated wholesale by parsed JSON blobs (see SH-231)
    moreInfo.accessibility = product.moreInfo.accessibility;
    moreInfo.cancellationPolicy = [].concat(product.moreInfo.cancellationPolicy);
    moreInfo.dressCode = product.moreInfo.dressCode;
    moreInfo.otherInfo = product.moreInfo.otherInfo;
    moreInfo.participantChanges = product.moreInfo.participantChanges;
    moreInfo.spectators = product.moreInfo.spectators;
    moreInfo.numbersOnTheDay = product.moreInfo.numbersOnTheDay; // changed from numbersOnTheDay to numbersOnDay
    moreInfo.rescheduling = [].concat(product.moreInfo.rescheduling);
    moreInfo.weather = product.moreInfo.weather;

    toKnow.howToGetThere = product.needToKnow.howToGetThere;
    toKnow.marketDescription = product.needToKnow.marketDescription; // changed from marketDescription to marketDesc
    toKnow.printDescription = product.needToKnow.printDescription; // changed from printDescription to printDesc
    toKnow.venue = product.needToKnow.venue;
    toKnow.description = [].concat(product.needToKnow.description);

    // TODO: Make sure all data coming in is an array so this check doesn't need to happen
    toKnow.guidelines = [].concat(product.needToKnow.guidelines); // changed from guidelines to participantGuideLine
    toKnow.highlights = [].concat(product.needToKnow.highlights);
    toKnow.restrictions = product.needToKnow.restrictions;
    toKnow.sessionLength = [].concat(product.needToKnow.sessionLength);
    toKnow.whatsIncluded = [].concat(product.needToKnow.whatsIncluded);

    imgVid.videoCode = product.info ? product.info.videoCode : (product.moreInfo ? product.moreInfo.videoCode : "");
  };

  // init model from live product
  var initFromLiveProduct = function(product,mode,workflowId,wfLastUpdated) {

    initCommon();

    shared.productFamilyCode = product.code;
    shared.workflowId = workflowId;
    shared.lastUpdated = wfLastUpdated;
    shared.submitted = false;
    ableToCreateNote = product.wfStatus ? product.wfStatus.toUpperCase() === 'AWAITING_MODERATION' : false;
    initialNoteCreated = true;

    summary.name = product.name;
    summary.countryCode = product.countryCode;
    summary.category = product.categories.primary;
    summary.supplierName = product.supplierName;
    summary.isExclusive = product.isExclusive;
    summary.exclusiveReason = product.exclusiveReason;
    // TODO: mocked until Customer Reviews API is completed
    Review.npsSummaryForProduct(shared.supplierId, shared.productFamilyCode).then(function(res) {
      summary.productNps = res;
      copyOldData();
    });

    if(mode == 'copy') {
      prices.priceOptions = [];
      shared.productFamilyCode = '';
      shared.workflowId = null;
      shared.lastUpdated = null;
      shared.submitted = false;
      summary.name = product.name;
      imgVid.images = [];
    }else{
      // get price options, filtering out those that are unpublished
      var allPriceOptions = _.find(product.variants, function(i) {
        return i.priceOptions;
      }).priceOptions;

      prices.priceOptions = _.filter(allPriceOptions, function(priceOption) {
        return priceOption.status.publishType.toLowerCase() !== 'unpublished';
      });

      // generate empty questions array for all price options
      _.each(prices.priceOptions, function(priceOption) {
        priceOption.questions = [];
      });

      // fetch questions and match to price options
      Product.questionsByProductFamily(product.code).query().$promise.then(function(res) {
        _.each(res, function(productQuestions) {
          _.each(productQuestions.details, function(question) {
            // find matching price option for question
            var priceOption = _.find(prices.priceOptions, function(priceOption) {
              return question.productId == priceOption.id;
            });
            // add question to price option
            if (priceOption) {
              priceOption.questions.push(question.question);
            }
          });
        });
        copyOldData();
      });
      Product.images(product.code, "380x380").query(function(res) {
        imgVid.images = res;
        copyOldData();
      });
    }

    mapToProductInfoColumns(product);

    maps.locations = product.locations;

    imgVid.videoCode = product.moreInfo.videoCode;
  };

  // init model from workflow product
  var initFromWorkflowProduct = function(workflowProduct,mode) {

    initCommon();

    var details = JSON.parse(workflowProduct.entityDetails);

    shared.productFamilyCode = details.productFamilyCode;
    shared.lastUpdated = workflowProduct.dateModified;
    shared.workflowId = workflowProduct.id;
    shared.submitted = workflowProduct.wfStatus.toLowerCase() !== 'start';
    ableToCreateNote = workflowProduct.wfStatus.toUpperCase() === 'AWAITING_MODERATION';
    initialNoteCreated = true;
    summary.productNps = {};

    summary.name = details.name;
    summary.countryCode = details.country;
    summary.category = details.categories;
    summary.notes = details.notes;
    summary.isExclusive = details.isExclusive;
    summary.exclusiveReason = details.exclusiveReason;
    imgVid.images = [];
    if(mode == 'copy') {
      prices.priceOptions = [];
      shared.productFamilyCode = '';
      shared.workflowId = null;
      shared.lastUpdated = null;
      shared.submitted = false;
      summary.name = details.name;
    }else{
      prices.priceOptions = details.priceOptions;
      mapToProductInfoColumns(details);
      maps.locations = details.locations;
      _.each(details.images, function(publicId) {
        Product.standaloneImages(publicId).query(function(res) {
          imgVid.images.push(res);
          copyOldData();
        });
      });
      imgVid.videoCode = details.moreInfo.videoCode;

      Product.findByFamilyCode(shared.productFamilyCode).query(function(res) {
        var productFamilyDate = new Date(res.updateDate);
        var workFlowDate = new Date(workflowProduct.dateModified);
        if(workflowProduct.wfStatus.toLowerCase() == 'start' && (workFlowDate < productFamilyDate)) {
          initFromLiveProduct(res,'edit',workflowProduct.id,workflowProduct.dateModified);
        }
      });

    }

  };

  // build workflow payload from model
  var buildWorkflowPayload = function() {
    var flatData = _.extend(angular.copy(summary), prices);
    _.extend(flatData, toKnow);
    _.extend(flatData, moreInfo);
    _.extend(flatData, maps);
    _.extend(flatData, imgVid);

    var flatCopyData = _.extend(angular.copy(prevProductData.summary), prevProductData.prices);
    _.extend(flatCopyData, prevProductData.toKnow);
    _.extend(flatCopyData, prevProductData.moreInfo);
    _.extend(flatCopyData, prevProductData.maps);
    _.extend(flatCopyData, prevProductData.imgVid);

    //For creating a note when a new product is submitted
    if (
      (!shared.productFamilyCode || !shared.productFamilyCode.length)
      && ableToCreateNote
      && !initialNoteCreated
      && entityHasChanges(flatCopyData, flatData)
    ) {
      var submitNote = createNoteForNewProductSubmit(flatData.name);
      flatData.notes = [submitNote];
      summary.notes = flatData.notes;
      copyOldData();
      initialNoteCreated = true;
      ableToCreateNote = false;
    }

    var keys = Object.keys(flatData);
    _.forEach(keys, function (fieldName) {
      var oldField = flatCopyData[fieldName];
      var newField = flatData[fieldName];

      if (ableToCreateNote && entityHasChanges(newField, oldField)) {
        var note = createNote(fieldName, oldField, newField);
        if (flatCopyData.notes) {
          flatCopyData.notes.push(note);
        } else {
          flatCopyData.notes = [note];
        }
        flatData.notes = flatCopyData.notes;
        summary.notes = flatData.notes;
        flatCopyData[fieldName] = flatData[fieldName];
        copyOldData();
      }
    });

    ableToCreateNote = initialNoteCreated ? true : ableToCreateNote;

    var payload = {
      supplierName: shared.supplierName,
      supplierId: shared.supplierId,
      name: summary.name,
      country: summary.country ? summary.country.code : summary.countryCode,
      isExclusive: summary.isExclusive || false,
      exclusiveReason: summary.exclusiveReason || "",
      priceOptions: prices.priceOptions,
      needToKnow: toKnow,
      moreInfo: _.extend(_.clone(moreInfo), {
        videoCode: imgVid.videoCode
      }),
      locations: _.map(maps.locations, function(location) { // TODO(mh): add mocked zoom for now, ask Curtis to add to shared lib
        return _.extend(location, {
          zoom: 0
        })
      }),
      images: _.map(imgVid.images, function(image) {
          return image.publicId
        }), // extract array of publicIds
      notes: flatData.notes
    };
    if (summary.category) {
      payload.categories = {};
      payload.categories = summary.category;
    }

    // presence of productFamilyCode indicates an 'add product' or an 'edit product'
    if (shared.productFamilyCode) {
      payload.productFamilyCode = shared.productFamilyCode;
    }
    // presence of workflowId indicates a new workflow is being created, or an existing workflow is being updated
    if (shared.workflowId) {
      payload.workflowId = shared.workflowId;
    }

    return payload;
  };

  var hasMandatoryFieldsForUpdate = function() {
    return (summary.name != null && summary.name !== '');
  };

  var priceOptionsHaveValidData = function() {
    var isValid = true;

    prices.priceOptions.forEach(function(option) {
      if (!option.poType) isValid = false;
    });

    return isValid;
  };

  var hasMandatoryNeedToKnow = function() {
    return (toKnow.highlights && toKnow.highlights.length) &&
      toKnow.marketDescription &&
      toKnow.printDescription &&
      (toKnow.whatsIncluded && toKnow.whatsIncluded.length) &&
      (toKnow.description && toKnow.description.length) &&
      (toKnow.guidelines && toKnow.guidelines.length) &&
      toKnow.venue &&
      toKnow.howToGetThere &&
      (toKnow.sessionLength && toKnow.sessionLength.length);
  };

  var hasMandatoryMoreInfo = function() {
    return (moreInfo.cancellationPolicy && moreInfo.cancellationPolicy.length);
  };

  var hasMandatoryFieldsForSubmit = function() {
    return summary.name &&
      (summary.country || summary.countryCode) &&
      summary.category &&
      (prices.priceOptions && prices.priceOptions.length) &&
      (imgVid.images && imgVid.images.length) &&
      hasMandatoryNeedToKnow() &&
      hasMandatoryMoreInfo() &&
      priceOptionsHaveValidData();
  };

  // generate up-to-date workflow payload and send to api

  var updateWorkflow = function() {
    if (!submitInProgress && hasMandatoryFieldsForUpdate() && !shared.disableAutoUpdates) {
      updatesInProgress += 1;

      var workflowPayload = buildWorkflowPayload();
      shared.networkErrorUpdatingWorkflow = false;

      Product.supplierWorkflowProducts({
          supplierId: shared.supplierId
        })
        .createOrUpdate(workflowPayload, function(res) {
          shared.workflowId = res.id;
          shared.lastUpdated = res.dateModified;
          updatesInProgress -= 1;
          if (retrievingWorkflowId) {
            retrievingWorkflowId = false;
            // from an add product we need to intially get the workflowId then submit the product
            submitWorkflow();
          } else if (readyToSubmit) {
            readyToSubmit = false;
            doSubmit();
          }
        }, function(rejection) {
          shared.networkErrorUpdatingWorkflow = true;
          shared.networkErrorStringUpdatingWorkflow = rejection;
          retrievingWorkflowId = false;
          updatesInProgress -= 1;
        });
    }
  };

  // update status of workflow
  var submitWorkflow = function() {
    shared.submitAttempted = true;
    ableToCreateNote = true;

    if (!shared.submitted && hasMandatoryFieldsForSubmit()) {

      // wait for pending updates to finish
      if (updatesInProgress > 0) {
        var unbind = $rootScope.$watch(function() {
          return updatesInProgress
        }, function(updateCount) {
          if (updateCount == 0) {
            unbind();
            submitWorkflow();
          }
        });
      }
      // if no updates pending and we don't have a workflow id yet, we need to trigger an update then another submit
      else if (!shared.workflowId) {
        // generate workflowId for new product (add)
        retrievingWorkflowId = true;
        updateWorkflow();
        //submitWorkflow();
      }
      // otherwise, trigger a submit
      else if (!submitInProgress) {
        // update Workflow just before submit
        readyToSubmit = true;
        updateWorkflow();
      }
    }
  };

  var copyOldData = function () {
    prevProductData = {
      summary: angular.copy(summary),
      prices: angular.copy(prices),
      toKnow: angular.copy(toKnow),
      moreInfo: angular.copy(moreInfo),
      maps: angular.copy(maps),
      imgVid: angular.copy(imgVid)
    };
  };

  var doSubmit = function() {
    submitInProgress = true;
    Product.workflowProductsSubmit({
      supplierId: shared.supplierId,
      workflowId: shared.workflowId
    }).success(function(data) {
      shared.submitted = true;
      submitInProgress = false;
      copyOldData();
      updateWorkflow();
    }).error(function(data) {
      alert(data);
      submitInProgress = false;
    });
  };

  var entityHasChanges = function(currentState, nextState) {
    var hasChanges = false;
    // string
    if (typeof currentState === 'string') {
      hasChanges = currentState !== nextState;
    } else if (typeof currentState === 'undefined' || currentState === null) {
      hasChanges = nextState !== 'undefined' && nextState !== null;
    } else {
      // array
      // object
      hasChanges = !angular.equals(currentState, nextState);
    }

    return hasChanges;
  };

  var createNote = function (field, oldValue, newValue) {
    return {
      "note": "Workflow Field: " + field + ", changed. From: " + JSON.stringify(oldValue) + ", To: " + JSON.stringify(newValue),
      "user": $rootScope.profile.email,
      "source": "SupplierHub",
      "dateAdded": moment().format("YYYY-MM-DD")
    }
  };

  var createNoteForNewProductSubmit = function (productName) {
    return {
      "note": "New Product: " + productName + " submitted for approval",
      "user": $rootScope.profile.email,
      "source": "SupplierHub",
      "dateAdded": moment().format("YYYY-MM-DD")
    }
  };

  shared.hasMandatoryFieldsForSubmit = hasMandatoryFieldsForSubmit;

  return {
    // model initialisation
    initFreshModel: initFreshModel,
    initFromLiveProduct: initFromLiveProduct,
    initFromWorkflowProduct: initFromWorkflowProduct,

    // api integration
    updateWorkflow: updateWorkflow,
    submitWorkflow: submitWorkflow,
    copyOldData: copyOldData,

    // model
    shared: shared,
    summary: summary,
    prices: prices,
    toKnow: toKnow,
    moreInfo: moreInfo,
    maps: maps,
    imgVid: imgVid
  };
}]);

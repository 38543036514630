app.controller('MoreInfoCtrl', ['$scope', 'productData', 'autoSave', '$timeout', function($scope, productData, autoSave, $timeout) {

  $scope.model = productData.moreInfo;

  $scope.save = function() {
    productData.updateWorkflow();
  };

  // Set up autosave
	$scope.initForm = function () {
		autoSave.init($scope);
	};

  // Init the form for autosave
  $timeout(function() {
    $scope.initForm();
  }, 1000, false);

}]);

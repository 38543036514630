/**
 *  SegmentTrackingService
 *  ----------------------------------------------
 *  @author James LeBoeuf [james.leboeuf@redballoon.com.au]
 *  @since 28/9/17
 */

(function () {
  'use strict';

  angular
    .module('app')
    .factory('SegmentTrackingService', SegmentTrackingService);

  function SegmentTrackingService($window) {

    function titleCase(str) {
      return str.split(' ').map(function(val){ 
        return val.charAt(0).toUpperCase() + val.substr(1).toLowerCase();
      }).join(' ');
    }

    function trackLogin(supplier) {
      var SUPPLIER_ID = supplier.id;

      if ($window.analytics) {
        $window.analytics.identify(SUPPLIER_ID);
      }
    }

    function allPages(params) {
      if ($window.analytics) {
        $window.analytics.page(params);  
      }
    }

    return {
      trackLogin: trackLogin,
      allPages: allPages,
      titleCase: titleCase
    };
  }

})();

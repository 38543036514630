app.directive('avAddBlockout', ['Availability', 'availabilityUtil', function(Availability, availabilityUtil) {
  return {
    restrict: 'E',
    scope: {
      save: '&saveCallback',
      multiple: '=',
      model: '=ngModel',
      statusData:'='
    },
    templateUrl: 'availability/components/editor/content-panel/blockout/addblockout.html',
    controllerAs: 'ctrl',
    controller: function($scope, $element, $attrs, $routeParams, $location) {
      $scope.productsParam = $routeParams.productcode;
      $scope.products = _.filter(
        _.map(Availability.getSelectedProductCodes(), function(productCode) {
        return {
          productId: Availability.findIdByCode(productCode),
          selected: Availability.isSelected(productCode),
          productCode: productCode,
          productName: Availability.findNameByCode(productCode),
          blockouts: []
        };
      }), function (blockout) {
          return blockout.selected == true;
        }
      );

      $scope.productSelected = function(productCode) {
        return _.findWhere($scope.statusData, {code: productCode}).selected;
      };

      $scope.addBlockoutDateToProduct = function(productCode) {
        var product = _.findWhere($scope.products, {productCode: productCode});
        if (product)
          product.blockouts.push(availabilityUtil.getNewBlockout(availabilityUtil.getNewTimeSlotId(product.blockouts)));
      };
      
      $scope.onFail = function (message) {
        
      };

      $scope.saveBlockoutDates = function () {
        $scope.save({data : $scope.products, onFail : $scope.onFail});
      }
    }
  };
}]);

app.controller('CompanyAgencyAgreementDetailsCtrl',
               ['$rootScope', '$routeParams', '$sce', '$location', '$routeSegment', 'SupplierContract', 'CompanyAgencyAgreementUtils', 'supplierContract',
               function($rootScope, $routeParams, $sce, $location, $routeSegment, SupplierContract, CompanyAgencyAgreementUtils, supplierContract) {

  var self = this;
  self.utils = CompanyAgencyAgreementUtils;
  self.supplierId = $rootScope.selectedSupplier.id;
  self.contractId = $routeParams.id;
  self.supplierContract = supplierContract;
  self.contractBody = $sce.trustAsHtml(supplierContract.contract.body);
  self.signResult = null;
  self.agreementAccepted = false;
  self.currentDate = self.utils.formatDate(new Date());
  self.signingSupplier = {
    firstName: null,
    lastName: null,
    jobTitle: null
  };

  self.sign = function() {
    SupplierContract.sign(self.supplierId, self.contractId).update(self.signingSupplier).$promise.then(function() {
      var url = $routeSegment.getSegmentUrl('company.agencyAgreement');
      url += '?submitted=true';
      $location.url(url);
    }, function() {
      self.signResult = 'FAILURE';
    });
  };

}]);

app.factory('SupplierContract', function($resource, $http, $rootScope) {

  var factory = {
    findAllResult: [],
    checkAgencyAgreement: checkAgencyAgreement,
    findAll: findAll,
    find: find,
    sign: sign,
    resetFactory: resetFactory
  };

  function checkAgencyAgreement() {
    return $rootScope.selectedSupplier && !$rootScope.selectedSupplier.isAgencyAgreementSigned;
  }

  function findAll(supplierId) {
    return $http({
      method: 'GET',
      url: '/api/supplier/' + supplierId +'/contract/all'
    }).then(findAllSuccess)
      .catch(findAllError);

    function findAllSuccess(result) {
      factory.resetFactory();
      factory.findAllResult = result.data;
      return result
    }

    function findAllError(error) {
      console.error('error is', error);
      return error;
    }
  }

  function find(supplierId, contractId) {
    return $resource('/api/supplier/:supplierId/contract/:contractId', {
      supplierId: supplierId, contractId: contractId
    }, {
      'get': {
        method: 'GET'
      }
    });
  }

  function sign(supplierId, contractId) {
    return $resource('/api/supplier/:supplierId/contract/:contractId/sign', {
      supplierId: supplierId, contractId: contractId
    }, {
      'update': {
        method: 'PUT'
      }
    });
  }

  function resetFactory() {
    factory.findAllHttpResult = [];
  }

  return factory;

});

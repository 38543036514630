app.directive('calendarList', function() {
	return {
		restrict: 'E',
		scope: {
			model: '=ngModel',
			multiple: '=isMultiple',
			month: '=avMonth',
		},
		templateUrl: 'availability/components/manager/calendar-list/calendar-list.html',
		controllerAs: 'ctrl',
		controller: function($scope, $element, $attrs, $routeParams, availabilityUtil) {

			$scope.productParam = $routeParams.productcode;

			$scope.isSelectionsExpanded = [];
			for (var i = 0;i < $scope.model.length; i++) {
				$scope.isSelectionsExpanded.push(true);
			}
			$scope.expandSelections = function($index) {
				$scope.isSelectionsExpanded[$index] = $scope.isSelectionsExpanded[$index] ? false : true;
			}

			$scope.isDataInWeek = function() {
				var week = _.filter($scope.model, function(el) {
					return filterDate(el.date);
				});
					for (var i = 0; i < week.length; i++) {
						for (var j = 0; j < week[i].products.length; j++)
							if (week[i].products[j].times.length > 0) {
								return true;
							}
					}
				return false;
			}

			$scope.displayDay = function(day) {
				for (var i=0;i<day.products.length;i++) {
					if (day.products[i].times.length > 0 && day.products[i].selected) {
						return filterDate(day.date);
					}
				}
				return false;
			}

			var filterDate = function(dateStr) {
				var date = moment(dateStr).unix();
				var from = moment($scope.month.weekDate).unix();
				var to = moment($scope.month.weekDate).add(6, 'd').unix();
				if (date >= from && date <= to)
					return true;
				return false;
			}

			var rmCallback = function(params) {
				var day = _.findWhere($scope.model, {date: params.date});
					var product = _.findWhere(day.products, {productCode: params.code});
					if (product.times.length > 1) {
						for (var i = 0;i<product.times.length;i++)
							if (product.times[i].id == params.id) {
								product.times.splice(i, 1);
							}
					} else {
						for (var i=0;i<$scope.model.length;i++) {
							if ($scope.model[i].date == params.date) {
								_.each($scope.model[i].products, function(el) {
									if (el.productCode == params.code) {
										el.times.length = 0;
									}
								})
							}
						}
					}
			}

			$scope.rmTimeslot = function(productCode, id, date, product, timeslot) {
        var toDeleteData = {
          "productCode": product.productCode,
          "productName": product.productName,
          "session": {
            "startTime": moment(timeslot.start).format('HH:mm'),
            "endTime": moment(timeslot.end).format('HH:mm'),
            "maxCapacity": timeslot.maxCapacity,
            "date": timeslot.date
          }
        };
				availabilityUtil.deleteSessionById({
					code: productCode,
					id: id,
					date: date
				}, rmCallback, toDeleteData);
			}

		},
	};
});

app.directive('reviewsForProduct', function() {
  return {
    restrict: 'E',
    scope: {
      model: '=ngModel',
      fyOptions: '='
    },
    templateUrl: 'reviews/components/reviews-for-product/reviews-for-product.html',
    controllerAs: 'ctrl',
    controller: function($scope, $element, $attrs, $routeParams, $rootScope, $filter, Review) {

      var self = this;

      self.supplierId = $rootScope.selectedSupplier.id;

      self.productName = $scope.model.productName;
      self.productCode = $scope.model.productCode;
      self.productLocation = $scope.model.productLocation;
      self.productPublishUrl = $scope.model.productPublishUrl;

      self.fyOptions = $scope.fyOptions;
      self.statusOptions = ['All', 'Approved', 'Rejected'];
      self.maxResultsPerPage = 10;
      self.maxVisiblePages = 30;

      initState($scope.model.reviews, self.fyOptions[0]);

      // fresh mutable model state
      function initState(reviews, year) {
        self.currentStatus = self.statusOptions[0];
        self.currentYear = year;
        self.currentPredicate = '';
        self.isReversed = false;
        self.currentPage = 1;
        self.reviews = reviews;
        filterReviews();
      }

      function filterReviews() {
        // filter by review approval status
        self.filteredReviews = _.filter(self.reviews, function(review) {
          return self.currentStatus.toLowerCase() === 'all'
              || review.reviewStatus.toLowerCase() === self.currentStatus.toLowerCase();
        });
      }

      self.sortReviews = function(reviews) {
        return $filter('orderBy')(reviews, self.currentPredicate, self.isReversed);
      };

      self.getPaginatedResults = function() {
        var displayFromIndex = (self.currentPage - 1) * self.maxResultsPerPage;
        var displayToIndex = displayFromIndex + self.maxResultsPerPage - 1;
        return _.filter(self.sortReviews(self.filteredReviews), function(product, index) {
          return index >= displayFromIndex && index <= displayToIndex;
        });
      };

      self.hasNoResults = function() {
        return _.size(self.filteredReviews) == 0;
      };

      self.getFilteredResultCount = function() {
        return _.size(self.filteredReviews);
      };

      self.getPageCount = function() {
        return Math.ceil(self.getFilteredResultCount() / self.maxResultsPerPage);
      };

      self.hasMultiplePages = function() {
        return self.getPageCount() > 1;
      };

      self.selectStatus = function(status) {
        self.currentStatus = status;
        filterReviews();
      };

      self.selectYear = function(year) {
        self.currentYear = year;
        Review.reviewsForSingleProduct(self.supplierId, self.productCode, self.currentYear).then(function(res) {
          initState(res.reviews, year);
        });
      };

      self.orderByProperty = function(predicate) {
        // only reverse order on the 2nd sort for a column
        if (predicate === self.currentPredicate) {
          self.isReversed = !self.isReversed;
        } else {
          // score should default to high-low, which is a reversed sort
          if (predicate === 'score') {
            self.isReversed = true;
          } else {
            self.isReversed = false;
          }
        }

        self.currentPredicate = predicate;
      };

      self.formatDate = function(date) {
        return moment(new Date(date)).format('DD-MM-YYYY');
      };

      self.getIconClassForStatus = function(review) {
        var status = review.reviewStatus.toLowerCase();
        if (status === 'approved') {
          return 'fa-check-circle nps-review__approval-status--approved';
        } else if (status === 'rejected') {
          return 'fa-times-circle nps-review__approval-status--rejected';
        } else {
          return '';
        }
      };

      self.getIconClassForScore = function(review) {
        if (review.npsScore <= 6) {
          return 'fa-frown-o nps-review__score-status-icon--negative';
        } else if (review.npsScore <= 8) {
          return 'fa-meh-o nps-review__score-status-icon--passive';
        } else {
          return 'fa-smile-o nps-review__score-status-icon--positive';
        }
      };

    }
  };
});

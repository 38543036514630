app.controller('BookingsModalInstanceCtrl', ['$rootScope','$route','$modalInstance', 'Booking','supplierId', 'selection', 'title', 'bookings', '$filter','buttonName','isPending','info', function($rootScope,$route,$modalInstance, Booking, supplierId, selection, title, bookings, $filter,buttonName,isPending,info) {

  var self = this;
  self.notes = null;
  self.selection = selection;
  self.info = info;
  self.voucherNotes = null;
  self.rejectReason = null;
  self.bookings = bookings;
  self.buttonName = buttonName;
  self.isPending = isPending;
  self.s = self.bookings.length > 1 ? 's' : '';
  self.title = title + self.s;
  self.confirmButton = 'Confirm Booking' + self.s;
  self.changeButton = 'Change Booking' + self.s;
  self.pendingButton = 'Set as Pending';
  self.hstep = 1;
  self.mstep = 15;
  self.updateResult = null;
  self.supplierId = supplierId;
  self.confirmBookings = confirmBookings;
  self.changeBookings = changeBookings;
  self.setExperienceTime = setExperienceTime;
  self.updateExperienceTime = updateExperienceTime;
  self.clearExperienceTime = clearExperienceTime;
  self.showCalendar = showCalendar;
  self.getStatusType = getStatusType;
  self.cancel = cancel;
  self.updatePending =updatePending;
  self.rejectBooking = rejectBooking;
  self.hasInternalNote = hasInternalNote;
  self.internalNote = null;
  self.customerNote = null;
  self.selectedReason = null;
  self.questions = null;
  self.validateTime = null;

  self.experienceDatePickerOptions = {
    changeYear: true,
    changeMonth: true,
    minDate: 0,
    yearRange: "c-0:+10"
  };

  self.parseDateCustomFormat = function parseDateCustomFormat(timeString, format) {
    return moment(timeString, 'YYYY-MM-DDTHH:mm:ss').format(format);
  };

  $modalInstance.opened
    .then(function () {
      if (self.selection == 1 || (self.selection == 0 && self.buttonName == 'confirm')) {
        _(self.bookings).map(function(booking) {
          updateExperienceTime(booking);

        });
      }
      if(self.bookings.length == 1){
        Booking.findNotes(self.supplierId, bookings[0].id).query().$promise.then(
          function(data){
           self.notes = data;
          }, function(error){
            self.updateResult = 'FAILURE';
          }
        );
      }
      if(self.buttonName == 'reject'){
        Booking.getRejectReasons(self.supplierId).query().$promise.then(
          function(data){
            var filteredData = _.filter(data, function (c) {
              return c.transactionTypeId == 17;
            });
            self.rejectReason = filteredData;
          },function(error){
            self.updateResult = 'FAILURE';
          }
        )
      }
      if(self.selection == 0 && self.bookings.length == 1){
        Booking.findQuestions(self.supplierId, bookings[0].id).query().$promise.then(
          function(data){
            self.questions = data;
          },function(error){
            self.updateResult = 'FAILURE';
          }
        )
      }
    }
  );

  function updatePending() {
    _(self.bookings).map(function(booking) {
      Booking.updatePending(self.supplierId, booking.id).update(getPendingState(!booking.pendingConfirmation,booking)).$promise.then(
        function(data) {
          $modalInstance.close();
        }, function(error) {
          self.updateResult = 'FAILURE';
        });
    });

  }

  function rejectBooking(){
    _(self.bookings).map(function(booking) {
      var note= {
          orderItemId : booking.orderItemId,
          reasonId : self.selectedReason.id,
          message : self.internalNote
        };
      Booking.clearBooking(booking.orderItemId, self.supplierId).insert(note).$promise.then(
        function(data) {
          $modalInstance.close();
        }, function(error) {
          self.updateResult = 'FAILURE';
        });
    });
  }


  function getPendingState(pendingState,booking) {
    var pending = {
      orderItemId: booking.orderItemId,
      pendingConfirmation: pendingState
    };
    if (self.hasInternalNote()) {
      pending = angular.extend(pending, {internalNote: self.internalNote});
    }
    return pending;

  }
  function hasInternalNote() {
    return self.internalNote !== null && self.internalNote.trim() !== "";
  }

  function enhance(booking)  {
    booking.isChecked = false;
    return booking;
  }

  function getStatusType(selection) {
    return ['new', 'confirmed'][selection];
  }

  function confirmBookings() {
    updateBookings();
  }

  function changeBookings() {
    updateBookings();
  }

  function updateBookings() {

    $rootScope.$emit('clear-booking-selections');
    var bookingUpdates = _(self.bookings).map(function(booking) {
      var expDate = new Date(Date.UTC(booking.bookingDates.experienceDate.getFullYear(), booking.bookingDates.experienceDate.getMonth(), booking.bookingDates.experienceDate.getDate()));
      return {
        id: booking.id,
        orderItemId: booking.orderItemId,
        voucherNumber: booking.voucherNumber,
        experienceDate: expDate,
        experienceTime: booking.bookingDates.experienceTimeMilitary,
        experienceTimeMeridian: booking.bookingDates.experienceTimeAsDisplay,
        notes: self.customerNote,
        resendSupplierConfirmation: booking.resendConfirmation,
        internalNote: self.internalNote,
      };
    });

    Booking.bookings(self.supplierId).update(bookingUpdates).$promise.then(
      function(data) {
        $modalInstance.close();
      }, function(error) {
        self.updateResult = 'FAILURE';
      });
  }


  function setExperienceTime(booking) {
    if (booking.bookingDates.experienceTime === null) {
      var date = new Date();
      // display the next closest quarter of an hour
      var currentMinute = date.getMinutes();
      var currentHour = date.getHours();
      var mod = currentMinute % 60;
      if (mod < 15) {
        date.setMinutes(15);
      }
      else if (mod < 30) {
        date.setMinutes(30);
      }
      else if (mod < 45) {
        date.setMinutes(45);
      }
      else {
        date.setHours(currentHour + 1);
        date.setMinutes(0);
      }
      booking.bookingDates.experienceTime = date;
    }
    updateExperienceTime(booking);
    self.validateTime = null;
    _(self.bookings).map(function(booking) {
      if(booking.bookingDates.experienceTime == null){
        self.validateTime = 'FAIL';
        return;
      }
    });
    if(self.validateTime != 'FAIL'){
      self.validateTime = 'PASS';
    }

  }

  function clearExperienceTime(booking) {
    booking.bookingDates.experienceTime = null;
    booking.bookingDates.experienceTimeAsDisplay = null;
    booking.bookingDates.experienceTimeMilitary = null;
    self.validateTime = 'FAIL';
  }

  function updateExperienceTime(booking) {
    if (booking.bookingDates.experienceTime == null) {
      self.validateTime = 'FAIL';
      return;
    }

    self.validateTime = 'PASS';
    booking.bookingDates.experienceTimeAsDisplay = $filter('date')(booking.bookingDates.experienceTime, 'hh:mma');
    booking.bookingDates.experienceTimeMilitary =  $filter('date')(booking.bookingDates.experienceTime, 'HH:mm');


  }



  function showCalendar(index) {
    var id = '#experienceDate-' + index;
    $(id).focus();
  }

  function cancel() {
    $rootScope.$emit('clear-booking-selections');
    $modalInstance.dismiss();
  }
}]);

app.directive('avSpTimeslot', ['availabilityUtil', function() {
	return {
		restrict: 'E',
		templateUrl: 'availability/components/editor/content-panel/timeslot/sp-timeslot.html',
		controllerAs: 'ctrl',
		controller: function ($scope, $element, $attrs, $routeParams, $location, availabilityUtil) {
			var ctrl = this;

      var disabledCount = 0;

      var constructDayToSend = function() {
        var dayToSend = {};
        dayToSend.products = [];
        dayToSend.date = ctrl.date;
        dayToSend.products.push(ctrl.product);
        return dayToSend;
      };

			ctrl.date = $routeParams.date;
			ctrl.productcode = $routeParams.selected;
			ctrl.selectedProducts = $routeParams.productcode;
			ctrl.id = parseInt($routeParams.id, 10);
			ctrl.day = {};

			availabilityUtil.copyDay(ctrl.day, ctrl.date);
			if (ctrl.day == undefined)
				$location.path('/home');
			else {
				ctrl.product = _.findWhere(ctrl.day.products, {productCode: ctrl.productcode});
				ctrl.timeslot = _.findWhere(ctrl.product.times, {id: ctrl.id});
				if (!ctrl.timeslot)
					$location.path('/home');
			}

			// TODO: refactor this to use new validation found
			// in mp-timeslot.js and mp-timeslot.js
      $scope.saveButtonValidation = function(count) {
        disabledCount += count;

        $scope.buttonDisabled = (disabledCount > 0);
      };

      $scope.onFail = function (response) {
        console.error('failed with response:', response);
        var errors = response.data.errors;
        var products = [].concat(ctrl.day.products);
        var errorIndex = 0;

        ctrl.day.products = products.map(function (product) {
          product.times = product.times.map(function (time) {
            time.error = errors[errorIndex++];
            return time;
          });
          return product;
        });
      };

			ctrl.saveTimeslot = function() {
				$scope.saveDayToModel(constructDayToSend(), ctrl.date, 'list', null, null, $scope.onFail);
			}
		}
	};
}]);

app.controller('ProductListCtrl',
    ['$rootScope', '$filter', '$routeParams', '$sce', 'products', 'workflowProducts', 'Availability', '$location',
      function($rootScope, $filter, $routeParams, $sce, products, workflowProducts, Availability, $location) {
  var self = this;

  self.status = {
    isOpen: false
  };

  // mobile accordion table
  self.expandProduct = expandProduct;
  self.selectedSupplierType = $rootScope.selectedSupplier.productType;

  function expandProduct(family) {
    family.isExpanded = family.isExpanded ? false : true;
  }


  self.publishTypeGroupOptions = [
    {
      count: 0,
      label: 'Published',
      class: 'published',
      options: ['availability', 'nps', 'edit', 'copy']
    },
    {
      count: 0,
      label: 'Preview',
      class: 'preview',
      options: ['availability', 'nps', 'edit']
    },
    {
      count: 0,
      label: 'Honouring',
      class: 'honouring',
      options: ['availability', 'nps', 'edit']
    },
    {
      count: 0,
      label: 'Unpublished',
      class: 'unpublished',
      options: ['nps']
    },
    {
      count: 0,
      label: 'Draft',
      class: 'preview',
      options: ['edit'],
      displaySingleStatus: true
    }
  ];


  if($rootScope.selectedSupplier.productType == 'Direct Ship')
  {
    self.publishTypeGroupOptions = [];
    self.publishTypeGroupOptions = [
      {
        count: 0,
        label: 'Published',
        class: 'published',
        options: ['availability', 'nps', 'edit', 'copy']
      },
      {
        count: 0,
        label: 'Unpublished',
        class: 'unpublished',
        options: ['nps']
      }
    ];
}

  self.order = function(predicate) {
    if (predicate === self.predicate) {
      self.reverse = !self.reverse;
    }
    self.predicate = predicate;
    self.families = orderBy(self.families, predicate, self.reverse);
  };

  self.resetFilter = function() {
    self.keyword = '';
    self.submit();
  };

  self.submit = function() {
    self.families = familiesWithPublishType(allFamilies, self.publishTypeFilter.label);
    self.query.name = self.keyword;
  };

  self.selectPublishTypeGroup = function(item) {
    self.publishTypeFilter = item;
    self.status.isOpen = false;
    self.currentPublishTypeGroup = _.find(self.publishTypeGroupOptions, function(option) {
      return self.publishTypeFilter.label === option.label;
    });
    self.submit();
  };

  self.getPublishTypeGroupCss = function(publishTypeGroup) {
    return {
      'aGreen': 'fa-circle status-icon--green',
      'bEye': 'fa-eye',
      'cAmber': 'fa-circle status-icon--yellow',
      'dRed': 'fa-circle status-icon--red'
    }[publishTypeGroup];
  };

  self.getPublishTypeFromStatus = function(wfStatus) {
    return {
      START: 'Draft',
      AWAITING_MODERATION: 'Preview'
    }[wfStatus];
  };

  self.displayCog = function(publishType) {
    var pt = publishType.toLowerCase();
    return (pt === 'published' || pt === 'exclusive' || pt === 'preview');
  };

  self.productUrl = function(product) {
    return (product.url ? '//' + product.url.url + '/' + product.url.friendlyUrl : '');
  };

  self.view = function(publishType) {
    return (publishType.toLowerCase() === 'preview' ? 'Preview' : 'Live');
  };

  self.getClassification = function(priceOption) {
    var result = '&nbsp';
    if (priceOption.status.isNew) { result = 'New'; }
    if (priceOption.status.special) { result = 'Special'; }
    return $sce.trustAsHtml(result);
  };

  // filter a product families price options for the selected publish type
  self.filterPriceOptions = function(family) {
    return _.filter(family.priceOptions, function(priceOption) {
      return priceOption.status.publishType.toLowerCase() === self.currentPublishTypeGroup.label.toLowerCase();
    });
  };

  self.shouldDisplayOption = function(option) {
    return self.currentPublishTypeGroup && _.contains(self.currentPublishTypeGroup.options, option);
  };

  self.shouldLinkNameToEdit = function() {
    return self.shouldDisplayOption('edit');
  };

  self.generateProductViewHref = function(familyCodeOrWorkflowId, publishType, preview,mode) {
    var previewVal = preview ? 'true' : 'false';
    return '/myproducts/view/' + familyCodeOrWorkflowId + '?publishType=' + publishType + '&preview=' + previewVal+ '&mode=' + mode;
  };

  // if there is a workflow product/draft with given product family code, return its workflow id, otherwise return null
  self.findWorkflowIdForFamilyCode = function(familyCode) {
    var matchingDraft = _.find(self.drafts, function(draft) {
      return draft.familyCode === familyCode;
    });
    return matchingDraft ? matchingDraft.workflowId : null;
  };

  // Drafts link thru to 'edit product', all other publish types link thru to 'view product'
  self.getHrefForTitle = function(family) {
    var publishType = self.publishTypeFilter.label;
    var familyCodeOrWorkflowId = (publishType === 'Draft' || publishType === 'Preview') ? family.workflowId : family.familyCode;
    return self.generateProductViewHref(familyCodeOrWorkflowId, publishType, publishType !== 'Draft','edit');
  };

  // Link thru to 'edit product'. Drafts, or families that have a corresponding draft, will populate using the draft
  // product, otherwise it will populate using the live product.
  self.getHrefForEditProduct = function(family,mode) {
    var publishType;
    var familyCodeOrWorkflowId;

    var workflowId = self.findWorkflowIdForFamilyCode(family.familyCode);

    if (self.publishTypeFilter.label === 'Draft' || workflowId != null) {
      // populate with workflow/draft product
      publishType = 'Draft';
      // if family code does not exist then use the family.workflowId instead of the workflowId
      if (family.familyCode === "-") {
        familyCodeOrWorkflowId = family.workflowId;
      }else{
        familyCodeOrWorkflowId = workflowId;
      }
    } else {
      // populate with live product
      publishType = self.publishTypeFilter.label;
      familyCodeOrWorkflowId = family.familyCode;
    }
    return self.generateProductViewHref(familyCodeOrWorkflowId, publishType, false,mode);

  };

// get Status for various publish types, Draft uses the workflow status
  self.getStatusByType = function(family, priceOption) {
    var publishType = self.publishTypeFilter.label;
    var status = publishType === 'Draft' ? family.wfStatus : priceOption.status.publishType;
    return status.toLowerCase();
  };

  var allFamilies = initAllFamilies(products);

  // transform workflow products into expected model format, and append to allFamilies
  var transformedWorkflowProducts = _.map(workflowProducts, function(workflowProduct) {
    var productDetails = JSON.parse(workflowProduct.entityDetails);

    var model = {
      workflowId: workflowProduct.id,
      familyCode: productDetails.productFamilyCode ? productDetails.productFamilyCode : '-',
      name: productDetails.name,
      wfStatus: workflowProduct.wfStatus,
      priceOptions: productDetails.priceOptions ?
          _.map(productDetails.priceOptions, function(priceOption) {
            return {
              noParticipants: priceOption.noParticipants,
              poType: priceOption.poType,
              sellPrice: priceOption.sellPrice,
              status: {
                publishType: self.getPublishTypeFromStatus(workflowProduct.wfStatus),
                publishTypeGroup: 'bEye',
                isNew: false, // ??
                special: false // ??
              }
            }
          }) : []
    };

    var publishGroup = workflowProduct.wfStatus === 'START' ? 4 : 1;

    if($rootScope.selectedSupplier.productType != 'Direct Ship')
    {
      self.publishTypeGroupOptions[publishGroup].count++;
    }

    return model;
  });

  // Combines products from product table and workflow table
  allFamilies = allFamilies.concat(transformedWorkflowProducts);

  self.publishTypeFilter = $routeParams.publishType ? routeParamToPublishTypeGroup() : self.publishTypeGroupOptions[0];
  self.drafts = transformedWorkflowProducts;
  self.families = familiesWithPublishType(allFamilies, self.publishTypeFilter.label);

  self.predicate = '';
  self.reverse = false;
  var orderBy = $filter('orderBy');
  self.order('name');

  self.keyword = $routeParams.keyword ? $routeParams.keyword : '';
  self.query = {
    name: self.keyword
  };

  function routeParamToPublishTypeGroup() {
    return _(self.publishTypeGroupOptions).find(function(o) {
      return o.label === $routeParams.publishType;
    });
  }

  function familiesWithPublishType(families, type) {
    if (type === 'All') return families;

    return _(families).filter(function(f) {
      if (f.priceOptions.length === 0 && f.wfStatus === "START" && type === "Draft") {
        return true;
      } else {
        return _(f.priceOptions).some(function(po) {
          return po.status.publishType === type;
        });
      }
    })
  }

  function initAllFamilies(products) {
    var enhancedProducts = _(products).map(function(p) {
      var publishType = p.status.publishType.toLowerCase();

        switch (publishType) {
          case 'exclusive':
            // TODO: How to handle this?
            break;
          case 'preview':
            if($rootScope.selectedSupplier.productType != 'Direct Ship')
            {
              p.status.publishTypeGroup = 'bEye';
              self.publishTypeGroupOptions[1].count++;
            }
            break;
          case 'honouring':
            if($rootScope.selectedSupplier.productType != 'Direct Ship')
            {
              p.status.publishTypeGroup = 'cAmber';
              self.publishTypeGroupOptions[2].count++;
            }
            break;
          case 'unpublished':
            if($rootScope.selectedSupplier.productType != 'Direct Ship')
            {
              p.status.publishTypeGroup = 'dRed';
              self.publishTypeGroupOptions[3].count++;
            }
            else{
              p.status.publishTypeGroup = 'dRed';
              self.publishTypeGroupOptions[1].count++;
            }
            break;
          default:
            p.status.publishTypeGroup = 'aGreen';
            self.publishTypeGroupOptions[0].count++;
            break;
        }

      return p;
    });

    var groupByFamily = _(enhancedProducts).groupBy(function(po) {return po.familyCode});

    return _(groupByFamily).map(function(priceOptions, familyCode) {
      return {familyCode: familyCode, name: _(priceOptions).first().name, priceOptions: priceOptions};
    });
  }

  self.manageSingleProductAvailability = function(productCode) {
    $location.url('/myproducts/availability/manager/code='+productCode+'/calendar');
  }

}]);

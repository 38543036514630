app.factory('Availability', ['paramToArrayFilter', '$resource', '$q', '$http', function (paramToArrayFilter, $resource, $q, $http) {

  // PRIVATE DATA
  var self = this;

  self.selectedProductsArray = [];
  self.productStatus = [];
  self.data = [];
  self.blockoutDatesData = [];
  self.blockoutDates = [];
  self.bulkdates = [];

  function findNameByCode(productCode) {
    var product = _.findWhere(self.productStatus, { code: productCode });
    return (product) ? product.name : "";
  }
  function findNameById(productId) {
    var product = _.findWhere(self.productStatus, { id: parseInt(productId) });
    return (product) ? product.name : "";
  }
  function findIdByCode(productCode) {
    var product = _.findWhere(self.productStatus, { code: productCode });
    return (product) ? product.id : 0;
  }
  function findCodeById(productId) {
    var product = _.findWhere(self.productStatus, { id: parseInt(productId) });
    return (product) ? product.code : 0;
  }
  function findLocationByCode(productCode) {
    return _.findWhere(self.productStatus, { code: productCode }).shortLocation;
  }
  function isSelected(productCode) {
    var product = _.findWhere(self.productStatus, { code: productCode });
    return (product) ? product.selected : false;
  }
  function isSelectedById(productId) {
    var product = _.findWhere(self.productStatus, { id: parseInt(productId) });
    return (product) ? product.selected : false;
  }

  function formatDateModel(container, response, blockoutModel) {
    container.length = 0;
    _.each(response, function (session) {
      var day = _.findWhere(container, { date: session.date });
      if (typeof (day) === 'undefined') {
        day = {
          date: session.date,
          products: _.map(self.selectedProductsArray, function (code) {
            return {
              selected: isSelected(code),
              productCode: code,
              productName: findNameByCode(code),
              shortLocation: findLocationByCode(code),
              times: []
            };
          })
        };
        var product = _.findWhere(day.products, { productCode: session.productFamilyCode });

        var timeslot = {
          id: session.id,
          start: moment(session.startTime, 'HH:mm'),
          end: moment(session.endTime, 'HH:mm'),
          allDay: false,
          maxCapacity: session.availability,
          bookingCount: session.bookingCount,
          status: 'Available',
          location: {
            Name: "NSW",
            countryCode: "AU",
            id: 1
          }
        };
        product.times.push(timeslot);
        container.push(day);
      } else {
        var product = _.findWhere(day.products, { productCode: session.productFamilyCode });
        {
          var session = {
            id: session.id,
            start: moment(session.startTime, 'HH:mm:ss'),
            end: moment(session.endTime, 'HH:mm:ss'),
            allDay: false,
            maxCapacity: session.availability,
            bookingCount: session.bookingCount,
            status: 'Available',
            location: {
              Name: "NSW",
              countryCode: "AU",
              id: 1
            }
          };
          product.times.push(session);
        }
      }
    });
    if (typeof (blockoutModel) != 'undefined') {
      _.each(blockoutModel, function (sessions) {
        if (typeof (sessions.date) != 'undefined') {
          var day = _.findWhere(container, { date: sessions.date });
          if (typeof (day) === 'undefined') {
            day = {
              date: sessions.date,
              products: _.map(self.selectedProductsArray, function (code) {
                return {
                  selected: isSelected(code),
                  productCode: code,
                  productName: findNameByCode(code),
                  shortLocation: findLocationByCode(code),
                  times: []
                };
              })
            };
            var product = _.findWhere(day.products, { productCode: sessions.productFamilyCode });
            var timeslot = {
              id: sessions.id,
              start: moment('00:00:00.000', 'HH:mm'),
              end: moment('00:00:00.000', 'HH:mm'),
              allDay: true,
              maxCapacity: 0,
              bookingCount: 0,
              weeklyDays: sessions.weeklyDays,
              status: 'Blockout',
              location: {
                Name: "NSW",
                countryCode: "AU",
                id: 1
              }
            };
            product.times.push(timeslot);
            container.push(day);
          } else {
            var product = _.findWhere(day.products, { productCode: sessions.productFamilyCode });
            {
              var sessions = {
                id: sessions.id,
                start: moment('00:00:00.000', 'HH:mm:ss'),
                end: moment('00:00:00.000', 'HH:mm:ss'),
                allDay: true,
                maxCapacity: 0,
                bookingCount: 0,
                weeklyDays: sessions.weeklyDays,
                status: 'Blockout',
                location: {
                  Name: "NSW",
                  countryCode: "AU",
                  id: 1
                }
              };
              product.times.push(sessions);
            }
          }
        }
      });
    }
    return container;
  }

  function formatBlockoutDateModel(container, response) {
    container.length = 0;
    if (response) {
      var groupedBlockout = _.groupBy(response, function (data) {
        return data.productFamilyId;
      });
      _.each(groupedBlockout, function (boResponses, productId) {
        var product = _.findWhere(container, { productId: productId });
        if (typeof (product) === 'undefined') {
          product = {
            productId: productId,
            selected: isSelectedById(productId),
            productCode: findCodeById(productId),
            productName: findNameById(productId),
            blockouts: _.map(boResponses, function (data) {
              return {
                id: data.id,
                productFamilyId: data.productFamilyId,
                date: data.date,
                weeklyDays: data.weeklyDays
              };
            })
          };
          container.push(product);
        }
      });
    }
    return container;
  }

  // PUBLIC FUNCTIONS

  /**
   * Load current availability data for all selected products.
   */
  function loadAllAvailabilityData(productCodesString, yyyymm) {
    yyyymm = typeof (yyyymm) === 'undefined' ? moment().format('YYYY-MM') : yyyymm;
    var fromDate = moment(yyyymm, 'YYYY-MM').startOf('month').startOf('week');
    var toDate = moment(yyyymm, 'YYYY-MM').endOf('month').endOf('week').add(14, 'days');
    self.selectedProductsArray = paramToArrayFilter(productCodesString);

    return $q.all({
      status: getProductStatusPromise(productCodesString),
      model: getFromDatesPromise(productCodesString, fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD')),
      blockoutModel: getBlockoutDatesFromDatesPromise(productCodesString, fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'))
    }).then(function (response) {
      self.productStatus.length = 0;
      _.each(response.status, function (p) {
        self.productStatus.push({
          id: p.id,
          code: p.code,
          name: p.name,
          selected: true,
          shortLocation: p.shortVenue,
          status: { publishType: p.status }
        });
      });
      return {
        status: self.productStatus,
        model: formatDateModel(self.data, response.model, response.blockoutModel),
        month: {
          date: fromDate.format('YYYY-MM-DD'),
          to: toDate.format('YYYY-MM-DD'),
          month: moment(yyyymm, 'YYYY-MM').month(),
          year: moment(yyyymm, 'YYYY-MM').year(),
          weekDate: moment(yyyymm, 'YYYY-MM').startOf('w').format('YYYY-MM-DD'),
        },
      }
    });

  }

  /**
   * Load Date model for given codes and dates
   */
  function getFromDatesPromise(productCodesString, fromDateStr, toDateStr) {
    toDateStr = moment(toDateStr, 'YYYY-MM-DD').format('YYYY-MM-DD');
    return $resource('/api/manageavailability/session/:from/:to?:codes',
      {
        from: fromDateStr,
        to: toDateStr,
        codes: productCodesString
      }, {
        'query': { method: 'GET', isArray: true }
      }
    ).query().$promise;
  }

  function getBlockoutDatesFromDatesPromise(productCodes, fromDate, toDate) {
    toDate = moment(toDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
    return $resource('/api/manageavailability/blockouts?:codes&fromDate=:from&toDate=:to',
      {
        from: fromDate,
        to: toDate,
        codes: productCodes
      }, {
        'query': { method: 'GET', isArray: true }
      }
    ).query().$promise;
  }

  /**
   * Load current availability data for all selected products.
   */
  function loadEditBulkDates(productCodesString, searchOptions) {

    // TODO(mh): pass in date range
    self.data = getAvailability(self.selectedProductsArray, null, null);
    return self.data;
  }

  function getProductStatusPromise(productCodesString) {
    return $resource('/api/productFamily?:codes',
      {
        codes: productCodesString
      }, {
        'query': {
          method: 'GET',
          isArray: true,
        }
      }
    ).query().$promise;
  }

  function deleteSessionPromise(id, toDeleteObject) {
    return $http({
      url: '/api/manageavailability/session/' + id,
      headers: { 'Content-Type': 'application/json' },
      method: 'DELETE',
      data: toDeleteObject
    });
  }

  function removeADMappingPromise(productCode) {
    return $http({
      url: '/api/manageavailability/sessions/' + productCode + '?skipOnNonSH=true',
      method: 'DELETE'
    })
  }

  function removeBDMappingPromise(productCode) {
    return $http({
      url: '/api/manageavailability/blockouts/all/' + productCode,
      method: 'DELETE'
    })
  }

  function UpdateSessionsResource() {
    return $resource('/api/manageavailability/sessions',
      {},
      {
        'save': {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        }
      }
    );
  }

  function BlockOutSessionsResource(params, supplierId) {
    return $resource('/api/manageavailability/blockouts/:supplierID',
      {
        supplierID: supplierId
      }, {
        'delete': {
          headers: { 'Content-Type': 'application/json' },
          method: 'DELETE',
          data: params.body
        },
        'save': {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          isArray: true
        },
        'update': {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' }
        }
      }
    );
  }

  //function UpdateBlockoutsResource(supplierId) {
  //  return $resource('/api/manageavailability/blockouts/:supplierID',
  //    {
  //      supplierID: supplierId
  //    }, {
  //      'delete': {
  //        headers: { 'Content-Type': 'application/json' },
  //        method: 'DELETE',
  //        isArray: true
  //      },
  //      'save': {
  //        method: 'POST',
  //        headers: { 'Content-Type': 'application/json' },
  //        isArray: true
  //      },
  //      'update': {
  //        method: 'PUT',
  //        headers: { 'Content-Type': 'application/json' }
  //      }
  //    }
  //  );
  //}

  function getProductStatus() {
    return self.productStatus;
  }

  function getSelectedProducts() {
    return self.selectedProductsArray;
  }

  function getDataModel() {
    return self.data;
  }

  function getBulkDates() {
    return self.bulkdates;
  }

  function getBlockoutDates() {
    return self.blockoutDates;
  }

  return {
    loadAll: loadAllAvailabilityData,
    getFromDatesPromise: getFromDatesPromise,
    formatDateResponse: formatDateModel,
    getBlockoutDatesFromDatesPromise: getBlockoutDatesFromDatesPromise,
    getProductStatusPromise: getProductStatusPromise,
    formatBlockoutDateModelResponse: formatBlockoutDateModel,
    getSelectedProductCodes: getSelectedProducts,
    getModel: getDataModel,
    getBulkDates: getBulkDates,
    getStatusModel: getProductStatus,
    getBlockoutDates: getBlockoutDates,
    findNameByCode: findNameByCode,
    isSelected: isSelected,
    findIdByCode: findIdByCode,
    deleteSessionPromise: deleteSessionPromise,
    removeADMappingPromise: removeADMappingPromise,
    removeBDMappingPromise: removeBDMappingPromise,
    UpdateSessionsResource: UpdateSessionsResource,
    BlockOutSessionsResource: BlockOutSessionsResource
  }
}]);

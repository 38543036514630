app.directive('avEditDatebulk', function () {
  return {
    restrict: 'E',
    scope: {
      save: '&saveCallback',
      model: '=ngModel',
      multiple: '=multiple',
      statusData: '='
    },
    templateUrl: 'availability/components/editor/content-panel/datebulk/editdatebulk.html',
    controllerAs: 'ctrl',
    controller: function ($scope, $element, $attrs, $routeParams, $location, availabilityUtil, $rootScope, paramToArrayFilter, $window) {
      $scope.productParam = $routeParams.productcode;
      $scope.filter = {
        fromDate: undefined,
        toDate: undefined,
        frequencyPeriod: undefined,
        frequencyMultiplier: undefined,
        selectedDays: []
      };

      $scope.saveTimeslots = function () {
        var param = {
          date: moment().format('YYYY-MM-DD'),
          products: [],
        };
        _.each($scope.model, function (day) {
          _.each(day.products, function (product) {
            var p = _.findWhere(param.products, { productCode: product.productCode });
            if (typeof (p) === 'undefined') {
              param.products.push(product);
            } else {
              _.each(product.times, function (session) {
                p.times.push(session);
              })
            }
          });
        });
        $scope.save({
          day: param,
          dateString: param.date,
          view: 'calendar',
          statusData: $scope.statusData
        });
      };

      // ********** Remove Blockout dates
      //$scope.removeBlockoutDates = function () {            
      //  $scope.deleteDayToBlocksOutModel($scope.filter, $scope.onFail, $scope.statusData);
      //};
      $scope.onFail = function (response) {
        console.error('failed with response:', response);
        //This error is thrown when a session is added for a product
        // that is already mapped to another provider (e.g: Rezdy,Livn etc)
        if (response.status === 409) {
          handleMappingConflict(response.data);
        }
      };

      var stopLoadingSpinner = function () {
        $rootScope.manage_availability_show_loading = false;
      };

      var saveCallback = function (params) {
        var fromDate = (params.date) ? moment(params.date).startOf('month').startOf('week') : moment().startOf('month').startOf('week');
        var toDate = (params.date) ? moment(params.date).endOf('month').endOf('week') : moment().endOf('month').endOf('week');
        var view = (params.view) ? params.view : "calendar";
        availabilityUtil.loadNewDates($scope.productParam, fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), stopLoadingSpinner);
        $location.path('/myproducts/availability/manager/' + $scope.productParam + '/' + view);
      };
      $scope.removeBlockoutDates = function () {

        var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        var repeat = [];

        _.each($scope.filter.selectedDays, function (el, idx) {
          if (el)
            repeat.push(days[idx]);
        });

        var dateRange = {
          from: moment($scope.filter.fromDate).format('YYYY-MM-DD'),
          to: moment($scope.filter.toDate).format('YYYY-MM-DD')
        };
        var day = {
          date: moment().format('YYYY-MM-DD'),
          products: []
        };
        var productCodes = paramToArrayFilter($scope.productParam);
        _.each(productCodes, function (code) {
          if (availabilityUtil.isProductSelected(code))
            day.products.push({
              productCode: code
            });
        });

        var dateString = day.date;
        var view = 'calendar';

        var handleXHRFail = function (response) {
          if ($scope.onFail) $scope.onFail(response);
          saveFailCallback(response);
        };
        var toDelete = [];
        var params = {
          body: {
            products: [],
            dateRange: {
              from: dateString,
              to: dateString
            }
          },
          day: day,
          date: dateString,
          view: view
        };

        day.products = _.map(day.products, function (product) {
          if ($scope.statusData) {
            var item = _.find($scope.statusData, function (statusItem) { return statusItem.code === product.productCode });
            product.id = item ? item.id : 0;
          }
          return product;
        });

        _.each(day.products, function (product) {
          if (availabilityUtil.isProductSelected(product.productCode))
            params.body.products.push(product.id);
        });

        params.body.repeat = repeat;

        if (dateRange) {
          params.body.dateRange = dateRange;
        }

        if (toDelete.length > 0) {
          params.body.toBeDeletedSessions = toDelete;
        }
        availabilityUtil.deleteBlockouts(params, saveCallback, handleXHRFail);
      };

      //***************** Remove blockoutdates


      $scope.getResultsNumber = function () {
        var res = 0;
        if ($scope.model)
          for (var i = 0; i < $scope.model.length; i++) {
            if ($scope.filter.selectedDays[moment($scope.model[i].date).day()]) {
              var products = $scope.model[i].products;
              for (var j = 0; j < products.length; j++) {
                res += products[j].times.length;
              }
            }
          }
        return res;
      }

      $scope.isSelectionsExpanded = [];
      if ($scope.model.length > 1)
        for (var i = 0; i < $scope.model.length; i++) {
          $scope.isSelectionsExpanded.push(false);
        }
      $scope.expandSelections = function ($index) {
        $scope.isSelectionsExpanded[$index] = $scope.isSelectionsExpanded[$index] ? false : true;
      }

      $scope.displayDay = function (day) {
        for (var i = 0; i < day.products.length; i++) {
          if ($scope.displayProduct(day.products[i].productCode))
            if (day.products[i].times.length > 0 && day.products[i].selected)
              if ($scope.filter.selectedDays[moment(day.date).day()])
                return true;
        }
        return false;
      }

      $scope.displayProduct = function (code) {
        return availabilityUtil.isProductSelected(code);
      }

      $scope.selectAllDeleted = function () {
        $scope.markAllDeleted = !$scope.markAllDeleted;
        _.each($scope.model, function (day, key) {
          $scope.isSelectionsExpanded[key] = true;
          _.each(day.products, function (product) {
            _.each(product.times, function (time) {
              time.isMarkDeletedSelected = $scope.markAllDeleted
            })
          })
        })
      };

      $scope.$on('$routeChangeStart', function (next, current) {
        $scope.model.length = 0;
      });
    },
  };
});

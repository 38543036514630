app.factory('SupplierSelection', function($q, $rootScope, $modal, User, Supplier, SegmentTrackingService, $location, Auth, $timeout) {

  function profileLoaded() {
    return !_.isEmpty($rootScope.profile);
  }

  function suppliersLoaded() {
    return !_.isEmpty($rootScope.suppliers);
  }

  function supplierSelected() {
    return !_.isEmpty($rootScope.selectedSupplier);
  }

  function fetchProfile() {
    return User.profile().get().$promise;
  }

  function fetchSuppliers() {
    return Supplier.allForCustomer().query().$promise;
  }

  function storeProfile(profile) {
    $rootScope.profile = profile;
    return profile;
  }

  function storeSuppliers(suppliers) {
    $rootScope.suppliers = suppliers;
    return suppliers;
  }

  function storeDirectShipSuppliers(suppliers) {
    var directSupplier = _.filter(suppliers, function (d) { return d.productType === 'Direct Ship' });
    $rootScope.suppliers = directSupplier;
    return directSupplier;
  }

  function loadProfile() {
    return fetchProfile().then(storeProfile);
  }

  function loadSuppliers() {
    return fetchSuppliers().then(storeSuppliers);
  }

  function loadDirectShipSuppliers() {
    return fetchSuppliers().then(storeDirectShipSuppliers);
  }

  function selectSupplier(suppliers, forceSelect) {
    function openModal(suppliers, currentSupplier) {
      return $modal.open({
        templateUrl: 'common/choose-supplier-modal.html',
        controller: 'ChooseSupplierModalCtrl',
        windowClass: 'supplier-modal',
        backdrop: 'static', // This prevents clicking backdrop closing modal
        keyboard: false, // This prevents pressing ESC key closing modal
        resolve: {
          items: function() { return suppliers; },
          selectedItem: function() { return currentSupplier; }
        }
      });
    }

    function changingSupplier(s1, s2) {
      return (s1 && s2) ? (s1.id != s2.id) : (s1 != s2);
    }

    var deferred = $q.defer();

    if (!suppliers.length) {
      Auth.logout().success(function() {
        $location.path('/login');
        delete $rootScope.profile;
        delete $rootScope.selectedSupplier;
        $rootScope.noSupplierCompanyAccount = true;

        $timeout(function(){
          $rootScope.$apply();
        });
      });
    } else if (!forceSelect && (suppliers.length === 1 || sessionStorage.getItem('selectedSupplier'))) {
      // single supplier, trivial
      var selectedItem = JSON.parse(sessionStorage.getItem('selectedSupplier')) || suppliers[0];
      handleSelectedSupplier(selectedItem);
      deferred.resolve();
    } else {
      // multiple suppliers, present selection modal
      var modal = openModal(suppliers, $rootScope.selectedSupplier);
      modal.result.then(function(selectedItem) {
        if (changingSupplier($rootScope.selectedSupplier, selectedItem)) {
          handleSelectedSupplier(selectedItem);
          deferred.resolve();
        } else {
          deferred.reject();
        }
      });
    }

    return deferred.promise;

  }

  function handleSelectedSupplier(supplier) {
    function insuranceExpired() {
      var minDate = moment().add(1, 'day').startOf('day');
      var d = supplier.insurance.expiry;
      var valid = d && (minDate.isSame(d) || minDate.isBefore(d));
      return !valid;
    }

    $rootScope.selectedSupplier = supplier;
    $rootScope.insuranceExpired = insuranceExpired();
    sessionStorage.setItem("selectedSupplier",JSON.stringify(supplier));
    if ($rootScope.trackUserLogin) {
      SegmentTrackingService.trackLogin(supplier);
      $rootScope.trackUserLogin = false;
    }

  }

  function loadInitialData() {
    if (profileLoaded() && suppliersLoaded() && supplierSelected()) {
      // nothing to do, return resolved promise
      return $q.when(true);
    } else {
      // load profile, load suppliers and select a supplier NOT ASYNC
      return loadProfile()
        .then(loadSuppliers)
        .then(selectSupplier);
    }
  }

  function loadDirectShipInitialData() {
    if (profileLoaded() && suppliersLoaded() && supplierSelected()) {
      // nothing to do, return resolved promise
      return $q.when(true);
    } else {
      // load profile, load suppliers and select a supplier NOT ASYNC
      return loadProfile()
        .then(loadDirectShipSuppliers)
        .then(selectSupplier);
    }
  }

  return {
    loadInitialData: loadInitialData,
    selectSupplier: selectSupplier,
    loadProfile: loadProfile,
    getUserProfile: fetchProfile,
    loadDirectShipInitialData: loadDirectShipInitialData
  };

});

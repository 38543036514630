
// Quick directive to close collapsible nav
// For now, use expected function in scope for toggle.

app.directive('collapseNav', function() {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            elem.bind('click', function () {
                scope.collapseNav();
                scope.$apply();
            });
        }
    };
});

//  Closing of topNavmenu for mobile view when slide out menu items clicked.

app.directive('closeMenu', function() {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            elem.bind('click touch', function () {
              $('#mainNav .navbar-toggle:visible').click();
              scope.$apply();
            });
        }
    };
});

app.factory('Invoice', function($resource, $window) {

  // dates must be formatted as yyyy-mm-dd
  function formatDates(h) {
    return _.mapObject(h, function(v) {
      return moment.isDate(v) ? moment(v).format('YYYY-MM-DD') : v;
    });
  }

  function search(supplierId, searchParams) {
    var params = formatDates(angular.extend({}, {supplierId: supplierId}, searchParams));
    return $resource('/api/supplier/:supplierId/invoice', params, {
        'get': {method: 'GET', isArray: true}
      }
    );
  }

  function exportCsv(supplierId, searchParams) {
    var params = formatDates(searchParams);
    var q = _.map(params, function(v, k) { return encodeURIComponent(k) + "=" + encodeURIComponent(v); }).sort().join('&');
    var url = '/api/supplier/' + supplierId + '/invoice/export?' + q;
    $window.location.assign(url);
  }

  return {
    search: search,
    exportCsv: exportCsv
  };

});

app.directive('avSelectedMultipleProducts', function() {
	return {
		restrict: 'E',
		scope: {
			statusData:'=ngModel',
			datesModel:'=',
			role:'@'
		},
		templateUrl: 'availability/components/shared/selected-products/selected-multiple-products.html',
		controllerAs: 'ctrl',
		controller: function($scope, $element, $attrs) {
			if ($scope.role !== 'filter')
				_.each($scope.statusData, function(el) {el.selected = false;});

			$scope.isSelectionsExpanded = $scope.role == 'filter' ? false : true;

			$scope.generateProductHref = function(familyCode) {
		    return '/myproducts/view/' + familyCode + '?preview=true';
		  };

			$scope.toggleSelected = function(mProduct) {
				_.each($scope.datesModel, function(day) {
					_.each(day.products, function(product) {
						product.selected = product.productCode == mProduct.code ? mProduct.selected : product.selected;
					})
				});
			}

			$scope.expandSelections = function() {
				$scope.isSelectionsExpanded = $scope.isSelectionsExpanded ? false : true;
			}

			$scope.selectAll = function(val) {
				angular.forEach($scope.statusData, function(el) {
					el.selected = val;
				});
			}

			$scope.selectedProductsCount = function() {
				var res = 0;
				_.each($scope.statusData, function(el) {
					if (el.selected)
						res++;
				});
				return res;
			}

			$scope.getPublishedTypeGroupCss = function(publishType) {
				var type = typeof(publishType) !== 'undefined' ? publishType.toLowerCase() : '';
				if (type === 'honouring') {
					return 'fa-circle status-icon--red';
				} else if (type === 'published') {
					return 'fa-circle status-icon--green';
				} else {
					return '';
				}
			};
		},
	};
});

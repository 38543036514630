app.directive('avBlockoutContent', ['Availability', 'availabilityUtil', function (Availability, availabilityUtil) {
  return {
    restrict: 'E',
    scope: {
      save: '&saveCallback',
      model: '=ngModel',
      statusData: '='
    },
    templateUrl: 'availability/components/editor/content-panel/addblockoutContent/blockoutContent.html',
    controllerAs: 'ctrl',
    controller: function ($scope, $element, $attrs, $routeParams, $location) {
      $scope.blockdateoption = 0;

      $scope.dateSelectOptions = [
        { model: "-- Select --", mValue: 0 },
        { model: "Add by date range", mValue:1 },
        { model: "Add recurring dates", mValue: 2 }
      ];

      $scope.productsParam = $routeParams.productcode;
      $scope.products = _.filter(
        _.map(Availability.getSelectedProductCodes(), function (productCode) {
          return {
            productId: Availability.findIdByCode(productCode),
            selected: Availability.isSelected(productCode),
            productCode: productCode,
            productName: Availability.findNameByCode(productCode),
            blockouts: []
          };
        }), function (blockout) {
          return blockout.selected == true;
        }
      );
      $scope.filter = {
        fromDate: undefined,
        toDate: undefined,
        frequencyPeriod: undefined,
        frequencyMultiplier: undefined,
        selectedDays: []
      };
      var handleResponseError = function (error) {
        var productName = error.productName;
        var productCode = error.productCode;

        _.each(error.errorMessage, function (errorMessage) {
          var message = productName + ' (' + productCode + ') : ' + errorMessage;
          $scope.errors.push(message);
        })
      };

      var handleMappingConflict = function (error) {
        _.each(error, function (errorMessage) {
          $scope.errors.push(errorMessage);
        })
      };

      $scope.daysSelected = false;

      $scope.errors = [];

      $scope.model.push(availabilityUtil.getNewTimeSlot(availabilityUtil.getNewTimeSlotId($scope.model)));

      $scope.isButtonDisabled = function () {
        return $scope.buttonDisabled || !$scope.daysSelected;
      };


      $scope.model = _.map($scope.model, function (product) {
        return _.extend(product, { hasError: false });
      });

      // function to remove products that contain the toDelete objects given
      // that everything is
      function removeToDeleteProducts(products) {
        return _.filter(products, function (product) {
          return product.toDelete ? '' : product;
        });
      }

      $scope.$watch('blockdateoption', function (newVal, oldVal)
      {
        //if (!newVal && !oldVal) return;

        //displaySelectedCountryName(newVal);
        $scope.filter = {
          fromDate: undefined,
          toDate: undefined,
          frequencyPeriod: undefined,
          frequencyMultiplier: undefined,
          selectedDays: []
        };
      });


      $scope.$watch('model', function (products) {
        // check for toDelete object as they were staying in memory and causing
        // issues with the disable checks for save button
        products = removeToDeleteProducts(products);
        $scope.buttonDisabled = _.reduce(products, function (reducer, product) {
          return !reducer ? product.hasError : reducer;
        }, false);
      }, true)

      $scope.productsParam = $routeParams.productcode;
      $scope.filter = {
        fromDate: undefined,
        toDate: undefined,
        frequencyPeriod: undefined,
        frequencyMultiplier: undefined,
        selectedDays: []
      };



      $scope.resetErrorArray = function () {
        $scope.errors = [];
      };

      $scope.onFail = function (response) {
        console.error('failed with response:', response);
        //This error is thrown when a session is added for a product
        // that is already mapped to another provider (e.g: Rezdy,Livn etc)
        if (response.status === 409) {
          handleMappingConflict(response.data);
        }
      };

      $scope.dayOfWeekOptions = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

      $scope.addBlockoutDateToProduct = function (productCode) {
        var product = _.findWhere($scope.products, { productCode: productCode });
        if (product)
          product.blockouts.push(availabilityUtil.getNewBlockout(availabilityUtil.getNewTimeSlotId(product.blockouts)));
      };

      $scope.onChangeBlockDateOption = function (thise) {

      };

      $scope.saveBlockoutDates = function () {
        $scope.resetErrorArray();
        $scope.filter.selectedDays = availabilityUtil.syncUserSelectedDays($scope, "addBulk", $scope.filter.userSelectedDays); // 'addBulk'
        //$scope.save({
        //  times: $scope.model,
        //  filter: $scope.filter,
        //  onFail: $scope.onFail,
        //  statusData: $scope.statusData
        //});
        $scope.save({
          blockoutRange: $scope.blockdateoption,
          filter: $scope.filter,
          onFail: $scope.onFail,
          statusData: $scope.statusData
        });
      }
    }
  };
}]);

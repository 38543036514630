app.controller('CompanyAgencyAgreementCtrl', ['$rootScope', '$routeParams', 'CompanyAgencyAgreementUtils', 'supplierContracts',
  function ($rootScope, $routeParams, CompanyAgencyAgreementUtils, supplierContracts) {
  var self = this;

  self.utils = CompanyAgencyAgreementUtils;
  self.supplierContracts = _.map(supplierContracts.data, function (instance) {
    var formattedInstance = _.extend({}, instance.contract, instance.acceptance || { accepted: false });

    return _.extend(formattedInstance, {
      signedByLabel: self.utils.signedBy(formattedInstance),
      dateSignedLabel: self.utils.dateSigned(formattedInstance)
    });
  });

  self.isSubmitted = function() {
    return $routeParams.submitted;
  };
}]);

app.directive('singleTimeslot', function() {
	return {
		restrict: 'E',
		scope: {
			model: '=ngModel',
			productCode: '=',
			rmTimeslot: '&rmTimeslotFn',
			date: '=',
			inSingleEdit: '@',
      product: '='
		},
		templateUrl: 'availability/components/editor/content-panel/timeslot/single-timeslot.html',
		controllerAs: 'ctrl',
		controller: function($scope, $element, $attrs, $routeParams, $location, $window) {
      // Set default false for hasChanged
      $scope.model.hasChanged = false;

			$scope.hstep = 1;
      $scope.mstep = 15;
      $scope.tpStart = $scope.model.start;
      $scope.tpEnd = $scope.model.end;

      var flagReset = true;

      $scope.hasInvalidStartAndEndTimes = function() {
        return moment($scope.tpEnd).isSameOrBefore(moment($scope.tpStart));
      };

      var validate = function() {
        $scope.model.hasError = $scope.hasInvalidStartAndEndTimes() || $scope.isMaxCapacityGreaterThanBookings();
      };

			$scope.$watch('tpStart', function(newValue, oldValue) {
				$scope.startDisplay = $scope.updateStartDisplay();
				$scope.model.start = moment($scope.tpStart);
			});

			$scope.$watch('tpEnd', function(newValue, oldValue) {
				$scope.endDisplay = $scope.updateEndDisplay();
				$scope.model.end = moment($scope.tpEnd);
			});

      // This watch function is used to add a flag on model(s)
      // that have changed/when a user has edited time(s) or max capacity
      $scope.$watchCollection('model', function (newValue, oldValue) {
        if (!_.isMatch(newValue, oldValue)) {
          $scope.model.hasChanged = true;
        }
      });

      $scope.updateStartDisplay = function() {
        validate();
        return moment($scope.tpStart).format('hh:mm a');
      };

      $scope.updateEndDisplay = function() {
        validate();
        return moment($scope.tpEnd).format('hh:mm a');
      };

			$scope.repeater = $attrs.repeater ? $attrs.repeater : false;
			$scope.showRepeater = false;
			if (!$scope.model.repeat) {
				$scope.model.repeat = {
					fromDate: undefined,
					toDate: undefined,
					frequencyPeriod: undefined,
					frequencyMultiplier: undefined,
					selectedDays: []
				};
			}

			$scope.markDelete = function(mode) {
        if (mode){
          var toDeleteData = {
            "productCode": $scope.product ? $scope.product.productCode : null,
            "productName": $scope.product ? $scope.product.productName : null,
            "session": {
              "id": $scope.model.id,
              "startTime": moment($scope.model.start).format('HH:mm'),
              "endTime": moment($scope.model.end).format('HH:mm'),
              "maxCapacity": $scope.model.maxCapacity,
              "date": $scope.date
            }
          };
          $scope.model.toDelete = toDeleteData;
        } else {
          delete $scope.model.toDelete;
        }
      };

      $scope.toDelete = function(){
        $scope.model.isMarkDeletedSelected = !$scope.model.isMarkDeletedSelected;
        $scope.markDelete($scope.model.isMarkDeletedSelected);
      };

      $scope.$watch('model.isMarkDeletedSelected', function (newData) {
        $scope.markDelete(newData);
      });

      $scope.hasBookingsCount = function() {
        // This checks bookingCount and returns a new boolean instance
        // Boolean(1) will return true
        // Boolean(0) will return false
        return Boolean($scope.model.bookingCount);
      };

      var TIMEOUT = 5000;
      var messageTimeout = null;

      $scope.errors = [];

      $scope.pushErrorMessage = function(msg) {
        if (!_.contains($scope.errors, msg)) {
          $window.clearTimeout(messageTimeout);
          $scope.errors.push(msg);
          messageTimeout = $window.setTimeout($scope.clearErrorMessages, TIMEOUT);
        }
      };

      $scope.clearErrorMessages = function() {
        $scope.errors = [];
        $scope.$apply();
      };

      $scope.handleClick = function() {
        if ($scope.hasBookingsCount()) {
          $scope.pushErrorMessage("Can't change date when bookings are already present");
        }
      };

			$scope.isMaxCapacityGreaterThanBookings = function() {
				return !$scope.model.maxCapacity || $scope.model.maxCapacity <= 0 || $scope.model.maxCapacity < $scope.model.bookingCount;
			};

      $scope.handleCapacityChange = function() {
				validate();
        // This is to prevent users from reducing the max capacity if bookings exists
        // while displaying an error message
        // It will also set max capacity to equal booking count to prevent from
        // choosing a number lower than the booking count
        if ($scope.model.bookingCount && $scope.model.maxCapacity < $scope.model.bookingCount) {
          $scope.model.maxCapacity = $scope.model.bookingCount;
          $scope.pushErrorMessage("Can't set capacity lower than bookings");
        }
      };

      $scope.toggleStartDropdown = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.isStartOpen = !$scope.isStartOpen;
      };

      $scope.toggleEndDisplay = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.isEndOpen = !$scope.isEndOpen;
      };

		}
	};
});

app.directive('availabilityFilter', function() {
  return {
    scope: {
      model: '=ngModel',
      show: '=ngShow',
      daysSelected: '='
    },
    restrict: 'E',
    templateUrl: 'availability/components/shared/filter/availability-filter.html',
    controllerAs: 'ctrl',
    controller: function($scope, $element, $attrs, $rootScope, availabilityUtil) {
      var ctrl = this;

      $scope.model.dateFormat = 'YYYY-MM-DD';

      $scope.$watchCollection('model.userSelectedDays', function(newValue) {
        // Check in userSelectedDays array to see if true is inside
        // Don't change daysSelected when page is on edit bulk date
        if ($attrs.customiseFor !== 'editBulk') {
          $scope.daysSelected = newValue.indexOf(true) !== -1;
        }
      });

      $scope.isDateValidate = function () {      

        if (($scope.model.fromDate > $scope.model.toDate) && ($attrs.customiseFor === 'addBlockoutDateRange' || $attrs.customiseFor === 'addBlockoutReccuringDates')) {
          return true;
        }
        else
          return false;       
      };

      $scope.isErrorMessageVisible = function () {
        return !($attrs.customiseFor === 'editBulk' || $scope.daysSelected);
      };


      ctrl.datePickerOptions = {
        changeYear: true,
        changeMonth: true,
        yearRange: "c-0:+10",
        maxDate: '+1Y',
        init: moment().format($scope.model.dateFormat)
      };

      ctrl.showCalendar = function($event) {
        $($event.currentTarget.parentNode).find('input').focus();
      }

      // TODO(mh): also set integration method based off this attr
      // customise based on customise-for attribute
      ctrl.showCancelRepeat = false;
      ctrl.showSummary = false;
      ctrl.showResults = false;
      ctrl.showFilterDays = true;
      ctrl.errorMessage = "";

      if ($attrs.customiseFor === 'repeat') {
        ctrl.showCancelRepeat = true;
        ctrl.showSummary = true;
      } else if ($attrs.customiseFor === 'editBulk') {
        ctrl.showResults = true;
      } else if ($attrs.customiseFor === 'addBulk') {
      }
      else if ($attrs.customiseFor === 'addBlockoutDateRange') {
        ctrl.showFilterDays = false;        
      }
      else if ($attrs.customiseFor === 'addBlockoutReccuringDates') {
        ctrl.showFilterDays =  true ;
      }

      // input field options
      ctrl.dayOfWeekOptions = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      ctrl.dayOfWeekNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      ctrl.frequencyPeriodOptions = ['Weekly', 'Monthly'];
      ctrl.getFrequencyMultiplierOptions = function() {
        var options = [];
        if (ctrl.frequencyPeriod === 'Weekly') {
            options = ['week', '2 weeks', '3 weeks']
        } else if (ctrl.frequencyPeriod === 'Monthly') {
          options = ['month', '2 months', '3 months']
        }
        return options;
      };

      // model
      var currentDate = new Date(); // default from/to date inputs to the current date

      $scope.model.fromDate = currentDate;
      $scope.model.toDate = currentDate;
      $scope.model.frequencyPeriod = '';
      $scope.model.frequencyMultiplier = '';
      if (ctrl.showResults) {
        // edit defaults user selected days to off
        $scope.model.userSelectedDays = _.times(7, _.constant(false));
      } else {
        // add defaults user selected days to off
        $scope.model.userSelectedDays = _.times(7, _.constant(false));
      }

      // build summary string for selected options
      ctrl.generateSummary = function() {
        var summary = '';

        // 'Every 2 weeks on '
        if ($scope.model.frequencyPeriod && $scope.model.frequencyMultiplier) {
          summary += 'Every ' + $scope.model.frequencyMultiplier;
        }
        // 'on Wednesday, Friday, '
        if (_.find($scope.model.selectedDays, function(i) { return i })) {
          summary += summary ? ' on ' : 'on ';
        }
        _.each($scope.model.selectedDays, function(selected, i) {
          if (selected) {
            summary += ctrl.dayOfWeekNames[i] + ', '
          }
        });
        // 'from 25th May 2016 until until 25th June 2016'
        summary += summary ? ' from ' : 'from ';
        summary += inputToDisplayDate($scope.model.fromDate) + ' until ' + inputToDisplayDate($scope.model.toDate);

        // capitalise
        return summary[0].toUpperCase() + summary.slice(1);
      };

      // convert input date (eg 23/05/2016) to display date (eg 23rd May 2016)
      var inputToDisplayDate = function(input) {
        return moment(input).format('Do MMMM YYYY');
      }

      var searchCallBack = function(success, data) {
        $rootScope.manage_availability_show_loading = false;
        if(!success) {
          console.log(data);
        }
      }

      $scope.filterBulkDates = function() {

        $scope.model.selectedDays = availabilityUtil.syncUserSelectedDays($scope, $attrs.customiseFor, $scope.model.userSelectedDays); // 'editBulk'

        var options = {
          fromStr: moment($scope.model.fromDate).format($scope.model.dateFormat),
          toStr: moment($scope.model.toDate).format($scope.model.dateFormat)
        }
        $rootScope.manage_availability_show_loading = true;
        availabilityUtil.loadBulkDates(options, searchCallBack);
      }
    },
  };
});

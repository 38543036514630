/**
 * productPanel
 * -----------------------------
 * @author Curtis McMullan <curtis.mcmullan@redballoon.com.au>
 */

(function () {

  angular
    .module('app')
    .directive('productPanel', function () {
      return {
        scope: {
          id: '=',
          name: '=',
          panelTooltip: '=',
          template: '=',
          controller: '=',
          editing: '=',
          editCallback: '&',
          productType: '=',
          disable: '='
        },
        restrict: 'E',
        transclude: true,
        controller: 'productPanelController',
        templateUrl: 'products/product/product-panel.template.html'
      }
    });

})();

// this model is a wrapper for the angular logging which adds logging to logentries.
// logentries still needs to be configured, see supplier/log-config.js
// needs these bower dependencies ["logentries": "*", "stacktrace-js": "~0.6.2"]

angular.module('rb-logentries', [])
.config(['$provide', function($provide){
  $provide.decorator('$log', ['$delegate', function($delegate){

    var _log = $delegate.log;
    $delegate.log = function(){
      try {
        LE.log(arguments);
      } catch (e) {
        // do nothing
      }
      return _log(arguments);
    };
    _.extend($delegate.log, _log);

    var _info = $delegate.info;
    $delegate.info = function() {
      try {
        LE.info(arguments);
      } catch (e) {
        // do nothing
      }
      return _info(arguments);
    };
    _.extend($delegate.info, _info);

    var _warn = $delegate.warn;
    $delegate.warn = function(){
      try {
        LE.warn(arguments);
      } catch (e) {
        // do nothing
      }
      return _warn(arguments);
    };
    _.extend($delegate.warn, _warn);

    var _error = $delegate.error;
    $delegate.error = function(){
      try {
        LE.error(arguments);
      } catch (e) {
        // do nothing
      }
      return _error(arguments);
    };
    _.extend($delegate.error, _error);

    return $delegate;
  }]);
}])

.factory('errorHttpInterceptor', ['$q', function ($q) {
  var blacklist401Urls = ['/api/profile', '/api/auth/login'];

  function logRejection(rejection){
    var logAtLevel = LE.error;
    if (rejection.status === 401 || rejection.status === 409){
      if (_.contains(blacklist401Urls, rejection.config.url)){
        // ignore
        return;
      }
      logAtLevel = LE.warn;
    }
    logAtLevel({
      msg: 'unexpected http error',
      config: rejection.config,
      status: rejection.status
    });
  }

  return {
    responseError: function responseError(rejection) {
      try {
        logRejection(rejection);
      } catch (e) {
        // do nothing
      }
      return $q.reject(rejection);
    }
  };
}])

.config(['$httpProvider', function($httpProvider) {
  $httpProvider.interceptors.push('errorHttpInterceptor');
}]);

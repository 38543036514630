app.service('availabilityUtil', ['Availability', 'arrayToParamFilter', '$rootScope', function (Availability, arrayToParamFilter, $rootScope) {
  return {
    // given a date, return an empty date model
    getEmptyModel: function (date) {
      return {
        date: date,
        products: _.map(Availability.getSelectedProductCodes(), function (productCode) {
          return {
            selected: Availability.isSelected(productCode),
            productCode: productCode,
            productName: Availability.findNameByCode(productCode),
            times: []
          };
        })
      }
    },

    // Return a new timeslot of ID id
    getNewTimeSlot: function (id) {
      return {
        new: true,
        id: id,
        start: moment().hour(0).minute(0),
        end: moment().hour(0).minute(0),
        allDay: false,
        maxCapacity: 1,
        bookingCount: 0,
        status: 'Available',
        location: {
          Name: "NSW",
          countryCode: "AU",
          id: 1
        },
        // Can probably remove this bit
        repeat: {
          fromDate: "",
          toDate: "",
          frequencyPeriod: "",
          frequencyMultiplier: "",
          selectedDays: []
        }
      }
    },

    // Return a new single blockout
    getNewBlockout: function (id) {
      return {
        new: true,
        id: id,
        date: new Date(),
        mode: 0,
        weekly: {}
      }
    },

    // return an valid id for a new timeslot
    getNewTimeSlotId: function (timesArray) {
      return timesArray.length == 0 ? 0 : _.sortBy(timesArray, 'id')[timesArray.length - 1].id + 1;
    },

    copyDay: function (destDay, dateString) {
      var day = _.findWhere(Availability.getModel(), { date: dateString });
      (typeof day === 'undefined') ? angular.copy(this.getEmptyModel(dateString), destDay) : angular.copy(day, destDay);
    },

    loadNewDates: function (codesParam, fromStr, toStr, callBack) {
      var promiseBlockout = Availability.getBlockoutDatesFromDatesPromise(codesParam, fromStr, moment(toStr).add(14, 'days').format('YYYY-MM-DD'));
      promiseBlockout.then(function (responseBlockout) {
        var promise = Availability.getFromDatesPromise(codesParam, fromStr, moment(toStr).add(14, 'days').format('YYYY-MM-DD'));
        promise.then(function (response) {
          Availability.formatDateResponse(Availability.getModel(), response, responseBlockout);
        });
        callBack();
      });
    },

    // sync the options the user has chose with the days seleted to filter and add
    // when filtering if no userSelectedDays selected then it counts as all days
    // otherwise just show the userSelectedDays in the time range
    syncUserSelectedDays: function (scope, customiseFor, userSelectedDays) {
      var selectedDays,
        userSelectedDaysArray;
      selectedDays = _.times(7, _.constant(true));
      if (userSelectedDays == undefined || _.uniq(_.toArray(userSelectedDays)).toString() == "false") {
        userSelectedDaysArray = _.times(7, _.constant(true));
      } else {
        userSelectedDaysArray = _.times(7, _.constant(false));
        _.each(userSelectedDays, function (_value, _name) {
          userSelectedDaysArray[_name] = _value;
        });
      }
      selectedDays = _.map(selectedDays, function (value, index) {
        if (userSelectedDaysArray[index] === false) {
          return false;
        } else {
          return true;
        }
      });
      return selectedDays;
    },

    loadBulkDates: function (options, callBack) {
      Availability.getBulkDates().length = 0;
      var codesArray = [];
      _.each(Availability.getStatusModel(), function (product) {
        if (product.selected)
          codesArray.push(product.code);
      });
      var codes = arrayToParamFilter(codesArray, 'code');
       
      var promiseBlockout = Availability.getBlockoutDatesFromDatesPromise(codes, options.fromStr, options.toStr);
      promiseBlockout.then(function (responseBlockout) {
        var promise = Availability.getFromDatesPromise(codes, options.fromStr, options.toStr);
        promise.then(function (response) {
          callBack(true);
          return Availability.formatDateResponse(Availability.getBulkDates(), response, responseBlockout);
        }, function (response) {
          callBack(false, response);
        });
      });
    },

    //var promise = Availability.getFromDatesPromise(codes, options.fromStr, options.toStr);
    //promise.then(function (response) {
    //  callBack(true);
    //  return Availability.formatDateResponse(Availability.getBulkDates(), response);
    //}, function (response) {
    //  callBack(false, response);
    //});

    loadBulkBlockoutDates: function (options, callBack) {
      var codesArray = [];
      _.each(Availability.getStatusModel(), function (product) {
        if (product.selected)
          codesArray.push(product.code);
      });
      var codes = arrayToParamFilter(codesArray, 'code');
      var promise = Availability.getBlockoutDatesFromDatesPromise(codes, options.fromStr, options.toStr);
      promise.then(function (response) {
        callBack(true);
        return Availability.formatBlockoutDateModelResponse(Availability.getBlockoutDates(), response);
      }, function (response) {
        callBack(false, response);
      });
    },

    isProductSelected: function (code) {
      return Availability.isSelected(code);
    },

    deleteSessionById: function (params, callBack, toDeleteObject) {
      Availability.deleteSessionPromise(params.id, toDeleteObject)
        .then(function success(response) {
          callBack(params);
        });
    },
    updateSessions: function (params, onSuccess, onFail) {
      Availability.UpdateSessionsResource()
        .save({}, params.body)
        .$promise
        .then(function success(response) {
          onSuccess(params);
        }, function fail(response) {
          onFail(response);
        });
    },
    insertBlockouts: function (params, onSuccess, onFail) {
      Availability.BlockOutSessionsResource(params, $rootScope.selectedSupplier.id)
        .save({}, params.body)
        .$promise
        .then(function success(response) {
          onSuccess(params);
        }, function fail(response) {
          onFail(response);
        });
    },
    deleteBlockouts: function (params, onSuccess, onFail) {
      Availability.BlockOutSessionsResource(params, $rootScope.selectedSupplier.id)
        .delete({}, params.body)
        .$promise
        .then(function success(response) {
          onSuccess(params);
        }, function fail(response) {
          onFail(response);
        });
    },
    updateBlockouts: function (params, onSuccess, onFail) {
      Availability.BlockOutSessionsResource(params, $rootScope.selectedSupplier.id)
        .update({}, params.body)
        .$promise
        .then(function success(response) {
        }, function fail(response) {
          onFail(response);
        });
    }

    //insertBlockouts: function (params, onSuccess, onFail) {
    //  Availability.UpdateBlockoutsResource($rootScope.selectedSupplier.id)
    //    .save({}, params)
    //    .$promise
    //    .then(function success(response) {
    //      onSuccess(params);
    //    }, function fail(response) {
    //      onFail(response);
    //    });
    //},
    //updateBlockouts: function (params, onSuccess, onFail) {
    //  Availability.UpdateBlockoutsResource($rootScope.selectedSupplier.id)
    //    .update({}, params)
    //    .$promise
    //    .then(function success(response) {
    //      onSuccess(params);
    //    }, function fail(response) {
    //      onFail(response);
    //    });
    //},
    //deleteBlockoutDate: function (params, callBack) {
    //  Availability.deleteBlockoutDates(params.body, $rootScope.selectedSupplier.id)
    //    .then(function success(response) {
    //      callBack(params);
    //    }, function fail(response) {
    //      onFail(response);
    //    });
    //}
  };
}]);

app.directive('avEditor', ['availabilityUtil', function () {
  return {
    restrict: 'E',
    scope: {
      model: '=ngModel',
      statusData: '=avStatus',
      month: '=avMonth'
    },
    templateUrl: 'availability/components/editor/editor.html',
    controllerAs: 'ctrl',
    controller: avEditorCtrl,
  };
}]);

function avEditorCtrl($scope, $routeParams, $rootScope, $location, availabilityUtil, paramToArrayFilter) {
  var ctrl = this;

  $scope.multiple = $scope.statusData.length > 1 && !$routeParams.id ? true : false;
  $scope.productParam = $routeParams.productcode;
  $scope.addBulkDateModel = [];
  $scope.addBlockDateModel = [];
  $scope.addBlockingDateModel = [];

  var stopLoadingSpinner = function () {
    $rootScope.manage_availability_show_loading = false;
  };

  var saveFailCallback = function (response) {
    console.log('saveFailCallback response is ', response);
    stopLoadingSpinner();
  };

  // TODO: reload data from server
  var saveCallback = function (params) {
    var fromDate = (params.date) ? moment(params.date).startOf('month').startOf('week') : moment().startOf('month').startOf('week');
    var toDate = (params.date) ? moment(params.date).endOf('month').endOf('week') : moment().endOf('month').endOf('week');
    var view = (params.view) ? params.view : "calendar";
    availabilityUtil.loadNewDates($scope.productParam, fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), stopLoadingSpinner);
    $location.path('/myproducts/availability/manager/' + $scope.productParam + '/' + view);
  };

  var checkHasChangedFlag = function (session) {
    if (!session.hasChanged) {
      session.hasChanged = false;
    }
  };

  $scope.addBlockOutDates = function (blockoutRange, filter, onFail, statusData) {
    console.log('statusData is', statusData);
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    if (filter.fromDate > filter.toDate) {
      return false;
    }

    var repeat = [];

    if (blockoutRange == "2") {  // display only for recurring dates     
      _.each(filter.selectedDays, function (el, idx) {
        if (el)
          repeat.push(days[idx]);
      });
    }

    var dateRange = {
      from: moment(filter.fromDate).format('YYYY-MM-DD'),
      to: moment(filter.toDate).format('YYYY-MM-DD')
    };
    var day = {
      date: moment().month($scope.month.month).format('YYYY-MM-DD'),
      products: []
    };
    var productCodes = paramToArrayFilter($scope.productParam);
    _.each(productCodes, function (code) {
      if (availabilityUtil.isProductSelected(code))
        day.products.push({
          productCode: code,
          isBlockout:true
        });
    });
    $scope.saveDayToBlocksOutModel(day, day.date, 'calendar', repeat, dateRange, onFail, statusData);
  }

  $scope.deleteDayToBlocksOutModel = function (day, dateString, view, repeat, dateRange, onFail, statusData) {
    var handleXHRFail = function (response) {
      if (onFail) onFail(response);
      saveFailCallback(response);
    };
    var toDelete = [];
    var params = {
      body: {
        products: [],
        dateRange: {
          from: dateString,
          to: dateString
        }
      },
      day: day,
      date: dateString,
      view: view
    };

    day.products = _.map(day.products, function (product) {
      if (statusData) {
        var item = _.find(statusData, function (statusItem) { return statusItem.code === product.productCode });
        product.id = item ? item.id : 0;
      }
      return product;
    });

    _.each(day.products, function (product) {
      if (availabilityUtil.isProductSelected(product.productCode) && !product.isBlockout && product.status == "Blockout")
          params.body.products.push(product.id);
    });

    params.body.repeat = repeat;

    if (dateRange) {
      params.body.dateRange = dateRange;
    }

    if (toDelete.length > 0) {
      params.body.toBeDeletedSessions = toDelete;
    }

    $rootScope.manage_availability_show_loading = true;

    availabilityUtil.deleteBlockouts(params, saveCallback, handleXHRFail);
  };

  $scope.saveDayToBlocksOutModel = function (day, dateString, view, repeat, dateRange, onFail, statusData) {
    var handleXHRFail = function (response) {
      if (onFail) onFail(response);
      saveFailCallback(response);
    };
    var toDelete = [];
    var params = {
      body: {
        products: [],
        dateRange: {
          from: dateString,
          to: dateString
        }
      },
      day: day,
      date: dateString,
      view: view
    };

    day.products = _.map(day.products, function (product) {
      if (statusData) {
        var item = _.find(statusData, function (statusItem) { return statusItem.code === product.productCode });
        product.id = item ? item.id : 0;
      }
      return product;
    });

    _.each(day.products, function (product) {
      if (availabilityUtil.isProductSelected(product.productCode) && product.isBlockout && product.status != "Blockout")
          params.body.products.push(product.id);
    });

    params.body.repeat = repeat;

    if (dateRange) {
      params.body.dateRange = dateRange;
    }

    if (toDelete.length > 0) {
      params.body.toBeDeletedSessions = toDelete;
    }

    $rootScope.manage_availability_show_loading = true;

    availabilityUtil.insertBlockouts(params, saveCallback, handleXHRFail);
  };



  $scope.saveDayToModel = function (day, dateString, view, repeat, dateRange, onFail, statusData) {
    var handleXHRFail = function (response) {
      if (onFail) onFail(response);
      saveFailCallback(response);
    };
    var toDelete = [];
    var params = {
      body: {
        products: [],
        dateRange: {
          from: dateString,
          to: dateString
        }
      },
      day: day,
      date: dateString,
      view: view
    };

    day.products = _.map(day.products, function (product) {
      if (statusData) {
        var item = _.find(statusData, function (statusItem) { return statusItem.code === product.productCode });
        product.productName = item ? item.name || item.productName : '';
      }
      return product;
    });

    var saveblockout = day.products.some(function (product) {
      return (product.isBlockout && product.status != "Blockout");
    });

    var removeblockout = day.products.some(function (product) {
      return (!product.isBlockout && product.status == "Blockout");
    });

    if (saveblockout) {
      var repeatProduct = [];
      $scope.saveDayToBlocksOutModel(day, day.date, 'calendar', repeatProduct, dateRange, onFail, statusData);
    }
    if (removeblockout) {
      var repeatProduct = [];
      $scope.deleteDayToBlocksOutModel(day, day.date, 'calendar', repeatProduct, dateRange, onFail, statusData);
    }

    _.each(day.products, function (product) {
      if (availabilityUtil.isProductSelected(product.productCode)) {
        _.each(product.times, function (session) {

          checkHasChangedFlag(session);

          if (session.toDelete && !session.new) {
            toDelete.push(session.toDelete);
          } else if (!session.toDelete) {
            var sessionObj = {
              startTime: moment(session.start).format('HH:mm'),
              endTime: moment(session.end).format('HH:mm'),
              maxCapacity: session.maxCapacity,
              hasChanged: session.hasChanged
            };

            if (!session.new) {
              sessionObj.id = session.id; // no ID means insert
            }
            params.body.products.push({
              productCode: product.productCode,
              productName: product.productName,
              session: sessionObj
            });
          }
        });
      }
    });

    if (repeat) {
      if (repeat.repeatOn.length > 0) {
        params.body.repeat = repeat;
      }
    }

    if (dateRange) {
      params.body.dateRange = dateRange;
    }

    if (toDelete.length > 0) {
      params.body.toBeDeletedSessions = toDelete;
    }

    $rootScope.manage_availability_show_loading = true;

    availabilityUtil.updateSessions(params, saveCallback, handleXHRFail);

  };

  // return true if there is timeslots in product
  var isTimesinProducts = function (productsArray) {
    var res = 0;
    for (var i = 0; i < productsArray.length; i++) {
      res += productsArray[i].times.length;
    }
    return res > 0 ? true : false;
  }

  // Return true if day exists in model
  var isDayInModel = function (dateString) {
    return (typeof _.findWhere($scope.model, { date: dateString }) === 'undefined') ? false : true;
  }





  $scope.addBulkDates = function (sessions, filter, onFail, statusData) {
    console.log('statusData is', statusData);
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    var repeat = {
      repeatOn: []
    };
    _.each(filter.selectedDays, function (el, idx) {
      if (el)
        repeat.repeatOn.push(days[idx]);
    });
    var dateRange = {
      from: moment(filter.fromDate).format('YYYY-MM-DD'),
      to: moment(filter.toDate).format('YYYY-MM-DD')
    };
    var day = {
      date: moment().month($scope.month.month).format('YYYY-MM-DD'),
      products: []
    };
    var productCodes = paramToArrayFilter($scope.productParam);
    _.each(productCodes, function (code) {
      if (availabilityUtil.isProductSelected(code))
        day.products.push({
          productCode: code,
          times: sessions
        });
    });
    $scope.saveDayToModel(day, day.date, 'calendar', repeat, dateRange, onFail, statusData);
  }

  $scope.dayOfWeekNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  $scope.addBlockDates = function (data, onFail) {
    var handleXHRFail = function (response) {
      if (onFail) onFail(response);
      stopLoadingSpinner();
    };
    var payLoad = [];
    _.each(data, function (product) {
      _.each(product.blockouts, function (bodData) {
        if (bodData.mode) {
          var weeklyDays = undefined;

          if (bodData.mode.id == 2) {
            weeklyDays = {};
            _.chain(bodData.daysWeeklys).each(function (value, key) {
              weeklyDays[$scope.dayOfWeekNames[key]] = value;
            })
          }

          var date = (!isNaN(bodData.date.getTime()) && bodData.mode.id == 1) ? moment(bodData.date).format("YYYY-MM-DD") : undefined;
          var blockout = {
            productFamilyId: parseInt(product.productId),
            date: date,
            weeklyDays: weeklyDays
          };
          payLoad.push(blockout);
        }
      })
    });
    availabilityUtil.insertBlockouts(payLoad, saveCallback, handleXHRFail);
  };

  $scope.editBlockDates = function (data, onFail) {
    var toDelete = [];
    var blockouts = [];

    var handleXHRFail = function (response) {
      if (onFail) onFail(response);
      stopLoadingSpinner();
    };

    _.each(data, function (product) {
      _.each(product.blockouts, function (bodData) {
        if (bodData.toDelete && !bodData.new) {
          toDelete.push(bodData.toDelete);
        } else {
          if (bodData.mode) {
            var weeklyDays = undefined;
            if (bodData.mode.id == 2) {
              weeklyDays = {};
              _.chain(bodData.daysWeeklys).each(function (value, key) {
                weeklyDays[$scope.dayOfWeekNames[key]] = value;
              })
            }

            var date = (!isNaN(bodData.date.getTime()) && bodData.mode.id == 1) ? moment(bodData.date).format("YYYY-MM-DD") : undefined;
            var blockout = {
              id: bodData.id,
              productFamilyId: parseInt(product.productId),
              date: date,
              weeklyDays: weeklyDays
            };
            blockouts.push(blockout);
          }
        }
      });
    });

    var deleteFunction = function () {
      availabilityUtil.deleteBlockouts(toDelete, saveCallback, handleXHRFail);
    };

    var updateFunction = function (callBack) {
      availabilityUtil.updateBlockouts(payLoad, callBack, handleXHRFail);
    };

    if (blockouts.length > 0 && toDelete.length > 0) {
      updateFunction(deleteFunction);
    } else if (toDelete.length > 0) {
      deleteFunction();
    } else {
      updateFunction(saveCallback);
    }
  }

};

app.controller('AccountDetailsCtrl', ['$rootScope', '$modal', 'User', 'Auth', function($rootScope, $modal, User, Auth) {

  var self = this;
  self.accountDetails = angular.copy($rootScope.profile);
  self.password = {};
  self.days = _.range(1, 32);
  self.months = moment.monthsShort();
  self.years = _.range(moment().year() - 100, moment().year() - 11);
  self.genders = [ { name: 'Male', value: 'M' }, { name: 'Female', value: 'F' } ];
  self.countries = [ { name: 'Australia', value: 'AU'}, { name: 'New Zealand', value: 'NZ'} ];
  self.states = [ 'NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'NT', 'ACT' ];
  self.updateResult = null;
  self.changePasswordResult = null;

  self.tempBirthday = { day: '', month: '', year: '' };
  if (self.accountDetails.birthday) {
    var d = new Date(self.accountDetails.birthday);
    self.tempBirthday.day = d.getDate();
    self.tempBirthday.month = self.months[d.getMonth()];
    self.tempBirthday.year = d.getFullYear();
  }

  self.updateDetails = function(form) {

    var successHandler = function() { handleUpdateOk(form); };

    var errorHandler = function(response) {
      var status = response ? response.status : 500;
      switch (status) {
        case 400: handleUpdateUnauthorised(form); break;
        case 409: handleUpdateConflict(form);     break;
        default : handleUpdateError(form);        break;
      }
    };

    function handleUpdateOk() {
      self.updateResult = 'SUCCESS';
      form.$setPristine();
      self.accountDetails.password = null;
      $rootScope.profile = angular.copy(self.accountDetails);
    }

    function handleUpdateConflict() {
      self.updateResult = 'CONFLICT';
      form.$setPristine();
    }

    function handleUpdateError() {
      self.updateResult = 'FAILURE';
      form.$setPristine();
    }

    function handleUpdateUnauthorised() {
      self.updateResult = 'UNAUTHORISED';
      updateWithPassword(successHandler, errorHandler);
    }

    function openPasswordModal() {
      return $modal.open({
        templateUrl: 'account/enter-password-modal.html',
        controller: 'EnterPasswordModalCtrl as ctrl',
        resolve: { accountDetails: function() { return self.accountDetails; },
                   currentPasswordInvalid: function() { return self.updateResult === 'UNAUTHORISED'; } }
      });
    }

    function update() {
      var date = birthdayMoment(self.tempBirthday.year, self.tempBirthday.month, self.tempBirthday.day);
      self.accountDetails.birthday = date.isValid() ? date.format("YYYY-MM-DD") : undefined;
      User.profile().update(self.accountDetails).$promise.then(successHandler, errorHandler);
    }

    function updateWithPassword() {
      openPasswordModal().result.then(update);
    }

    if (self.accountDetails.email != $rootScope.profile.email) {
      updateWithPassword();
    } else {
      update();
    }

  };

  self.changePassword = function(form) {

    function handleChangePasswordResult(form, result) {
      form.$setPristine();
      self.changePasswordResult = result;
    }

    var passwords = {email: $rootScope.profile.email, currentPassword: self.password.oldPassword, newPassword: self.password.newPassword};
    Auth.updatePassword(passwords).
      success(function () {
        self.password = {};
        handleChangePasswordResult(form, 'SUCCESS');
      }).
      error(function (data, status) {
        handleChangePasswordResult(form, (status == 400) && (data.error == 'bad_credentials') ? 'UNAUTHORISED' : 'FAILURE');
      });

  };

  self.validateBirthdayWithDay = function(f, d) {
    return validateBirthday(f, self.tempBirthday.year, self.tempBirthday.month, d);
  };

  self.validateBirthdayWithMonth = function(f, m) {
    return validateBirthday(f, self.tempBirthday.year, m, self.tempBirthday.day);
  };

  self.validateBirthdayWithYear = function(f, y) {
    return validateBirthday(f, y, self.tempBirthday.month, self.tempBirthday.day);
  };

  function birthdayMoment(y, m, d) {
    return (y && m && d) ? moment(y + "-" + m + "-" + d, "YYYY-MMM-DD") : moment.invalid();
  }

  function validateBirthday(f, y, m, d) {
    if (y && m && d) {
      f.birthday.$setValidity('date', birthdayMoment(y, m, d).isValid());
    }
    return true;
  }

}]);

app.factory('Campaign', function($resource, $http) {
  
  function addWorkflow(body) {
    return $http.post('/workflow/campaign/products', body);
  }

  function getCampaigns() {
    return $http.get('/campaigns');
  }

  function getCampaignStatusPerProduct(campaignId, productId) {
    return $http.get('/campaign/' + campaignId + '/product/' + productId);
  }

  function getCampaignByProduct(productCode) {
    return $http.get('/api/productFamily/'+productCode+'/campaigns');
  }
  

  return {
    addWorkflow: addWorkflow,
    getCampaigns: getCampaigns,
    getCampaignStatusPerProduct: getCampaignStatusPerProduct,
    getCampaignByProduct: getCampaignByProduct,
  };
});

app.filter('paramToArray', function() {
	return function(codes) {
		return _.map(codes.split('&'), function(str) {
			return _.last(str.split('='));
		});
	};
});

app.filter('arrayToParam', function() {
	return function(array, name) {
		var str = '';
		for (var i = 0; i < array.length; i++) {
			str += name+'='+array[i];
			if (i + 1 < array.length)
				str += '&';
		}
		return str;
	};
});
/**
 * @author James LeBoeuf [james.leboeuf@redballoon.com.au]
 * @desc divider directive that can be used anywhere to add height & adds width of 100%
 * @example <divider height="50"></divider>
 */
angular
  .module('app')
  .directive('divider', divider);

function divider() {
  return {
    restrict: 'E',
    scope: {
      height: '@'
    },
    template: '<div ng-style="style"></div>',
    link: function(scope, e, attrs) {
      scope.style = {
        "height": attrs.height + 'px',
        "width": "100%"
      };
    }
  };
}

app.directive('reviewsNpsSummary', function() {
  return {
    restrict: 'E',
    scope: {
      model: '=ngModel'
    },
    templateUrl: 'reviews/components/nps-summary/reviews-nps-summary.html',
    controllerAs: 'ctrl',
    controller: function($scope, $element, $attrs) {
      var self = this;

      self.nps = $scope.model.nps;
      self.npsValues = [$scope.model.promotersPercent, $scope.model.passivesPercent, $scope.model.detractorsPercent];
      self.redBalloonBenchmark = 65;

      self.npsChart = {
        values: self.npsValues,
        colours: ['#25bc2c', '#e6a830', '#ed1c24'],
        labels:  ['Promoters', 'Passives', 'Detractors'],
        options: {
          segmentStrokeWidth: 4,
          percentageInnerCutout: 70,
          legendTemplate : "<div class=\"nps-doughnut__sidebar chart-sidebar\"><ul class=\"nps-doughnut__legend <%=name.toLowerCase()%>-legend\"><% for (var i=0; i<segments.length; i++){%><li><span style=\"background-color:<%=segments[i].fillColor%>\"></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul><div class=\"nps-doughnut__benchmark-summary\">RedBalloon <br>benchmark " + self.redBalloonBenchmark + "</div></div>"
        }
      }

    }
  };
});

app.factory('Directship', function ($resource, $window) {
  return {
    find: function (supplierId, bookingId) {
      return $resource('/api/supplier/:supplierId/booking/:bookingId', { supplierId: supplierId, bookingId: bookingId }, {
        'query': { method: 'GET' }
      }
      );
    },
    findAllDirectShip: function (supplierId, status, productCode, facilityId, sortBy, order, page, startDate, endDate, limit) {
      return $resource('/api/supplier/:id/directShip', { id: supplierId, status: status, productFilter: productCode, branchFilter: facilityId, sortBy: sortBy, order: order, page: page, startDate: startDate, endDate: endDate, limit: limit }, {
        'query': { method: 'GET', isArray: true }
      });
    },
    exportDirectShipCsv: function (supplierId, orderItemId) {
      var orderItemIds = '';

      _.each(orderItemId, function (val) {
        orderItemIds = (orderItemIds == '') ? ("orderItemId=" + val) : (orderItemIds + "&orderItemId=" + val)
      });

      var url = '/api/supplier/' + supplierId + '/directShips/exportCSV?' + orderItemIds;
      $window.location.assign(url);
    },
    exportDirectShipExcel: function (supplierId, orderItemId) {
      var orderItemIds = '';

      _.each(orderItemId, function (val) {
        orderItemIds = (orderItemIds == '') ? ("orderItemId=" + val) : (orderItemIds + "&orderItemId=" + val)
      });

      var url = '/api/supplier/' + supplierId + '/directShips/exportXLS?' + orderItemIds;
      $window.location.assign(url);
    },
    findDirectShipCounts: function (supplierId, productCode, facilityId) {
      return $resource('/api/supplier/:id/directShipCounts', { id: supplierId, productFilter: productCode, branchFilter: facilityId }, {
        'query': { method: 'GET' }
      }
      );
    },
    markAsSent: function (supplierId, orderItemId) {

      return $resource(
        '/api/supplier/:id/directShips/markAsSent',
        { id: supplierId, orderItemIds: orderItemId },
        {'update': { method: 'PUT' }}
      );
    },
    search: function (supplierId, orderNumber, name, sortBy, order, page, limit) {
      return $resource('/api/supplier/:id/directship/search', { id: supplierId, orderNumber: orderNumber, name: name, sortBy: sortBy, order: order, page: page, limit: limit }, {
          'query': { method: 'GET', isArray: true }
        }
      );
    },
    searchCount: function (supplierId, orderNumber, name) {
      return $resource('/api/supplier/:id/directship/searchCount', { id: supplierId, orderNumber: orderNumber, name: name }, {
        'query': { method: 'GET' }
      }
      );
    },
  };
});

app.directive('singleBlockout', function () {
  return {
    restrict: 'E',
    scope: {
      model: '=ngModel',
      productCode: '=',
      product: '=',
      inSingleEdit: '@'
    },
    templateUrl: 'availability/components/editor/content-panel/blockout/single-blockout.html',
    controllerAs: 'ctrl',
    controller: function($scope, $element, $attrs, $routeParams, $location, $window){

      $scope.dayOfWeekOptions = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      $scope.dayOfWeekNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

      $scope.modeOptions = [{id : 1, name : 'Dates'}, { id : 2, name : 'Weekly'}];
      var mode = ($scope.model.date) ? 1 : 2;
      $scope.model.mode = _.findWhere($scope.modeOptions, {id : mode});
      $scope.model.daysWeeklys = (mode == 2) ? _.map($scope.dayOfWeekNames, function(value, key){
        return $scope.model.weeklyDays[value] == true;
      }) : null;

      $scope.tpStart = $scope.model.date;

      $scope.$watch('tpStart', function(newValue, oldValue) {
        $scope.model.date = new Date($scope.tpStart);
      });

      $scope.toDelete = function () {
        var toDeleteData = {
          "id": $scope.model.id,
          "date": moment($scope.model.date).format("YYYY-MM-DD"),
          "weekly": $scope.model.weekly,
          "productFamilyId": parseInt($scope.product.productId)
        };
        $scope.model.toDelete = toDeleteData;
      }
    }
  }
});

app.controller('CompanyUserAccountsCtrl', ['$rootScope', '$modal', 'User', 'SupplierSelection', 'users', 'SupplierContactTypes', function($rootScope, $modal, User, SupplierSelection, users, SupplierContactTypes) {
  var self = this;
  self.users = users;
  self.contactTypes = [];
  self.labelsClassName = ["label-info", "label-danger", "label-warning", "label-success"];

  SupplierContactTypes.then(function(data){
    self.contactTypes = data;
  });

  self.getLabelClassName = function (key) {
    return self.labelsClassName[(key % self.labelsClassName.length)]
  };

  self.getKeyLabel = function (id) {
    return _.findKey(self.contactTypes, function(value){return value.id == id;});
  };

  function reloadUsers() {
    User.customerWithContactType($rootScope.selectedSupplier.id).query().$promise.then(function(users) {
        self.users = users;
      });
  }

  // reload profile whenever logged in user is updated
  function reloadProfile(updatedUserEmail) {
    if ($rootScope.profile.email == updatedUserEmail) {
      SupplierSelection.loadProfile();
    }
  }

  self.fullName = function(user) {
    return $.trim($.trim(user.customer.contact.firstName) + ' ' + $.trim(user.customer.contact.lastName));
  };

  self.openEditUserModal = function(user) {
    this.modalInstance = $modal.open({
      templateUrl: 'company/edit-user-modal.html',
      controller: 'ModalEditUserCtrl',
      windowClass: 'supplier-modal',
      backdrop: 'static', // This prevents clicking backdrop closing modal
      keyboard: true, // This allows pressing ESC key to close modal
      resolve: {
        user: function() { return angular.copy(user); },
        users: function() { return self.users; }
      }
    });

    this.modalInstance.result.then(function(result) {
      self.updateResult = result;
      if (self.updateResult.status === 'SUCCESS') {
        reloadUsers();
        reloadProfile(user.customer.email);
      }
    }, function(result) {
    });
  };

  self.openDeleteUserModal = function(user) {
    this.modalInstance = $modal.open({
      templateUrl: 'company/delete-user-modal.html',
      controller: 'ModalDeleteUserCtrl',
      windowClass: 'supplier-modal',
      backdrop: 'static', // This prevents clicking backdrop closing modal
      keyboard: true, // This allows pressing ESC key to close modal
      resolve: {
        user: function() { return angular.copy(user); }
      }
    });

    this.modalInstance.result.then(function(result) {
      self.updateResult = result;
      if (self.updateResult.status === 'SUCCESS') {
        reloadUsers();
      }
    }, function(result) {
    });
  };

  this.canDelete = function(user) {
    return user.customer.email !== $rootScope.profile.email;
  };

  self.addUser = function() {

      this.modalInstance = $modal.open({
        templateUrl: 'company/add-user-modal.html',
        controller: 'ModalAddUserCtrl',
        windowClass: 'supplier-modal',
        backdrop: 'static', // This prevents clicking backdrop closing modal
        keyboard: true, // This allows pressing ESC key to close modal
        resolve: {
          //user: function() { return angular.copy(user); },
          users: function() { return self.users; }
        }
      });

      this.modalInstance.result.then(function(result) {
        self.updateResult = result;
        if (self.updateResult.status === 'SUCCESS') {
          reloadUsers();
        }
      }, function(result) {
      });

  };

}]);

/**
 * @desc alert directive
 * Multiple messages
 * @example <error-message ng-repeat="error in errorMessageArray"></error-message>
 * Single message
 * @example <error-message message="Lorem lorem lorem"></error-message>
 */
angular
  .module('app')
  .directive('errorMessage', errorMessage);

function errorMessage() {
  var directive = {
    link: link,
    templateUrl: 'common/error-directive.html',
    restrict: 'E'
  };

  function link(scope, el, attrs) {
    scope.message = attrs.message;
  }

  return directive;
}

app.controller('CompanyMenuCtrl', ['$rootScope', '$location', '$scope', 'PageTitle', function($rootScope, $location, $scope, PageTitle) {
  var self = this;

  PageTitle.set('Company - Supplier Hub | RedBalloon')

  self.companyName = function() {
    if ($rootScope.selectedSupplier) {
      return $rootScope.selectedSupplier.name;
    }
  }

  self.tabs = [
    {id: 0, title: 'Company Details', url: 'account-details'},
    {id: 1, title: 'User Accounts', url: 'user-accounts'},
    // {id: 2, title: 'Insurance', url: 'insurance'}
  ];

  self.selectTab = selectTab;
  self.tabSelected = selectTab(getInitialTab(), true);

  init();

  function init() {
    $rootScope.tabSelected = self.tabSelected;
  }

  function selectTab(tab, init) {
    if (typeof init !== 'undefined' && init) {
      tab.active = true;
      self.tabSelected = tab;
      return tab;
    } else if (self.tabSelected === tab) {
      return tab;
    }

    self.tabSelected.active = false;
    tab.active = true;
    self.tabSelected = tab;
    $rootScope.tabSelected = self.tabSelected;
    $location.path('/company/' + tab.url);
  }

  // Checks the route and compares to the url field in each tab object
  function getInitialTab() {
    var path = $location.$$path;
    if (path.indexOf(self.tabs[0].url) !== -1) return self.tabs[0];
    if (path.indexOf(self.tabs[1].url) !== -1) return self.tabs[1];
    if (path.indexOf(self.tabs[2].url) !== -1) return self.tabs[2];
    if (path.indexOf(self.tabs[3].url) !== -1) return self.tabs[3];
  }

  // Listen for route change to update the selected tab when the user
  // uses the sidebar to naviate to company pages
  $scope.$on('$routeChangeSuccess', function(next, current) {
    selectTab(getInitialTab(), true);
  });

}]);

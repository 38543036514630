app.factory('Product', function($resource, $http) {
  return {
    // functionality for supplier products in the add/edit workflows
    supplierWorkflowProducts: function(params) {
      var baseRoute = '/api/workflow/supplier/:supplierId/products';
      var baseParams = {supplierId: params.supplierId};
      return $resource(baseRoute, baseParams, {
        // retrieve all workflow items for supplier
        'fetchAll': {method: 'GET', isArray: true},

        // retrieve details for single workflow item - ensure params.familyCode is set
        'fetch': {method: 'GET', url: baseRoute + '/:workflowId',
          params: _.extend(_.clone(baseParams), {workflowId: params.workflowId})},

        // create or update a workflow item - pass workflow json as body
        'createOrUpdate': {method: 'POST'}

      })
    },
    workflowProductsSubmit: function(params) {
      return $http({
        method: 'POST',
        url: '/api/workflow/products/transition',
        data: {
          workflowId: params.workflowId,
          command: 'submit',
          supplierId: params.supplierId
        }
      });
    },
    // fetch set of categories (valid for primary category)
    categories: function(countryCode) {
      return $resource('/api/categories', {categoryType: 'category', countryCode: countryCode}, {
        'query': {method: 'GET', isArray: true}
      })
    },
    // fetch set of price option types
    priceOptionTypes: function() {
      //don't show the delivery
      return $resource('/api/productFamily/priceOptionTypes?hideDelivery=true', {}, {
        'query': {method: 'GET', isArray: true}
      });
    },
    // fetch, upload and delete standalone images
    standaloneImages: function(publicId) {
      return $resource('/api/images/standalone/:publicId', {publicId: publicId}, {
        'query': {method: 'GET'},
        'delete': {method: 'DELETE'},
        'upload': {method: 'POST', headers: { 'Content-Type': 'text/plain; charset=utf-8' }}
      });
    },
    deactivateImages: function(publicId) {
      return $http({
        method: 'PUT',
        url: '/api/images/standalone/'+ publicId +'/deactivate'
      })
    },
    // product family linked images
    // note: unfiltered=false means we're hiding 'archived' images
    images: function(familyCode, size) {
      return $resource('/api/images', {productCode: familyCode, size: size, unfiltered: false}, {
        'query': {method: 'GET', isArray: true}
      });
    },

    questionsByProductFamily: function(familyCode) {
      return $resource('/api/productFamily/:familyCode/questions', {familyCode: familyCode}, {
        'query': {method: 'GET', isArray: true}
      });
    },
    allForSupplier: function(supplierId, hideDuplicates) {
      var endpoint;

      hideDuplicates = hideDuplicates || false;
      endpoint = hideDuplicates?
        '/api/productFamily/supplier/' + supplierId :
        '/api/productFamily/priceOptions';

      return $resource(endpoint, {supplierId: supplierId, withField: 'FamilyDetails'}, {
            'query': {method: 'GET', isArray: true}
          }
      );
    },
    findByFamilyCode: function(familyCode) {
      return $resource('/api/productFamily/:code', {
            code: familyCode,
            publishedOnly: false
          }, {
            'query': {method: 'GET', isArray: false}
          }
      );
    },
    findNps: function(productCode) {
      return $resource('/api/productFamily/:code/nps', {code: productCode}, {
            'query': {method: 'GET', isArray: false}
          }
      );
    },
    findProductsOnSpecial: function(familyCode) {
      return $resource('/api/productFamily/:familyCode/productsOnSpecial', {familyCode: familyCode}, {
            'query': {method: 'GET', isArray: false}
          }
      );
    },
    getCurrentOrFutureCampaigns: function(familyCode) {
      return $resource('/api/productFamily/:familyCode/campaigns', {familyCode: familyCode}, {
            'query': {method: 'GET', isArray: true}
          }
      );
    },
  };
});

app.controller('FinancialsPaymentsCtrl', ['$rootScope', '$routeSegment', '$filter', 'Invoice', 'invoices', 'searchDefaultDate', function($rootScope, $routeSegment, $filter, Invoice, invoices, searchDefaultDate) {

  var self = this;
  self.supplierId = $rootScope.selectedSupplier.id;
  self.allInvoices = invoices;

  self.displayModeThreshold = 15;
  self.displayMode = null;
  self.displayModeSwitchButtonText = null;
  self.invoices = null;

  self.setupDisplay = setupDisplay;
  self.switchDisplayMode = switchDisplayMode;
  self.exceedThreshold = exceedThreshold;

  self.startSearch = startSearch;
  self.clearSearch = clearSearch;
  self.exportPayments = exportPayments;
  self.searchParams = new SearchParams(searchDefaultDate);
  self.searchTitle = null;
  self.exportParams = null;
  self.loading = false;

  self.sorter = new Sorter($filter);
  self.sortBy = sortBy;

  self.isSearchCollapsed = true;

  setupDisplay();

  // setup initial display, decide if should start in partial or full display mode, etc
  function setupDisplay() {
    self.displayMode = exceedThreshold() ? 'PARTIAL' : 'FULL';
    setVisibleInvoices();
    setDisplayModeSwitchButtonText();
    setSearchTitle();
    setExportParams();
    self.sorter.reset();
  }

  // switch from partial to full display mode and vice-versa
  function switchDisplayMode() {
    self.displayMode = self.displayMode == 'FULL' ? 'PARTIAL' : 'FULL';
    setVisibleInvoices();
    setDisplayModeSwitchButtonText();
  }

  function exceedThreshold() {
    return self.allInvoices.length > self.displayModeThreshold;
  }

  function setVisibleInvoices() {
    self.invoices = self.displayMode == 'PARTIAL' ? _.first(self.allInvoices, self.displayModeThreshold) : self.allInvoices;
  }

  function setDisplayModeSwitchButtonText() {
    self.displayModeSwitchButtonText = self.displayMode == 'PARTIAL' ? 'Show All' : 'Show Less';
  }

  function setSearchTitle() {
    self.searchTitle = self.searchParams.searchTitle();
  }

  function setExportParams() {
    self.exportParams = self.searchParams.getParams();
  }

  function startSearch() {
    self.loading = true;
    Invoice.search(self.supplierId, self.searchParams.getParams()).get().$promise.then(function(invoices) {
      self.allInvoices = invoices;
      self.setupDisplay();
      self.loading = false;
    });
  }

  function clearSearch() {
    _.last($routeSegment.chain).reload();
    self.isSearchCollapsed = false;
  }

  function exportPayments() {
    Invoice.exportCsv(self.supplierId, self.exportParams);
  }

  function sortBy(predicate) {
    self.allInvoices = self.sorter.sort(self.allInvoices, predicate);
    setVisibleInvoices();
  }

  // manage search default/custom params and search title
  function SearchParams(searchDefaultDate) {

    var self = this;
    self.searchDefaultDate = searchDefaultDate;
    self.model = {};
    self.getParams = getParams;
    self.searchTitle = searchTitle;

    // return the default search params to be used when user has not entered any search criteria
    function defaultParams() {
      var from = moment(self.searchDefaultDate).startOf('month').toDate();
      var to = moment(self.searchDefaultDate).endOf('month').toDate();
      return { from: from, to: to };
    }

    // return the current search params provided by the user
    function userParams() {
      return _.pick(self.model, _.identity);
    }

    // indicates whether current search criteria is the default one or user has provided custom params
    function defaultSearch() {
      return _.isEmpty(userParams());
    }

    // return the params to be used by the search: either the criteria provided by the user or the default from/to dates (current month)
    function getParams() {
      return defaultSearch() ? defaultParams() : userParams();
    }

    // title describing the search to be displayed at the header
    function searchTitle() {
      var title = null;
      if (defaultSearch()) {
        // default search - display month of default search date (current month)
        title = moment(self.searchDefaultDate).format('MMMM, YYYY');
      } else {
        // custom search - use from and to to decide what to display
        function fmt(d) { return moment(d).format('DD/MM/YYYY'); }
        var p = userParams();
        if (p.from && p.to) {
          title = 'From ' + fmt(p.from) + ' To ' + fmt(p.to);
        } else if (p.from) {
          title = 'From ' + fmt(p.from);
        } else if (p.to) {
          title = 'To ' + fmt(p.to);
        } else {
          title = 'Search Results';
        }
      }
      return title;
    }

    return self;

  }

  // manage state for the sorting
  function Sorter($filter) {

    var self = this;
    self.predicate = null;
    self.reversed = false;
    self.sort = sort;
    self.reset = reset;

    var orderBy = $filter('orderBy');

    function sort(invoices, predicate) {
      self.reversed = (predicate == self.predicate) ? !self.reversed : false;
      self.predicate = predicate;
      return orderBy(invoices, self.predicate, self.reversed);
    }

    function reset() {
      self.predicate = null;
      self.reversed = false;
    }

    return self;

  }

}]);

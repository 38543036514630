app.controller('BookingsListContentCtrl', ['$rootScope', '$routeParams', '$scope', '$location', 'Booking', 'bookings', 'status', '$modal', function($rootScope, $routeParams, $scope, $location, Booking, bookings, status, $modal) {
  var self = this;
  self.predicateToParamMap = createParamMap();
  self.paramToPredicateMap = createPredicateMap();
  self.supplierId = $rootScope.selectedSupplier.id;
  self.bookings = _(bookings).map(enhance);
  self.predicate = self.paramToPredicateMap[$routeParams.sortBy];
  self.reverse = ($routeParams.order === 'ASC') ? false : true;
  self.allSelected = false;
  self.status = status;
  self.bookingErrors = [];
  self.currentPage = $routeParams.page;
  self.limit = 25;
  self.maxVisiblePages = 10;
  self.totalCount = $rootScope.tabSelected.count;
  self.bookingsSelected = bookingsSelected;
  self.getRowClassName = getRowClassName;
  self.confirmBookings = confirmBookings;
  self.confirmSingleBooking = confirmSingleBooking;
  self.markSingleBookingAsPending =markSingleBookingAsPending;
  self.rejectSingleBooking = rejectSingleBooking;
  self.changeBookings = changeBookings;
  self.changeSingleBooking = changeSingleBooking;
  self.clearAllBookings = clearAllBookings;
  self.toggleAllBookings = toggleAllBookings;
  self.isAutoConfirmed = isAutoConfirmed;
  self.isPending = isPending;
  self.isNew = isNew;
  self.isConfirmed = isConfirmed;
  self.isCompleted = isCompleted;
  self.goto = goto;
  self.bookingDetails = bookingDetails;
  self.hasMultiplePages = hasMultiplePages;
  self.expandBooking = expandBooking;
  self.showVoucher = showVoucher;
  self.rowClassName = "";
  self.isAccordExpand = false;
  self.parseDate = function parseDate(timeString) {
    return moment(timeString, 'YYYY-MM-DDTHH:mm:ss').format("DD MMM");
  };
  self.parseDateCustomFormat = function parseDateCustomFormat(timeString, format) {
    return moment(timeString, 'YYYY-MM-DDTHH:mm:ss').format(format);
  };
  self.timeOnlyFormat = function timeOnlyFormat(timeString) {
    return moment(timeString, 'HH:mm:ss.SSS').format("hh:mm A")
  };

  $rootScope.$on('clear-booking-selections', function() {
    self.clearAllBookings();
  })

  self.order = function(predicate) {
    if (predicate === self.predicate) {
      self.reverse = !self.reverse;
    } else {
      self.reverse = false;
    }
    self.predicate = predicate;
    var sortBy = self.predicateToParamMap[predicate];
    var order = (self.reverse) ? 'DESC' : 'ASC';
    $location.path('/bookings/list/' + self.status)
    .search({
      page: 1,
      sortBy: sortBy,
      order: order,
      productCode: $routeParams.productCode,
      facilityId: $routeParams.facilityId,
      startDate: $routeParams.startDate,
      endDate: $routeParams.endDate
    });
  };

  function createParamMap() {
    var map = {};
    map['bookingDates.interestedDateTime1'] = 'preferredDateTime';
    map['bookingDates.confirmDateTime'] = 'confirmDateTime';
    map['participantName'] = 'name';
    map['familyName'] = 'product';
    map['bookingDates.redeemDate'] = 'redeemDate';
    map['bookingDates.supplierConfirmedOnDate'] = 'supplierConfirmedOnDate';
    map['voucherNumber'] = 'voucherNumber';
    return map;
  }

  function createPredicateMap() {
    var map = {};
    map['preferredDateTime'] = 'bookingDates.interestedDateTime1';
    map['confirmDateTime'] = 'bookingDates.confirmDateTime';
    map['name'] = 'participantName';
    map['product'] = 'familyName';
    map['redeemDate'] = 'bookingDates.redeemDate';
    map['supplierConfirmedOnDate'] = 'bookingDates.supplierConfirmedOnDate';
    map['voucherNumber'] = 'voucherNumber';
    return map;
  }

  function isNew() {
    return self.status === 'new';
  }

  function isConfirmed() {
    return self.status === 'confirmed';
  }

  function isCompleted() {
    return self.status === 'completed';
  }

  function enhance(booking)  {
    booking.isChecked = false;
    return booking;
  }

  function goto() {
    $location.path('/bookings/list/' + self.status)
    .search({
      page: self.currentPage,
      productCode: $routeParams.productCode,
      facilityId: $routeParams.facilityId,
      sortBy: $routeParams.sortBy,
      order: $routeParams.order,
      startDate: $routeParams.startDate,
      endDate: $routeParams.endDate
    });
  }

  function hasMultiplePages() {
    return self.totalCount > self.limit;
  }

  function findBookings() {
    self.bookings = [];

    Booking.findAll($rootScope.selectedSupplier.id, self.status, $routeParams.productCode, $routeParams.facilityId, $routeParams.sortBy, $routeParams.order, self.currentPage).query().$promise.then(
      function(data) {
        self.bookings = _(data).map(enhance);
    }, function(error) {
        self.bookingErrors.push(error);
    });
  }

  function confirmBookings() {
    self.modalInstance = openModal({
      templateUrl: 'bookings/bookings-modal.html',
      controller: 'BookingsModalInstanceCtrl as ctrl',
      resolve: {
        supplierId: function() {return self.supplierId;},
        selection: function() {return 0; },
        title: function() {return 'Confirm Booking';},
        info: function() {return 'Once you have confirmed this booking we will email ' +
          'the customer on behalf of you with their booking date, time and address.';},
        buttonName:function(){return 'confirm';},
        isPending : function() {return false},
        bookings: getSelectedNew
      }
    });
    self.modalInstance.result.then(function() {update();});
  };

  function markSingleBookingAsPending(booking){
    self.clearAllBookings();
    booking.isChecked = true;
    self.modalInstance = openModal({
      templateUrl: 'bookings/bookings-modal.html',
      controller: 'BookingsModalInstanceCtrl as ctrl',
      resolve: {
        supplierId: function() {return self.supplierId;},
        selection: function() {return 0; },
        title: function() {
          if(booking.pendingConfirmation){
            return 'Undo Pending';
          }else{
            return 'Set as Pending';
          }
          },
        info: function() {
          if(booking.pendingConfirmation){
            return '';
          }else{
            return 'When you set a booking as pending it indicates to RedBalloon ' +
              'that you have opened the booking and made contact with the customer.';
          }
        },
        buttonName:function(){return 'pending';},
        isPending : function() {return booking.pendingConfirmation;},
        bookings: getSelectedNew
      }
    });
    self.modalInstance.result.then(function() {update();});
  };

  function rejectSingleBooking(booking){
    self.clearAllBookings();
    booking.isChecked = true;
    self.modalInstance = openModal({
      templateUrl: 'bookings/bookings-modal.html',
      controller: 'BookingsModalInstanceCtrl as ctrl',
      resolve: {
        supplierId: function() {return self.supplierId;},
        selection: function() {return 0; },
        info: function() {return 'When you reject a booking it means yourself and the customer couldn\'t come to ' +
          'a mutual agreement on an experience date. This booking will be automatically cleared from your Supplier Hub and we will email the customer on your behalf.';},
        title: function() {return 'Reject Booking'},
        buttonName:function(){return 'reject';},
        isPending : function() {return false;},
        bookings: getSelectedNew
      }
    });
    self.modalInstance.result.then(function() {update();});
  }

  function confirmSingleBooking(booking) {
    self.clearAllBookings();
    booking.isChecked = true;

    self.confirmBookings();
  };

  function changeBookings() {
    self.modalInstance = openModal({
      templateUrl: 'bookings/bookings-modal.html',
      controller: 'BookingsModalInstanceCtrl as ctrl',
      resolve: {
        supplierId: function() {return self.supplierId;},
        selection: function() {return 1; },
        title: function() {return 'Confirmed Booking';},
        info: function() {return '';},
        buttonName:function(){return 'confirm';},
        isPending : function() {return false},
        bookings: getSelectedConfirmed
      }
    });
    self.modalInstance.result.then(function() {update();});
  };

  function changeSingleBooking(booking) {
    self.clearAllBookings();
    booking.isChecked = true;
    self.changeBookings();
  };

  function update() {
    findBookings();
    $scope.$emit('update-counts');
  }

  function openModal(params) {
    var defaults = {
      animation: true,
      windowClass: 'bookings-modal',
      backdrop: 'static', // This prevents clicking backdrop closing modal
      keyboard: true // This allows pressing ESC key to close modal
    };
    return $modal.open(angular.extend({}, defaults, params));
  }

  function clearAllBookings() {
    self.allSelected = true;
    self.toggleAllBookings();
  }

  function toggleAllBookings() {
    self.allSelected = !self.allSelected;
    _(self.bookings).map(function(booking) {
      if (self.isAutoConfirmed(booking)) {
        booking.isChecked = false;
      } else {
        booking.isChecked = self.allSelected;
      }
    });
  }

  function getSelectedNew() {
    return (_.filter(self.bookings, function(booking) {
      return (booking.isChecked === true);
    }).map(function(booking) {
      var bkg = angular.copy(booking);
      bkg.bookingDates.experienceDate = null;
      bkg.bookingDates.experienceTime = null;
      bkg.resendConfirmation = true;
      Booking.find(self.supplierId, booking.id).query().$promise.then(
        function(data){
          bkg.bookedBy = data.bookedBy;
          bkg.bookingEmail =data.bookingEmail;
        }, function(error) {
          self.bookingErrors.push(error);
        });

      return bkg;
    }));
  }

  function getSelectedConfirmed() {
    return (_.filter(self.bookings, function(booking) {
      return (booking.isChecked === true);
    }).map(function(booking) {
      var expDate = new Date(Date.parse(booking.bookingDates.confirmDateTime));
      var expTime = (booking.bookingDates.confirmTime == null) ? null : expDate;
      var bkg = angular.copy(booking);
      bkg.bookingDates.experienceDate = expDate;
      bkg.bookingDates.experienceTime = expTime;
      bkg.resendConfirmation = true;
      Booking.find(self.supplierId, booking.id).query().$promise.then(
        function(data){
          bkg.bookedBy = data.bookedBy;
          bkg.bookingEmail =data.bookingEmail;
        }, function(error) {
          self.bookingErrors.push(error);
        });
      return bkg;
    }));
  }


  function expandBooking(booking) {
    booking.isExpanded = booking.isExpanded ? false : true;
  }

  function showVoucher(booking) {
    booking.isAccordExpand = booking.isAccordExpand ? false : true;
  }

  function getRowClassName(data) {
   var isNewBooking =  self.isNew();
    var expand = data.expandStatus || 'is-expanded';
    var pending = self.bookings[data.index].pendingConfirmation ? 'highlighted' : '';

    if (data && data.bookedBy && !self.bookings[data.index].rowClassName  ) {
      var index = (!data.index) ? 1 : data.index;

      if (pending == 'highlighted') {
        self.rowClassname = " child";
      }
      else if (self.bookings[index - 1].bookedBy != data.bookedBy || data.index == 0) {
        self.rowClassname = (self.rowClassname && self.rowClassname.replace(" child", "") == "white") ? "gray" : "white";
      } else {
        self.rowClassname = (self.rowClassname == undefined) ? " child" : self.rowClassname + " child";
      }

      var className = expand + " " + self.rowClassname;
      self.bookings[data.index].rowClassName = className;
      return pending + ' ' + className;
    } else {
      return pending + ' ' + self.bookings[data.index].rowClassName;
    }
  }

  function bookingsSelected() {
    return(_.filter(self.bookings, function(booking) {
      return (booking.isChecked === true);
    })).length > 1;
  }

  function isAutoConfirmed(booking) {
    return booking.autoConfirmed === true;
  }

  function isPending(booking) {
    return booking.pendingConfirmation === true;
  }

  function bookingDetails(booking) {
    $location.path('/bookings/view/' + booking.id).search({page: self.currentPage, productCode: $routeParams.productCode, facilityId: $routeParams.facilityId, sortBy: $routeParams.sortBy, order: $routeParams.order});
  }

}]);

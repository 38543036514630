app.controller('availabilityListCtrl', ['$scope', 'products', 'Supplier', '$location', 'arrayToParamFilter', function($scope, productList, Supplier, $location, arrayToParamFilter) {
	$scope.lproducts = _.map(_.filter(productList, function(el) {
		return el.publishType === 'Honouring' || el.publishType === 'Published';
	}), function(obj){
		return {
			code: obj.code,
			familyCode: obj.familyCode,
			name: obj.name,
			publishType: obj.publishType,
			status: obj.status,
			selected: false,
			shortLocation: obj.shortLocation
		};
	});

	$scope.proceedSelection = function() {
		var pcodes = _.filter($scope.lproducts, function(obj) {
				return obj.selected;
			})
			.map(function (obj) {
				return obj.familyCode;
			});

		if (Boolean(pcodes.length)) {
			$location.path('/myproducts/availability/manager/' + arrayToParamFilter(pcodes, 'code') + '/calendar');
		}
	};

	$scope.cDisabled = true;

  /**
   * comment out this block (waiting for the design)
   */
  //  //fetch supplier lead time
  //  var supplier = $scope.$root.selectedSupplier;
  //  $scope.supplierLeadTime = supplier.leadTime;
  //
  //  var leadTimeData = {
  //   "leadTime": supplier.leadTime
  // };
  //
  //  $scope.updateSupplierLeadTime = function(leadTime) {
  //   leadTimeData.leadTime = parseInt(leadTime);
  //   Supplier.leadTime(supplier.id).save({}, leadTimeData).$promise.then(function(data) {
  //     self.handleUpdateResult(form, 'SUCCESS');
  //   }, function() {
  //     self.handleUpdateResult(form, 'FAILURE');
  //   });
  // };
   
  
	$scope.$watch('lproducts', function (nextValue) {
		$scope.cDisabled = !Boolean(_.filter(nextValue, function (product) {
			return product.selected;
		}).length);
	}, true);
}]);

app.factory('User', function($resource) {
  return {
    profile: function() {
      return $resource('/api/profile/', {}, {
        'get': {method: 'GET'},
        'update': {method: 'PUT'}
      });
    },
    customerWithContactType: function(supplierId) {
      return $resource('/api/customers', {supplierId: supplierId}, {
          'query': {method: 'GET', isArray: true},
          'update': {method: 'PUT'}
        }
      );
    }
  };
});

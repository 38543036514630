app.directive('blockoutFilter', function() {
  return {
    scope: {
      model: '=ngModel',
      show: '=ngShow',
      daysSelected: '='
    },
    restrict: 'E',
    templateUrl: 'availability/components/shared/filter/blockout-filter.html',
    controllerAs: 'ctrl',
    controller: function($scope, $element, $attrs, $rootScope, availabilityUtil) {
      var ctrl = this;
      $scope.model.dateFormat = 'YYYY-MM-DD';

      ctrl.datePickerOptions = {
        changeYear: true,
        changeMonth: true,
        yearRange: "c-0:+10",
        maxDate: '+1Y',
        init: moment().format($scope.model.dateFormat)
      };

      ctrl.showCalendar = function($event) {
        $($event.currentTarget.parentNode).find('input').focus();
      };

      // model
      var currentDate = new Date(); // default from/to date inputs to the current date

      $scope.model.fromDate = currentDate;
      $scope.model.toDate = currentDate;

      var searchCallBack = function(success, data) {
        $rootScope.manage_availability_show_loading = false;
        if(!success) {
          console.log(data);
        }
      };

      $scope.filterBlockoutDates = function() {
        var options = {
          fromStr: moment($scope.model.fromDate).format($scope.model.dateFormat),
          toStr: moment($scope.model.toDate).format($scope.model.dateFormat)
        };
        $rootScope.manage_availability_show_loading = true;
        availabilityUtil.loadBulkBlockoutDates(options, searchCallBack);
      }
    }
  };
});

app.controller('ModalDeleteUserCtrl', ['$rootScope', '$scope', '$modalInstance', 'user', 'Supplier', function($rootScope, $scope, $modalInstance, user, Supplier) {

  $scope.user = user;

  $scope.delete = function() {
    Supplier.contact($rootScope.selectedSupplier.id, user.customer.id).delete().$promise.then(function(result) {
      var fullName;
      if (user.customer.contact.firstName) {
        fullName = user.customer.contact.firstName
      }
      if (user.customer.contact.lastName) {
        fullName += ' ' + user.customer.contact.lastName
      }

      var msg = 'User ' + fullName + ' has been removed.';
      $modalInstance.close({status: 'SUCCESS', message: msg});
    }, function(reason) {
      $modalInstance.close({status: 'FAILURE', message: reason.data});
    });
  };

  $scope.cancel = function() {
    $modalInstance.dismiss();
  };

}]);

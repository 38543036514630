app.directive('avCalendarOverlay', function() {
  return {
    restrict: 'E',
    scope: {
      model:'=ngModel'
    },
    templateUrl: 'availability/components/manager/calendar-overlay/calendar-overlay.html',
    controllerAs: 'ctrl',
    controller: function($scope, $element, $attrs, $location, $routeParams) {
      // TODO(mh): click outside modal to dismiss not working properly (only on RHS)

      $scope.hasTimeslots = function() {
        return $scope.model && _.find($scope.model.products, function(product) { return product.times.length && product.selected; });
      };
      $scope.title = function() {
        return $scope.model ? moment($scope.model.date, 'YYYY-MM-DD').format('dddd, Do MMMM YYYY') : '';
      };
      $scope.products = function() {
        return $scope.model ? $scope.model.products : [];
      };
      $scope.date = function() {
        return $scope.model ? $scope.model.date : '';
      };

      $scope.addTimeslots = function() {
        $element.modal('hide');
        $location.path('/myproducts/availability/editor/timeslot/multiple/' + $scope.date() + '/' + $routeParams.productcode);
      };
      $scope.edit = function() {
        $element.modal('hide');
        $location.path('/myproducts/availability/editor/timeslot/multiple/' + $scope.date() + '/' + $routeParams.productcode);
      };
      $scope.clearDay = function() {
        // TODO(mh): use availabilityUtil/Availability to clear this day
        $element.modal('hide');
        $location.path('/myproducts/availability/manager/' + $routeParams.productcode + '/calendar');
      };
    }
  };
});

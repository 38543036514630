app.service('CompanyAgencyAgreementUtils', ['$filter', function($filter) {

  // helper functions shared between agency agreement list and details views
  var self = this;
  var countries = {'AU': 'Australia', 'NZ': 'New Zealand'};

  self.signatureRequired = function(supplierContract) {
    if (!loaded(supplierContract)) return;

    return supplierContract.signatureRequired;
  };

  self.signed = function(supplierContract) {
    if (!loaded(supplierContract)) return;

    return Boolean(supplierContract.accepted);
  };

  self.signedBy = function(supplierContract) {
    if (!loaded(supplierContract)) return;

    if (!self.signatureRequired(supplierContract)) {
      return 'Auto Accepted';
    } else if (self.signed(supplierContract)) {
      return $.trim(supplierContract.signedByFirstName + ' ' + supplierContract.signedBySurname);
    }

    return '';
  };

  self.dateSigned = function(supplierContract) {
    if (!loaded(supplierContract)) return;

    if (!self.signatureRequired(supplierContract)) {
      return self.formatDate(supplierContract.created);
    } else if (self.signed(supplierContract)) {
      return self.formatDate(supplierContract.accepted);
    }

    return '';
  };

  self.country = function(supplierContract) {
    if (!loaded(supplierContract)) return;

    return countries[supplierContract.country];
  };

  self.formatDate = function(date) {
    if (!loaded(date)) return;

    return $filter('date')(date, 'dd-MM-yyyy');
  };

  self.formatName = function(supplierContract) {
    if (!loaded(supplierContract)) return;

    return supplierContract.signedByFirstName + ' ' + supplierContract.signedBySurname;
  };

  // this is necessary in case view is rendered before model is completely loaded
  function loaded(supplierContract) {
    return supplierContract != undefined;
  }

}]);

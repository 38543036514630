app.directive('reviewsNpsTrend', function () {
  return {
    restrict: 'E',
    scope: {
      model: '=ngModel',
      fyOptions: '='
    },
    templateUrl: 'reviews/components/nps-trend/reviews-nps-trend.html',
    controllerAs: 'ctrl',
    controller: function ($scope, $element, $attrs, $rootScope, $document, Review) {

      var self = this;

      self.supplierId = $rootScope.selectedSupplier.id;

      // initial length of npsValues will dictate number of graphed years
      // reversed so that the current year will be on the right for both the select and the chart legend
      self.npsValues = $scope.model.reverse();
      self.fyOptions = $scope.fyOptions;
      self.selectedYears = _.map(self.npsValues, function(entry) {
        return entry.financialYear;
      });

      // Chart
      self.npsChart = {
        colours: ['#cccccc', '#0087c3'],
        labels: ["Jul", "Aug", "Sept", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        series: generateSeries(),
        scores: generateScores(),
        options: {
          scaleGridLineColor: '#f9f9f9',
          tooltipTemplate:"<%if (label){%><%=label %>: <%}%><%= value %>",
          multiTooltipTemplate:"<%= 'NPS ' + value %>"
        }
      };

      function generateSeries() {
        return _.map(self.npsValues, function(entry) {
          return 'FY ' + entry.financialYear + ' NPS ' + entry.averageNps;
        });
      }

      function generateScores() {
        return _.map(self.npsValues, function(entry) {
          return entry.npsByMonth;
        });
      }

      self.selectYear = function(index, year) {
        self.selectedYears[index] = year;
        Review.npsTrend(self.supplierId, self.selectedYears).then(function(res) {
          self.npsValues = res;
          self.npsChart.series = generateSeries();
          self.npsChart.scores = generateScores();
        });
      };

    }
  };
});

app.controller('OrderViewCtrl', ['$rootScope', '$routeParams', '$location', '$filter', 'Booking', 'booking', 'questions', 'notes', '$http', function ($rootScope, $routeParams, $location, $filter, Booking, booking, questions, notes,  $http) {
  var self = this;
  self.supplierId = $rootScope.selectedSupplier.id;
  self.customerNote = null;
  self.internalNote = null;
  self.booking = angular.copy(booking);
  self.questions = angular.copy(questions);
  self.notes = angular.copy(notes);
  self.isNew = isNew;
  self.isConfirmed = isConfirmed;
  self.isCompleted = isCompleted;
  self.hasInternalNote = hasInternalNote;
  self.getDisplayStatus = getDisplayStatus;
  self.setExperienceTime = setExperienceTime;
  self.updateExperienceTime = updateExperienceTime;
  self.clearExperienceTime = clearExperienceTime;
  self.confirmBooking = confirmBooking;
  self.changeBooking = changeBooking;
  self.updateNotes = updateNotes;
  self.isAutoConfirmed = isAutoConfirmed;
  self.cancel = cancel;
  self.hstep = 1;
  self.mstep = 15;
  self.updateResult = null;
  self.isPending = isPending;
  self.updatePending = updatePending;
  self.contactAddress = "";
  self.dispatchbtnText = "Confirm Order";

  self.experienceDatePickerOptions = {
    changeYear: true,
    changeMonth: true,
    minDate: 0,
    yearRange: "c-0:+10"
  };

  self.timeOnlyFormat = function timeOnlyFormat(timeString) {
    return moment(timeString, 'HH:mm:ss.SSS').format("hh:mm A")
  };

  //init();
initdirectship();
function initdirectship()
{
  $http.get('/supplier/'+ $rootScope.selectedSupplier.id +'/directship/'+ self.booking.id).then(function(response) {
    self.directship = response.data;
    self.uidate = self.directship.despatchDate ? new Date(Date.parse(self.directship.despatchDate)) : null;
  });
}

self.dispatchmessagecontainer = false;

self.sendDispatchDate = function(deliverydate, orderItemID) {
  if(deliverydate!="" && orderItemID!="")
  {
    var confirmDeliveryDate = $filter('date')(deliverydate, 'yyyy-MM-dd')
    $http({
      method: 'PUT',
      url: '/supplier/' + $rootScope.selectedSupplier.id+ '/delivery/dispatchDate/'+orderItemID,
      data: {
        date: confirmDeliveryDate
      }
    }).success(function(data, status, headers, config) {

      self.dispatchbtnText = "Order Confirmed";
      angular.element('#dispatchbtn').addClass("success");

      self.dispatchmessagecontainer = true;
      initdirectship();
    }).error(function(data, status, headers, config) {
      console.log('Discpatch date confirmation error: ' + data);
    });
  }
};

  function init() {
    self.booking.resendConfirmation = true;
    if (isNew()) {
      self.booking.bookingDates.experienceDate = new Date();
      self.booking.bookingDates.experienceTime = null;
    } else {
      var expDate = new Date(Date.parse(self.booking.bookingDates.confirmDateTime));
      var expTime = (angular.isDefined(self.booking.bookingDates.confirmTime)) ? expDate : null;
      self.booking.bookingDates.experienceDate = expDate;
      self.booking.bookingDates.experienceTime = expTime;
      updateExperienceTime();
    }

    self.contactAddress = "";
   
    if (self.booking.deliveryAddress != undefined) {
      self.contactAddress = (self.booking.deliveryAddress.address1.trim() != "") ? getFormattedAddress(self.booking.deliveryAddress.address1.trim()) : "";
      self.contactAddress = (self.booking.deliveryAddress.address2.trim() != "") ? getFormattedAddress(self.booking.deliveryAddress.address2.trim()) : self.contactAddress;
      self.contactAddress = (self.booking.deliveryAddress.suburb.trim() != "") ? getFormattedAddress(self.booking.deliveryAddress.suburb.trim()) : self.contactAddress;
      self.contactAddress = (self.booking.deliveryAddress.state.trim() != "") ? getFormattedAddress(self.booking.deliveryAddress.state.trim()) : self.contactAddress;
      self.contactAddress = (self.booking.deliveryAddress.postCode.trim() != "") ? getFormattedAddress(self.booking.deliveryAddress.postCode.trim()) : self.contactAddress;
      self.contactAddress = (self.booking.deliveryAddress.country.trim() != "") ? getFormattedAddress(self.booking.deliveryAddress.country.trim()) : self.contactAddress;      
    }
  }

  function getFormattedAddress(address) {
    return (self.contactAddress != "") ? self.contactAddress + ", " + address : address;
  }

  function isNew() {
    return self.booking.status === 'new';
  }

  function isConfirmed() {
    return self.booking.status === 'confirmed';
  }

  function isCompleted() {
    return self.booking.status === 'completed';
  }

  function isAutoConfirmed() {
    return self.booking.autoConfirmed === true;
  }

  function hasInternalNote() {
    return self.internalNote !== null && self.internalNote.trim() !== "";
  }

  function getDisplayStatus() {
    if (self.booking.status === 'new') return 'New';
    else if (self.booking.status === 'confirmed') return 'Confirmed';
    return 'Completed';
  }

  function cancel() {
    goto();
  }

  function setExperienceTime() {
    if (self.booking.bookingDates.experienceTime === null) {
      var date = new Date();
      // display the next closest quarter of an hour
      var currentMinute = date.getMinutes();
      var currentHour = date.getHours();
      var mod = currentMinute % 60;
      if (mod < 15) {
        date.setMinutes(15);
      }
      else if (mod < 30) {
        date.setMinutes(30);
      }
      else if (mod < 45) {
        date.setMinutes(45);
      }
      else {
        date.setHours(currentHour + 1);
        date.setMinutes(0);
      }
      self.booking.bookingDates.experienceTime = date;
    }
    updateExperienceTime(self.booking);
  }

  function clearExperienceTime() {
    self.booking.bookingDates.experienceTimeAsDisplay = null;
    self.booking.bookingDates.experienceTimeMilitary = null;
  }

  function updateExperienceTime() {
    if (self.booking.bookingDates.experienceTime === null) return;
    self.booking.bookingDates.experienceTimeAsDisplay = $filter('date')(self.booking.bookingDates.experienceTime, 'hh:mma');
    self.booking.bookingDates.experienceTimeMilitary =  $filter('date')(self.booking.bookingDates.experienceTime, 'HH:mm');
  }

  function confirmBooking() {
    updateBookings();
  }

  function changeBooking() {
    updateBookings();
  }

  function updateBookings() {
    function getBookingUpdates() {
      var expDate = new Date(Date.UTC(self.booking.bookingDates.experienceDate.getFullYear(), self.booking.bookingDates.experienceDate.getMonth(), self.booking.bookingDates.experienceDate.getDate()));
      return [{
        id: self.booking.id,
        orderItemId: self.booking.orderItemId,
        voucherNumber: self.booking.voucherNumber,
        experienceDate: expDate,
        experienceTime: self.booking.bookingDates.experienceTimeMilitary,
        experienceTimeMeridian: self.booking.bookingDates.experienceTimeAsDisplay,
        notes: self.customerNote,
        internalNotes: self.internalNote,
        resendSupplierConfirmation: self.booking.resendConfirmation
      }];
    }

    var bookingUpdates = getBookingUpdates();
    Booking.bookings(self.supplierId).update(bookingUpdates).$promise.then(
      function(data) {
        goto();
      }, function(error) {
        self.updateResult = 'FAILURE';
      });
  }

  function goto() {
    if (angular.isDefined($routeParams.voucherNumber) || angular.isDefined($routeParams.name))
      $location.path('/orders/search/').search({voucherNumber: $routeParams.voucherNumber, name: $routeParams.name, page: $routeParams.page, sortBy: $routeParams.sortBy, order: $routeParams.order});
    else if (angular.isDefined($routeParams.sortBy))
      $location.path('/orders/list/' + self.booking.status).search({page: $routeParams.page, productCode: $routeParams.productCode, facilityId: $routeParams.facilityId, sortBy: $routeParams.sortBy, order: $routeParams.order});
    else
      $location.path('/financials/payments');
  }

  function updateNotes() {
    var note = {
      orderItemId: self.booking.orderItemId,
      note: self.internalNote
    };
    Booking.bookingNote(self.supplierId, self.booking.id).insert(note).$promise.then(
      function(data) {
        self.notes.unshift(data);
        self.internalNote = null;
      }, function(error) {
        self.updateResult = 'FAILURE';
      });
  }

  function isPending() {
    return self.booking.pendingConfirmation === true;
  }

  function updatePending() {
    Booking.updatePending(self.supplierId, self.booking.id).update(getPendingState(!self.booking.pendingConfirmation)).$promise.then(
      function(data) {
        goto();
      }, function(error) {
        self.updateResult = 'FAILURE';
      });
  }


  function getPendingState(pendingState) {
    var pending = {
      orderItemId: self.booking.orderItemId,
      pendingConfirmation: pendingState
    };
    if (self.hasInternalNote()) {
      pending = angular.extend(pending, {internalNote: self.internalNote});
    }
    return pending;

  }
}]);

app.controller('ImagesCtrl', ['$scope', 'Product', 'productData', 'autoSave', '$modal', '$timeout', '$q', function($scope, Product, productData, autoSave, $modal, $timeout, $q) {

	// The existing data
	$scope.model = productData.imgVid;

	// The existing images
	$scope.model.images;

	// The existing video URL
	$scope.model.videoCode;

	$scope.imageGuidelines = function() {
		$scope.modalInstance = openModal({
			templateUrl: 'products/product/product-image-modal.html',
			controller: 'ProductImageModalInstanceCtrl as ctrl'
		});
		$scope.modalInstance.result.then(function() {update();});
	};

	function openModal(params) {
		var defaults = {
			animation: true,
			windowClass: 'product-image-modal',
			backdrop: 'static', // This prevents clicking backdrop closing modal
			keyboard: true      // This allows pressing ESC key to close modal
		};
		return $modal.open(angular.extend({}, defaults, params));
	}

	// flag for api operation (upload, delete) in progress
	$scope.apiOpInProgress = false;

	// NOTE: uploads are handled by product-images-dropzone.controller.js

	// delete remotely uploaded image, update model.images and trigger a workflow update
  $scope.errorDeleteImageMessage = '';

	$scope.removeImage = function(publicId) {
		$scope.apiOpInProgress = true;

		// 1 erase img from workflow
		$scope.model.images = _.filter($scope.model.images, function(image) {
			return image.publicId !== publicId;
		});

		// 2 for submitted product, deactivate img
		if (productData.shared.submitted) {
			Product.deactivateImages(publicId)
			.then(function(res) {
			})
			.catch(function(msg) {
				$scope.errorDeleteImageMessage = msg.data;
				$timeout(function () {
					$scope.errorDeleteImageMessage = '';
				}, 5000);
			})
			.finally(function() {
				$scope.apiOpInProgress = false;
			})
		} else {
			$scope.apiOpInProgress = false;
		}
	};

	$scope.save = function() {
		productData.updateWorkflow();
	};

	// Set up autosave
	$scope.initForm = function () {
		autoSave.init($scope, ['model.images']);
	};

	// Init the form for autosave
  $timeout(function() {
    $scope.initForm();
  }, 2000, false);

}]);

app.factory('Auth', function ($http) {
  return {
    login: function (credentials) {
      return $http.post('/api/auth/loginSupplier', credentials);
    },
    logout: function () {
      return $http.post('/api/auth/logout');
    },
    logoutAs: function () {
      return $http.post('/api/auth/logoutAs');
    },
    resetPassword: function (resetDetails) {
      return $http.post('/api/auth/password/resetSupplier', resetDetails);
    },
    changePassword: function (changePasswordDetails) {
      return $http.post('/api/auth/password/token', changePasswordDetails);
    },
    updatePassword: function (details) {
      return $http.post('/api/auth/password', details);
    },
    tokenValid: function (token) {
      return $http.get('/api/auth/checkLogin/' + token);
    }
  };
});
